import TableRow, { Row } from '../../../../components/TableRow';
import ActionMenus from './DiscountActionMenus';
import { Box, UserTableContainer } from '../../../../styles';
import {
    DiscountDataType, MenuType, TableHeaderInfo, UserDataType
} from "../../../types/DataTypes";

interface DiscountTableProps {
    data:  DiscountDataType[]
    nullMessage: string
    showPlusIcon: boolean
    additionMessage?: string
    tableHeader: TableHeaderInfo[]
    addTableActionBtn: boolean
    onActionClicked: (item: DiscountDataType) => void
    actionMenus: MenuType[]
    selectedDiscount?: DiscountDataType
    onMenuClick: (m: MenuType) => void
    offset: number
    onAddClick: () => void
}

const DiscountTable = ({ data, nullMessage, showPlusIcon, additionMessage, addTableActionBtn, tableHeader,
    onActionClicked, actionMenus, selectedDiscount, onMenuClick, offset, onAddClick }: DiscountTableProps) => {
    const fetchFromObject: (obj: UserDataType, prop?: string) =>
        string = (obj, prop) => {
            return obj[prop as keyof UserDataType] || ''
        }

    const fillRowData = (item: UserDataType, header: TableHeaderInfo, ind: number, key: number) => {
        let Data
        if (header.wrapper) {
            Data = (
                <header.wrapper
                    value={fetchFromObject(item, header.value)}
                ></header.wrapper>
            )
        }
        else if (ind === 0) {
            Data = key + 1 + offset
        }
        else {
            Data = `${fetchFromObject(item, header.value)}`
        }
        return (
            <Row key={ind} className={`row row${ind}`}>
                <p
                >
                    {Data || 'N/A'}
                </p>
            </Row>
        )
    }

    return (
        <UserTableContainer>
            <TableRow className='table-head'>
                {
                    tableHeader.map((s, ind) => {
                        return (
                            <Row key={ind} className={`row row${ind}`}>
                                <p>{s.text}</p>
                            </Row>
                        )
                    })

                }
                {
                    addTableActionBtn && (
                        <Row className={`row row-last}`}>
                            <p>Action</p>
                        </Row>
                    )
                }
            </TableRow>
            {
                data === undefined ?
                <></>:
                data.length > 0 ?
                    data.map((item, key: number) => {
                        return (
                            <TableRow className='table-row' key={key}>
                                {
                                    tableHeader.map((header, ind) => {
                                        return fillRowData(item as UserDataType, header, ind, key)
                                    })
                                }
                                {addTableActionBtn &&
                                    <div onClick={() => onActionClicked(item)}>
                                        <ActionMenus
                                            menus={actionMenus}
                                            selectedDiscount={selectedDiscount as DiscountDataType}
                                            item={item as DiscountDataType}
                                            onMenuClick={onMenuClick}
                                            activator={
                                                <>
                                                    <p>Action</p>
                                                    <img src="/assets/institution/icons/dropdown.svg" alt="" />
                                                </>
                                            }
                                            type="tableAction"
                                        />
                                    </div>
                                }
                            </TableRow>
                        )
                    })
                    :
                    <Box style={{ backgroundColor: "none" }}>
                        <div>
                                <p>{nullMessage}</p>
                                <div onClick={onAddClick}>
                                    {showPlusIcon &&
                                    <p className='btn'><span>+</span><span style={{ textDecoration: "underline", marginLeft: "10px" }}>{additionMessage}</span></p>
                                    }
                                </div>
                            </div>
                    </Box>
            }

        </UserTableContainer>
    )
}

export default DiscountTable
