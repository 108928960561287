import styled from "styled-components";
import { PriceDataType } from "../../../types/DataTypes";

const Modal = styled.div`
    .content-inner{
        padding: 16px;
        font-family: 'Courier New';
        background-color: #ECF5FF;
        margin-top: 15px;
        margin-bottom: 30px;

        .value{
            line-height: 16px;
            color: #373737;
        }
    }

    .head-1, .head-2, .head-3{
        font-weight: 700;
    }

    .head-1{
        font-size: 22px;
    }

    .head-2{
        font-size: 14px;
        margin-top: 30px;
    }

    .row{
        display: flex;
        flex-direction : row;
        justify-content: space-between;
    }

    .row1, .row2, .row3, .row4, .row5{
        margin-bottom: 20px;
    }
    
    .row p:(first-child){
        margin-bottom: 8px;
    }
`


interface ViewPriceProps {
    price?: PriceDataType,
    productName: string
}

const ViewProduct = ({ price, productName }: ViewPriceProps) => {

    return (
        <>
            <Modal>
                <p className="head-1">{productName}</p>
                <p className="head-2">Price details</p>
                <div className="content-inner">
                    <div className="row row1">
                        <div>
                            <p className="head-3">Price</p>
                            <p className="value">{price?.price}</p>
                        </div>
                        <div>
                            <p className="head-3">Volume</p>
                            <p className="value">{price?.volume}</p>
                        </div>
                    </div>
                    <div className="row row2">
                        <div>
                            <p className="head-3">Valid From</p>
                            <p className="value">{price?.validFrom}</p>
                        </div>
                        <div>
                            <p className="head-3">Valid To</p>
                            <p className="value">{price?.validTo}</p>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )

}

export default ViewProduct