import React from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from '../../components/Button';
import { Flex, P } from '../styles/generalStyles';


const FooterContainer = styled(Flex)`
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 2px;
    height: 64px;
    border-top: 2px solid #2A6BB0;
`

const CtaContainer = styled(Flex)`
    width: 100%;
    justify-content: space-between;
`

const Note = styled(P)`

`

const Btn = styled.div`
    padding: 16px;
    height: 50px;
    border-radius: 8px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    outline: none;
    border: none;

`

interface AuthPageFooterProps {
    shouldDisable: boolean;
    note: string;
    to: string;
    link: string;
    btn: string;
    loading: boolean;
}

const AuthPagesFooter = ({ note, to, link, shouldDisable, btn, loading }: AuthPageFooterProps) => {

    return (
        <FooterContainer>
            <CtaContainer>
                <Note>{note}<Link to={to} style={{ color: "#2A6BB0" }}>{link}</Link></Note>
            <Btn>
                <Button
                    children={btn} disabled={shouldDisable} loading={loading}
                />
            </Btn>
            </CtaContainer>
        </FooterContainer>
    )
}

export default AuthPagesFooter
