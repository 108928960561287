import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useAdminSession } from '../context/AdminSessionContext';


const Styles = styled.div`
    display: none;
    @media (max-width: 1024px) {
        display: block;
    }
`

const MenuBox = styled.div`
    height: auto;
    width: 100%;
    background-color: #2A6BB0;
    padding:0px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

`
const NavContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1px;


`

const BtnBox = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    height: 32px;
    cursor: pointer;
    border-radius: 8px;

    div {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #2A6BB0;
    }

`

type NavButtonProps = {
    tag: string,
    imageUrl: string,
    tagColor: string,
    onClickFunc: () => void,
    bg: string,
    bd?: string
}

type NavButtonType = {
    tag: string,
    imageUrl: string,
    link: string
}

const NavButtonCollection: NavButtonType[] = [
    {
        tag: "Type & Specialization",
        imageUrl: "institution-type",
        link: "typeandspecialization"
    },
    {
        tag: "Users",
        imageUrl: "users",
        link: "users"
    },
    {
        tag: "Products",
        imageUrl: "products",
        link: "products"
    },
    {
        tag: "Institution",
        imageUrl: "institution",
        link: "institution"
    },
    {
        tag: "Accounts",
        imageUrl: "account",
        link: "accounts"
    },
]

const NavButton = ({ tag, imageUrl, tagColor, onClickFunc, bg, bd }: NavButtonProps) => {

    return (
        <BtnBox onClick={onClickFunc} style={{ backgroundColor: bg, border: bd ? bd : "none" }}>
            <div >
                <img src={`/assets/admin/icons/${imageUrl}.svg`} alt={`${imageUrl}`} />
                <p style={{ color: tagColor }}>{tag}</p>
            </div>
        </BtnBox>
    )
}

const AdminMobileMenu = () => {
    const [curNav, setCurNav] = useState<number>();
    const { sessionAdmin, setSessionAdmin } = useAdminSession();
    return (
        <Styles>
            <MenuBox>
                <NavContainer>
                    {
                        NavButtonCollection.map((nav, ind) => {
                            return (
                                <NavLink to={`/admin/home/${nav.link}`} key={ind} style={{ textDecoration: "none" }}>
                                    {({ isActive }) => (
                                        <NavButton
                                            key={ind}
                                            imageUrl={`${nav.imageUrl}-${curNav === ind || isActive ? "fill" : "icon"}`}
                                            tag={nav.tag}
                                            tagColor={curNav === ind || isActive ? "#2A6BB0" : "#ffffff"}
                                            onClickFunc={() => { curNav !== ind && setCurNav(ind) }}
                                            bg={curNav === ind || isActive ? "#ffffff" : "#2A6BB0"}
                                        />
                                    )}
                                </NavLink>
                            )
                        })
                    }
                    <NavButton
                        imageUrl="logout-icon"
                        tag="Log out"
                        tagColor="#ffffff"
                        onClickFunc={() => {
                            setSessionAdmin({
                                userId: null,
                                adminImage: null,
                                adminName: null,
                                accessToken: null
                            })
                        }}
                        bg="#2A6BB0" />
                </NavContainer>
            </MenuBox>

        </Styles>
    )
}

export default AdminMobileMenu