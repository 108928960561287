import styled from "styled-components";
import { Flex, H, P } from "../../styles/generalStyles";


export const Container = styled.div`
    padding:38.75px 0 26px 0;
    flex: 1;
    height: 100vh;
    overflow-y: scroll;

`

export const Body = styled.div`
    width: 100%;
    height: 601.25px;
    
    // border: 1px solid red;

    // position: relative;

    .close {
        text-align: right;
        width: 100% !important;
        margin-bottom: 10px;
        cursor: pointer;
        padding: 0 40px 0 55px;
    }



`

export const FooterContainer = styled(Flex)`
    width: 100%;
    flex-direction: column;
    gap: 14px;
    height: 50px;
    

`

export const ProgressBar = styled(Flex)`
    width: 100%;



    span {
        height: 0;
        border: 1px solid #E5E5E5;
    }

    .left {
        background-color: #2A6BB0;
        border: 1px solid #2A6BB0;
    }
`
export const CtaContainer = styled(Flex)`
    width: 100%;
    justify-content: space-between;
    padding: 0 47.5px;
    height: 50px;

`
export const BackBtn = styled(P)`
    cursor: pointer;
    text-decoration-line: underline;

`

export const NextBtn = styled.div`
    padding: 16px;
    // width: 67px;
    height: 50px;
    border-radius: 8px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    outline: none;
    border: none;

`

// BasicInfoPage

export const Heading = styled(H)`
    margin-bottom: 32px;

`

export const FormBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    // padding: 0 40px 0 55px;

    .form-textarea {
        height: 100px;
        width: 100%;
    }

`

export const DoubleInputWrapper = styled(Flex)`
    gap: 24px;

`