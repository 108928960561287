import TableRow, { Row } from '../../../../components/TableRow';
import TransactionActionMenus from './TransactionActionMenus';
import { Box, UserTableContainer } from '../../../../styles';
import {
    TableHeaderInfo, MenuType, TransactionDataType,
} from '../../../types/DataTypes';


interface TransactionTableProps {
    data: TransactionDataType[]
    nullMessage: string
    showPlusIcon: boolean
    additionMessage?: string
    tableHeader: Array<TableHeaderInfo>
    addTableActionBtn: boolean
    onActionClicked: (transaction: TransactionDataType) => void
    actionMenus: MenuType[]
    selectedTransaction?: TransactionDataType
    onMenuClick: (m: MenuType) => void
    onAddClick?: () => void
    offset: number
}

const TransactionTable = ({ data, nullMessage, showPlusIcon, additionMessage, addTableActionBtn, tableHeader,
    onActionClicked, actionMenus, selectedTransaction, onMenuClick, offset, onAddClick }: TransactionTableProps) => {
    const fetchFromObject: (obj: TransactionDataType, prop?: string) =>
        string = (obj, prop) => {
            return obj[prop as keyof TransactionDataType] || ''
        }

    const fillRowData = (item: TransactionDataType, header: TableHeaderInfo, ind: number, key: number) => {
        let Data
        if (header.wrapper) {
            Data = (
                <header.wrapper
                    value={fetchFromObject(item, header.value)}
                ></header.wrapper>
            )
        }
        else if (ind === 0) {
            Data = offset + key + 1
        }
        else {
            Data = `${fetchFromObject(item, header.value)}`
        }
        return (
            <Row key={ind} className={`row row${ind}`}>
                <p
                >
                    {Data || 'N/A'}
                </p>
            </Row>
        )
    }

    return (
        <UserTableContainer>
            <TableRow className='table-head'>
                {
                    tableHeader.map((s, ind) => {
                        return (
                            <Row key={ind} className={`row row${ind}`}>
                                <p>{s.text}</p>
                            </Row>
                        )
                    })

                }
                {
                    addTableActionBtn && (
                        <Row className={`row row-last}`}>
                            <p>Action</p>
                        </Row>
                    )
                }
            </TableRow>
            {
                data === undefined ?
                    <></> :
                    data.length > 0 ?
                        data.map((item: TransactionDataType, key: number) => {
                            return (
                                <TableRow className='table-row' key={key}>
                                    {
                                        tableHeader.map((header, ind) => {
                                            return fillRowData(item, header, ind, key)
                                        })
                                    }
                                    {addTableActionBtn &&
                                        <div onClick={() => onActionClicked(item)}>
                                            <TransactionActionMenus
                                                menus={actionMenus}
                                                selectedTransaction={selectedTransaction}
                                                item={item}
                                                onMenuClick={onMenuClick}
                                                activator={
                                                    <>
                                                        <p>Action</p>
                                                        <img src="/assets/institution/icons/dropdown.svg" alt="" />
                                                    </>
                                                }
                                                type="tableAction"
                                            />
                                        </div>
                                    }
                                </TableRow>
                            )
                        })
                        :
                        <Box style={{ backgroundColor: "none" }}>
                            <div>
                                <p>{nullMessage}</p>
                                <div onClick={onAddClick}>
                                    {showPlusIcon &&
                                    <p className='btn'><span>+</span><span style={{ textDecoration: "underline", marginLeft: "10px" }}>{additionMessage}</span></p>
                                    }
                                </div>
                            </div>
                        </Box>
            }

        </UserTableContainer >
    )
}

export default TransactionTable
