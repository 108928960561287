import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from "styled-components";
import ClickUpload from './ClickUpload';


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    position: relative;
    width: 100%;
    gap: 4px;


    .star{
        position: absolute;
        bottom: 16px;
        left: 10px;
        pointer-events: none;
        color: grey;
        transition: .5s;
        transform: translateY(-20px);
        font-size: 12px;
    }
`
const Label = styled.p`
    

`

const InputWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 24px;


    .click-upload-group {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px;
        // gap: 8px;
        width: 150px;
        height: 50px;
        background-color: #2A6BB0;
        border-radius: 8px;
    }


`

const Input = styled.div`
    height: 50px;
    width: 100%;
    border-radius: 4px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #4E4E4E;
    padding: 16px;
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
    outline: none;
    display: flex;

    p {
        color: #373737;
    }

`

interface ImageInputProps {
    title: string;
    placeholder: string;
    files: File[];
    setFiles: Dispatch<SetStateAction<File[]>>;
    fileType: "img" | "file" | "both";
    required?:boolean;
}

const ImageInput = ({title, placeholder, files, setFiles, fileType, required}:ImageInputProps) => {
    // const [files, setFiles] = useState<File[]>([]);

    const inputValue = files.map((file, i) => (<p key={i}>{file.name}</p>));
  return (
    <Container>
        <Label className="label">
            {title}
            <span style={{marginLeft: "3px"}}>{required && "*"}</span>
        </Label>
        <InputWrapper>
            <Input>
                {
                    inputValue.length > 0 ?
                    inputValue:
                    placeholder
                }
            </Input>
            <ClickUpload 
                label='Choose file'
                textStyle={{color: "#FFF"}}
                fileType = {fileType}
                prevState = {files}
                stateFunc = {setFiles}
                multiple = {false}/>
        </InputWrapper>
      
    </Container>
  )
}

export default ImageInput
