import { useEffect, useState } from "react";
import TopMenu from "../../../components/TopMenu";
import InviteTable from "./templates/InviteTable";
import StatusWrapper from "../../../components/StatusWrapper";
import Modal from "../../../components/Modal";
import AddInvite from "./templates/AddInvite";
import Pagination from "../../../components/Pagination";
import {
    InviteDataType,
    ListMenuType,
    InviteTopMenuType,
    MenuType,
    InstDataType,
} from "../../types/DataTypes";
import { AppMenuType } from "../../../components/DataTypes";
import DateWrapper from "../../../components/DateWrapper";
import connectivityService from "../../../service/ConnectivityService";
import { ToastContainer, toast } from "react-toastify";
import { ToastTypes } from "../../../components/DataTypes";
import RoleWrapper from "../../../components/RoleWrapper";
import { useInstitutionSession } from "../../context/InstitutionSessionContext";
import { useNavigate } from "react-router-dom";
import { sortDataByDate } from "../../../utils";
import {
    Loader,
    LoaderWrapper,
    LoadingOverlay,
    SectionContainer,
    SpacedBetweenColumnBox,
} from "../../../styles";
import { handleResponse } from "../../../utils";

const tableHeader = [
    { value: "inviteId", text: "S/N" },
    { value: "email", text: "Email address" },
    { value: "phoneNumber", text: "Phone number" },
    { value: "workStatus", text: "Status", wrapper: StatusWrapper },
    { value: "userRole", text: "Role", wrapper: RoleWrapper },
    { value: "createdAt", text: "Date", wrapper: DateWrapper },
];

const itemsPerPage = 8;

const Invites = () => {
    const topActionMenus: InviteTopMenuType[] = [
        "Single Invite",
        "Bulk Invite",
    ];

    const [selectedInvite, setSelectedInvite] = useState<InviteDataType>();
    const [listActionMenus, setListActionMenus] = useState<ListMenuType[]>([]);
    const [modalType, setModalType] = useState<string>("");
    const [showModal, setModalShow] = useState<boolean>(false);
    const [tableData, setTableData] = useState<InstDataType[]>();
    const [initialData, setInitialData] = useState<InstDataType[]>();
    const [noDataMessage, setNoDataMessage] = useState<string>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [refreshPage, setRefreshPage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { sessionInstitution } = useInstitutionSession();

    const navigate = useNavigate();

    const handleAccessError = () => {
        navigate("/institution/login");
    };

    const toggleModalDisplay = () => {
        setModalShow(!showModal);
    };

    const handleTopMenuClick = (menu: AppMenuType) => {
        toggleModalDisplay();
        setModalType(menu.split(" ")[0]);
    };

    const addSingleInvite = (invite: InviteDataType) => {
        invite.workStatus = "pending";
        invite.createdAt = new Date().toDateString();
        setTableData([invite, ...(tableData ?? [])]);
    };

    const handleInviteAction = (item: InviteDataType) => {
        setSelectedInvite(item);
        setListActionMenus(
            item.workStatus === "PENDING" || item.workStatus === "pending"
                ? ["Cancel", "Resend Invite"]
                : []
        );
    };

    const updateInviteStatus = async (
        invite: InviteDataType,
        menu: MenuType
    ) => {
        setIsLoading(true);
        const url = `${process.env.REACT_APP_INST_URL}/${sessionInstitution.shortCode}/userInvites`;
        let payload;
        if (menu === "Cancel") {
            payload = {
                userId: invite.iUIId,
                workStatus: "CANCELLED",
            };
            const res = await connectivityService().makeApiCall(
                "put",
                url,
                payload,
                sessionInstitution.accessToken
            );
            handleResponse(
                res,
                "Invite cancelled successfully",
                handleAccessError
            );
            setIsLoading(false);
            setRefreshPage(!refreshPage);
        } else if (menu === "Resend Invite") {
            payload = {
                userId: invite.iUIId,
                workStatus: "CANCELLED",
            };
            const cancelRes = await connectivityService().makeApiCall(
                "put",
                url,
                payload,
                sessionInstitution.accessToken
            );

            if (cancelRes.status !== "success") {
                handleResponse(cancelRes);
                setIsLoading(false);
                return;
            }

            const inviteData = {
                email: invite.email,
                phoneNumber: invite.phoneNumber,
                userRole: invite.userRole,
            };

            const data = {
                invites: [inviteData],
                host: "institution",
            };

            const resendRes = await connectivityService().makeApiCall(
                "post",
                url,
                data,
                sessionInstitution.accessToken
            );
            handleResponse(
                resendRes,
                "Invite resent successfully",
                handleAccessError
            );
            setIsLoading(false);
            setRefreshPage(!refreshPage);
        }
    };

    const notify = (type: ToastTypes, msg: string) =>
        toast(msg, { type: type, position: "top-center", theme: "colored" });

    useEffect(() => {
        const code = sessionInstitution.shortCode;
        const url = `${process.env.REACT_APP_INST_URL}/${code}/userInvites`;
        const fetchData = async () => {
            const res = await connectivityService().makeApiCall(
                "get",
                url,
                undefined,
                sessionInstitution.accessToken
            );
            if (res.code === 200 || res.code === 201) {
                const data = res.data as InstDataType[];
                const sortedData = sortDataByDate(data, "createdAt");
                setTableData(sortedData);
                setInitialData(sortedData);
            } else {
                const error = await connectivityService().handleErrors(
                    res,
                    notify
                );
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.");
                    navigate("/institution/login");
                }
            }
        };
        fetchData();
    }, [refreshPage, sessionInstitution, navigate]);

    const searchData = (data: string) => {
        if (data === "") {
            setTableData(initialData);
            setNoDataMessage("No invitation has been made");
        } else {
            setNoDataMessage("Invite not found");
            const result = (initialData as InviteDataType[])?.filter((val) => {
                return val.email.toLowerCase().includes(data.toLowerCase());
            });
            setTableData(result);
        }
    };

    const offset = itemsPerPage * (currentPage - 1);
    let displayData = tableData
        ? tableData.slice(offset, offset + itemsPerPage)
        : tableData;

    return (
        <SectionContainer>
            {showModal && (
                <Modal
                    children={
                        <AddInvite
                            type={modalType}
                            entityName="user"
                            addSingleInvite={addSingleInvite}
                            formClose={() => setModalShow(false)}
                        />
                    }
                    show={showModal}
                    onClose={() => setModalShow(false)}
                    style={{ width: "704px" }}
                />
            )}

            <TopMenu
                navTag="Invites"
                btnAction="Add invite"
                topActionMenus={topActionMenus}
                hasSearch
                hasSubMenus={true}
                onMenuClick={handleTopMenuClick}
                portal="institution"
                searchBy="email address"
                searchData={searchData}
            />

            <SpacedBetweenColumnBox>
                {tableData !== undefined ? (
                    <>
                        <div className="page-section">
                            <InviteTable
                                data={displayData as InviteDataType[]}
                                offset={offset}
                                nullMessage={
                                    noDataMessage ||
                                    "No invitation has been made"
                                }
                                showPlusIcon={true}
                                additionMessage="Add new invites"
                                tableHeader={tableHeader}
                                addTableActionBtn={true}
                                onActionClicked={(item: InviteDataType) => {
                                    handleInviteAction(item);
                                }}
                                actionMenus={listActionMenus}
                                selectedInvite={selectedInvite}
                                onMenuClick={(menu) => {
                                    updateInviteStatus(
                                        selectedInvite as InviteDataType,
                                        menu
                                    );
                                }}
                                topActionMenus={topActionMenus}
                                onTopMenuClick={handleTopMenuClick}
                            />
                        </div>
                        {tableData !== undefined &&
                            tableData.length > itemsPerPage && (
                                <Pagination
                                    data={tableData as InstDataType[]}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    itemsPerPage={itemsPerPage}
                                />
                            )}
                    </>
                ) : (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                )}

                {isLoading && (
                    <LoadingOverlay>
                        <LoaderWrapper>
                            <Loader />
                        </LoaderWrapper>
                    </LoadingOverlay>
                )}
            </SpacedBetweenColumnBox>

            <ToastContainer limit={1} />
        </SectionContainer>
    );
};

export default Invites;
