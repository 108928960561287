import styled from "styled-components";
import { TransactionDataType } from "../../../types/DataTypes";

const Modal = styled.div`
    .content-inner{
        padding: 16px;
        font-family: 'Courier New';
        background-color: #ECF5FF;
        margin-top: 15px;
        margin-bottom: 30px;

        .value{
            line-height: 16px;
            color: #373737;
        }
    }

    .head-1, .head-2, .head-3{
        font-weight: 700;
    }

    .head-1{
        font-size: 22px;
    }

    .head-2{
        font-size: 14px;
        margin-top: 30px;
    }

    .row{
        display: flex;
        flex-direction : row;
        justify-content: space-between;
        gap: 4px;
    }

    .row1, .row2, .row3, .row4, .row5{
        margin-bottom: 20px;
    }
    
    .row p:(first-child){
        margin-bottom: 8px;
    }
`



interface ViewTransactionProps {
    transaction: TransactionDataType
}

const TimeWrapper = (time: string) => {
    function convertDate(inputFormat: string) {
      var d = new Date(inputFormat);
      let day = d.getDate();
      let month = d.toLocaleString('default', { month: 'short' });;
      let year = d.getFullYear();
      let hours = d.getHours().toString().padStart(2, '0');
      let minutes = d.getMinutes().toString().padStart(2, '0');
      if (isNaN(day) || !day || isNaN(year)) return "Invalid date";
      return `${day} ${month} ${year}, ${hours}:${minutes}`;
    }
  
    return convertDate(time);
  };

const ViewTransaction = ({ transaction }: ViewTransactionProps) => {

    return (
        <>
            <Modal>
                <p className="head-1">Transaction details</p>
                <div className="content-inner">
                    <div className="row row1">
                        <div>
                            <p className="head-3">Transaction ID</p>
                            <p className="value">{transaction.transactionId}</p>
                        </div>
                        <div>
                            <p className="head-3">Account ID</p>
                            <p className="value">{transaction.accountId}</p>
                        </div>
                        <div>
                            <p className="head-3">Customer ID</p>
                            <p className="value">{transaction.customerId}</p>
                        </div>
                    </div>
                    <div className="row row2">
                        <div>
                            <p className="head-3">Transaction Type</p>
                            <p className="value">{transaction.transactionType}</p>
                        </div>
                        <div>
                            <p className="head-3">Transaction Time</p>
                            <p className="value">{TimeWrapper(transaction.transactionTime)}</p>
                        </div>
                        <div>
                            <p className="head-3">Updated on</p>
                            <p className="value">{TimeWrapper(transaction.updatedAt)}</p>
                        </div>
                    </div>
                    <div className="row3">
                        <p className="head-3">Transaction Description</p>
                        <p className="value">{transaction?.transactionDescription}</p>
                    </div>
                </div>
            </Modal>
        </>
    )

}

export default ViewTransaction