import * as yup from 'yup';

export const PriceSchema = yup.object({
    price: yup
        .string()
        .trim()
        .required("This field is required"),
    volume: yup
        .string()
        .trim()
        .required("This field is required"),
    validFrom: yup
        .string()
        .required("This field is required"),
    validTo: yup
        .string()
        .required("This field is required")
});
