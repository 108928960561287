import { Routes, Route, useNavigate } from "react-router-dom";
import InstitutionApp from "./institution/InstitutionApp";
import AdminApp from "./admin/AdminApp";
import InstitutionInviteRedirect from "./components/InstitutionInviteRedirect";
import ResetPasswordRedirect from "./components/ResetPasswordRedirect";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url('/assets/images/medicine.svg') no-repeat center center fixed;
    background-size: contain;
    height: 100vh;
    width: 100vw;
    color: white;
`;

const Logo = styled.img`
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 70px;
`;

const PageContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    padding: 45px 40px;
    margin: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    backdrop-filter: blur(5px);
`;

const ButtonCard = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    padding: 8px;
    margin: 20px 0;
    border-radius: 8px;
    background-color: #2A6BB0;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        transform: scale(1.05);
        background-color: white;
        color: #2A6BB0;
        border: 1px solid #2A6BB0;
    }
`;

const FakeHome = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <Logo
                src="/favicon.png"
                alt="one-2-one logo"
            />
            <PageContent>
                <h1>Welcome to One2One Portal</h1>
                <p>
                    We're currently under construction, but our admin and institution portals are available.
                    Please use the buttons to navigate.
                </p>
                <ButtonCard onClick={() => navigate("/admin")}>
                    Login as Admin
                </ButtonCard>
                <ButtonCard onClick={() => navigate("/institution")}>
                    Login as Institution
                </ButtonCard>
            </PageContent>
        </Container>
    );
};

function App() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<FakeHome />} />
                <Route path="/institution/*" element={<InstitutionApp />} />
                <Route path="/admin/*" element={<AdminApp />} />
                <Route
                    path="/:portal/reset-password/:id"
                    element={<ResetPasswordRedirect />}
                />
                <Route
                    path="/create-account/institution/:referralCode"
                    element={<InstitutionInviteRedirect />}
                />
                <Route
                    path="/create-account/admin/:referralCode"
                    element={<InstitutionInviteRedirect />}
                />
            </Routes>
        </div>
    );
}

export default App;
