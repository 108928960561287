import { useEffect, useState } from "react";
import styled from "styled-components";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { DiscountSchema } from "../Discount.validators";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import { FormBox } from "../../../styles/generalStyles";
import { TwoColumnInputFlex } from "../../../styles/generalStyles";
import connectivityService from "../../../../service/ConnectivityService";
import { ToastContainer, toast } from 'react-toastify';
import { ToastTypes } from '../../../../components/DataTypes';
import { DiscountDataType, InstitutionDataType, ProductDataType } from "../../../types/DataTypes";
import toggleButton from "../../../../institution/utils/toggleButton";
import { useAdminSession } from "../../../context/AdminSessionContext";

const ModalContent = styled.div`
    margin-top : -17px;

    .file-input{
        height: 50px;
        width: 100%;
        padding: 13px;
    }

    .title{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        p{
            font-weight: 400;
            font-size: 16px;
            a{
                color: #2A6BB0;
            }
        }
    }

    .input-blue-border{
        background: #FFFFFF;
        border: 1px solid #2A6BB0;
        border-radius: 4px;
    }
    
    .input-section{
        margin-top: 16px;
        .button {
            margin-top: 34px;
        }
        label{
            font-weight: 400;
            font-size: 16px;
            color: #4E4E4E;
        }
    }

    .bulk{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 24px;
        align-items: end;
    }

    #button.img-btn:hover{
        background: #2A6BB0 !important;
    }
`

const ValidityText = styled.div`
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 16px;
    p{
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #373737;
    }
`

type DiscounttObjType = {
    productName?: string,
    institutionName?: string,
    institutionCode?: string,
    discount?: string,
    validFrom?: string,
    validTo?: string
}

interface addDiscountProps {
    addDiscount: (invite: DiscountDataType) => void,
    formClose: () => void,
    selectedDiscount?: DiscountDataType,
    selectedInstitution?: InstitutionDataType,
    pageRefresh: boolean,
    setPageRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

type Product = {
    productName: string,
    productId: string,
}

const AddDiscount = ({ formClose, addDiscount, selectedDiscount, selectedInstitution, pageRefresh, setPageRefresh }: addDiscountProps) => {
    const [discountData, setDiscountData] = useState<DiscounttObjType>(
        {
            productName: "",
            institutionName: selectedInstitution?.name,
            institutionCode: selectedInstitution?.shortCode,
            validFrom: "",
            validTo: ""
        }
    )

    const [loading, setLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<Product[]>([]);
    const {sessionAdmin} = useAdminSession();

    const navigate = useNavigate();
    const notify = (type: ToastTypes, msg: string) => (
        toast(msg, { type: type, position: "top-center", theme: "colored" })
    )

    const handleSubmit = async (values: DiscounttObjType, actions: FormikHelpers<DiscounttObjType>) => {
        if (values.validTo) {
            if (new Date(values.validTo) <= new Date(values.validFrom as string)) {
                notify("error", `discount validFrom must be less than validTo`);
                return;
            }

            if (new Date(values.validTo) <= new Date()) {
                notify("error", `invalid validTo for discount`);
                return;
            }
        }

        setLoading(true);

        const productId = products.filter(p=>(p.productName === values.productName))[0].productId;

        const discountObj = {
            productId,
            institutionCode: values.institutionCode,
            validFrom: values.validFrom,
            validTo: values.validTo,
            discount: values.discount,
            createdBy: sessionAdmin.userId
        }

        const url = `${process.env.REACT_APP_PRODUCT_ROOT_URL}/discount`;

        const response = await connectivityService().makeApiCall(
            'post',url, discountObj, sessionAdmin.accessToken
        )

        setLoading(false);

        if (response.code === 200 || response.code === 201) {
            notify("success", `Discount added successful for ${selectedInstitution?.name}`);
            setPageRefresh(!pageRefresh);
            formClose();
        }
        else {
            const error = await connectivityService().handleErrors(response, notify)
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.")
                    navigate("/admin")
                }
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            const res = await connectivityService()
                .makeApiCall("get", `${process.env.REACT_APP_PRODUCT_ROOT_URL}`, undefined, sessionAdmin.accessToken);
            if (res.code === 200 || res.code === 201) {

                const incomingProducts = res.data.map((p:ProductDataType)=>({
                    productName: `${p.productName}(unit: ${p.unitType}-${p.unit})`,
                    productId: p.productId
                }));
                setProducts(incomingProducts);

               
            }
            else {
                const error = await connectivityService().handleErrors(res, notify)
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.")
                    navigate("/admin")
                }
            }
        }
        fetchData();
    }, []);

    const productOptions = products.map(p=>(p.productName));

    return (
        <>
            <Formik
                initialValues={discountData}
                onSubmit={handleSubmit}
                validationSchema={DiscountSchema}>
                {
                    (formik: FormikProps<DiscounttObjType>) => {
                        const toggle = toggleButton<DiscounttObjType>(formik.values) &&
                            Object.keys(formik.errors).length === 0;
                        return (
                            <ModalContent>
                                <div className="title">
                                    Add new Discount
                                </div>
                                <div className="input-section">
                                    <Form>
                                        <TwoColumnInputFlex>
                                            <FormBox>
                                                <div>
                                                    <Select
                                                        name="productName"
                                                        title="Product Name"
                                                        options={productOptions}
                                                        placeholder="Select name"
                                                        required
                                                    />
                                                </div>
                                                <div className="flex">
                                                    <Input
                                                        name="institutionName"
                                                        title="Institution Name"
                                                        type="text"
                                                        placeholder="Institution name"
                                                        required
                                                        disabled
                                                    />
                                                    <Input
                                                        name="institutionCode"
                                                        title="Institution Code"
                                                        type="text"
                                                        placeholder="Institution code"
                                                        required
                                                        disabled
                                                    />
                                                </div>
                                                <div>
                                                    <Input
                                                        name="discount"
                                                        title="Set Discount(%)"
                                                        type="number"
                                                        placeholder="Enter discount"
                                                        required
                                                    />
                                                </div>
                                                <ValidityText>
                                                    <p>Validity Period</p>
                                                </ValidityText>
                                                <div className="flex">
                                                    <Input
                                                        name="validFrom"
                                                        title="From"
                                                        type="date"
                                                        required
                                                    />
                                                    <Input
                                                        name="validTo"
                                                        title="To"
                                                        type="date"
                                                        required
                                                    />
                                                </div>
                                                <Button
                                                    className="button"
                                                    type="submit"
                                                    loading={loading}
                                                    disabled={!toggle}
                                                    children={`Add Discount`}
                                                    style={{ marginTop: "24px" }}
                                                />
                                            </FormBox>
                                        </TwoColumnInputFlex>
                                    </Form>
                                </div>
                            </ModalContent>
                        )
                    }
                }
            </Formik>
            <ToastContainer limit={1} />
        </>
    )

}

export default AddDiscount;