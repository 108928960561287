import React from 'react'
import { useCreateAccount } from '../context/InstCreateAccountContext';
import { BackBtn, CtaContainer, FooterContainer, NextBtn, ProgressBar } from '../../CreateAccount.style'
import { useNavigate } from 'react-router-dom';
import Button from '../../../../../components/Button';

interface FooterProps {
    shouldDisable: boolean;
    loading: boolean
}

const Footer = ({ shouldDisable, loading }: FooterProps) => {
    const navigate = useNavigate();
    const { subPages } = useCreateAccount();
    const [curSubPage, setCurSubPage] = subPages;
    const progressRatios = [[1, 2], [1, 2], [2, 1], [1, 0]];

    return (
        <FooterContainer>
            <ProgressBar>
                <span className='left' style={{ flex: progressRatios[curSubPage as number][0] }}></span>
                <span style={{ flex: progressRatios[curSubPage as number][1] }}></span>
            </ProgressBar>
            <CtaContainer>
                <BackBtn onClick={() => { curSubPage > 0 && setCurSubPage(curSubPage - 1 as 0 | 1 | 2); curSubPage === 0 && navigate('/institution') }}>Back</BackBtn>
                <NextBtn>
                    <Button
                        children={curSubPage === 3 ? "Create Account" : "Next"}
                        disabled={shouldDisable}
                        loading={loading}
                        type="submit"
                    />
                </NextBtn>
            </CtaContainer>
        </FooterContainer>
    )
}

export default Footer
