import styled from "styled-components";
import { P } from "../../styles/generalStyles";



export const ForgotPassword = styled(P)`

    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    align-self: end;
    // margin-right: 100px;

`