import styled, { keyframes } from "styled-components";

const spinAnimation = keyframes`
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
`;

const roundAnimation = keyframes`
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
`;

export const Loader = styled.span`
    color: #2a6bb0;
    font-size: 45px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: fixed; /* Change position to fixed */
    top: 50%; /* Position from top */
    left: 50%; /* Position from left */
    transform: translate(-50%, -50%); /* Center the loader */
    z-index: 9999; /* Ensure the loader is on top of other elements */
    animation: ${spinAnimation} 1.7s infinite ease,
        ${roundAnimation} 1.7s infinite ease;
`;

export const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
`;

export const SectionContainer = styled.div<{ optionalStyles?: string }>`
    width: 100%;
    height: 96vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    padding: 0 20px;
    overflow-x: auto;
    ${(props: { optionalStyles: any; }) => props.optionalStyles}
`;

export const UserTableContainer = styled.div`
    width: 100%;
    display: table;
    border-collapse: separate;
    border-spacing: 0 10px;

    @media (max-width: 1024px) {
        margin-top: 0;
    }

    .table-row:not(:first-child) {
        border-top: 1px solid #e0e7ed;
    }

    .table-row {
        display: table-row;
        padding: 10px 15px;
        gap: 15px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
        &:hover {
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
        }
        p {
            display: table-cell;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .table-head {
        display: table-row;
        background-color: #dde2e7;
        padding: 10px 15px;
        gap: 15px;
        border-radius: 10px;
        p {
            display: table-cell;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #888888;
            text-shadow: 1px 0 #888888;
        }
    }

    .row {
        padding-left: 12px;
        p {
            font-family: "Lato";
            font-style: normal;
            font-size: 14px;
            line-height: 14px;
            color: #373737;
            display: table-cell;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .active,
    .inactive,
    .pending {
        padding: 8px 12px;
        border-radius: 8px;
        font-weight: 700;
    }

    .active {
        background: rgba(159, 247, 157, 0.41);
        color: #347d32;
    }

    .pending {
        background: rgba(237, 204, 28, 0.2);
        color: #7b6a10;
    }

    .inactive {
        background: rgba(237, 28, 36, 0.1);
        color: #ed1c24;
    }

    span {
        display: inline-block;
        padding: 4px 8px;
        border-radius: 4px;
        font-weight: 500;
    }

    .active span {
        background: rgba(159, 247, 157, 0.41);
        color: #347d32;
    }

    .pending span {
        background: rgba(237, 204, 28, 0.2);
        color: #7b6a10;
    }

    .inactive span {
        background: rgba(237, 28, 36, 0.1);
        color: #ed1c24;
    }
`;

export const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 510px;

    p {
        font-family: "Lato";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #4e4e4e;
    }

    p.btn {
        color: #2a6bb0;
        cursor: pointer;
    }

    div {
        display: flex;
        flex-direction: column;
        gap: 18px;
    }
`;

export const ActionMenusBox = styled.div`
    #menu {
        position: relative;

        .tableAction,
        .topAction {
            color: #4e4e4e;
            position: absolute;
            background: #ffffff;
            box-shadow: 0 1px 7px rgb(120 120 120 / 25%);
            border-radius: 8px;
            padding: 8px;
            font-size: 14px;
        }

        .tableAction {
            left: -16px;
            top: 41px;
            width: 118px;
            z-index: 999;
        }

        .topAction {
            left: -127px;
            top: 14px;
            width: 110px;
        }
    }

    .topAction li {
        padding: 4px;
    }
    #menu .popup.p-hide {
        visibility: hidden;
    }
    ul {
        li {
            list-style: none;
            cursor: pointer;
            padding: 2px;
        }
    }
    li:hover {
        background-color: #2a6bb0;
        color: white;
    }

    .row-action {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        p {
            padding: 6px 0 6px 12px;
        }
        img {
            padding: 6px 12px 6px 0;
        }
    }

    #topAction {
        gap: 4px;
    }

    #tableAction {
        gap: 8px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
            0px 0px 0px 1px rgba(70, 79, 96, 0.16);
        border-radius: 6px;
        width: 80%;
    }
`;

export const ActionMenuLabel = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    p {
        padding: 6px 0 6px 12px;
    }
    img {
        padding: 6px 12px 6px 0;
    }
`;

export const Head = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 28px;
    align-items: center;

    @media (max-width: 1024px) {
        .option {
            margin-bottom: 4px;
        }
    }
`;


export const SpacedBetweenColumnBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    height: 90%;
    padding-top: 8px;
`;


export const LoadingOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`;
