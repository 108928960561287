import { toast } from "react-toastify";
import { useAdminSession } from "../admin/context/AdminSessionContext";
import connectivityService from "../service/ConnectivityService";
import { ToastTypes } from "../components/DataTypes";

const baseUrl = process.env.REACT_APP_API_ROOT_URL;
// const baseUrl = "https://api.one2one.dufuna.com/api/v1";

export const notify = (type: ToastTypes, msg: string) =>
    toast(msg, { type: type, position: "top-center", theme: "colored" });

export const useUserDetails = () => {
    const { sessionAdmin } = useAdminSession();

    const getUserDetails = async (userId: any) => {
        const res = await connectivityService().makeApiCall(
            "get",
            `${baseUrl}/user/${userId}`,
            undefined,
            sessionAdmin.accessToken
        );

        if (res.status === "success" || res.code === 200 || res.code === 201) {
            return res.data;
        }
    };

    return { getUserDetails };
};

export const sortDataByDate = (data: any[], dateField: string) => {
    return [...data].sort(
        (a, b) =>
            new Date(b[dateField]).getTime() - new Date(a[dateField]).getTime()
    );
};

export const handleResponse = async (
    res: any,
    successMessage?: string,
    cleanUpAction?: () => void,
) => {
    if (res.code === 200 || res.code === 201) {
        notify("success", successMessage as string);
    } else {
        const error = await connectivityService().handleErrors(res, notify);
        if (error === "AccessDenied") {
            notify("error", "Login session expired. Please re-login.");
            cleanUpAction && cleanUpAction();
        }
    }
};
