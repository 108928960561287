import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { SingleIDPUserSchema } from '../ManageUsers.validators';
import connectivityService from '../../../../service/ConnectivityService';
import { ToastContainer, toast } from 'react-toastify';
import { ToastTypes } from '../../../../components/DataTypes';
import { useNavigate } from 'react-router-dom';
import { useInstitutionSession } from '../../../context/InstitutionSessionContext';

const BulkUsersList = styled.div`
width: 100%;
padding: 50px 40px 0 40px;
height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .heading {
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        color: #373737;
        margin-top: 30px;
    }

    .row{
        display: flex;
        flex-direction: row;
        gap: 24px;
        padding-bottom: 24px;
        align-items: flex-end;
    }

    .footer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 14px 0;
        border-top: 2px solid #2A6BB0;
    }

    input, select{
        padding: 13px;
        width: 100%;
        height: 50px;
        background: #FFFFFF;
        border: 1px dashed #2A6BB0 !important;
        border-radius: 4px;
    }

    .row label{
        display: none;
    }

    .add input, .add select{
        border: 1px solid #2A6BB0 !important;
    }

    .add label{
        display: block !important;
    }

    button{
        border-radius: 4px;
    }

    #button.img-btn:hover{
        background: #2A6BB0 !important;
    }

    .entries {
        padding-top: 24px;
        border-bottom:  1px solid #2A6BB0;
    }
`

interface stateType {
    users: userObject[]
}

type userObject = {
    email?: string,
    phoneNumber?: string,
    firstName?: string,
    lastName?: string,
    dob?: string,
    role?: string,
    gender?: string,
    key?: number
}

const IDPBulkUsersLit = ({ users }: stateType) => {
    const [usersArray, setUsersArray] = useState<userObject[]>([]);
    const [lastEditedRow, setLastEditedRow] = useState<number>();
    const [lastKey, setLastKey] = useState<number>(0);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const { sessionInstitution } = useInstitutionSession();

    const userObj = {
        firstName: "",
        dob: "",
        role: ""
    }

    useEffect(() => {
        let newUserArray = [];
        for (let i = 0; i < users.length; i++) {
            const newObject = { ...users[i], key: i };
            newUserArray.push(newObject);
        }
        setUsersArray(newUserArray);
        setLastKey(newUserArray.length - 1);
    }, [users]);

    const notify = (type: ToastTypes, msg: string) => (
        toast(msg, { type: type, position: "top-center", theme: "colored" })
    )

    const disablePreviousRow = () => {
        document.querySelector(`.row${lastEditedRow} input[name="firstName"]`)?.setAttribute('disabled', '');
        document.querySelector(`.row${lastEditedRow} input[name="lastName"]`)?.setAttribute('disabled', '');
        document.querySelector(`.row${lastEditedRow} input[name="dob"]`)?.setAttribute('disabled', '');
        document.querySelector(`.row${lastEditedRow} select[name="gender"]`)?.setAttribute('disabled', '');
    }

    const enableRowEdit = (key: number) => {
        disablePreviousRow();
        document.querySelector(`.row${key} input[name="firstName"]`)?.removeAttribute('disabled');
        document.querySelector(`.row${key} input[name="lastName"]`)?.removeAttribute('disabled');
        document.querySelector(`.row${key} input[name="dob"]`)?.removeAttribute('disabled');
        document.querySelector(`.row${key} select[name="gender"]`)?.removeAttribute('disabled');
        setLastEditedRow(key);
    }

    const handleChange = (name: string, value: string, key?: number) => {
        const newState = usersArray.map((val, i) => {
            if (val.key === key) {
                return ({ ...val, [name]: value });
            }
            else {
                return val;
            }
        })

        setUsersArray(newState);
        setLastKey(newState.length - 1);
    }

    const removeEntry = (key: number) => {
        const newState = usersArray.filter((val, ind) => ind !== key);
        setUsersArray(newState);
        setLastKey(newState.length);
    }

    const handleSubmit = (values: userObject, actions: FormikHelpers<userObject>) => {
        const newUser = {
            firstName: values.firstName,
            lastName: values.lastName,
            dob: values.dob,
            gender: values.gender,
            key: lastKey + 1
        };
        const newUserArray = [...usersArray, newUser];
        setUsersArray(newUserArray);
        setLastKey(newUserArray.length - 1);
        actions.resetForm({ values: { firstName: "", lastName: "", dob: "", gender: "" } });
    }

    const addUsers = async () => {
        setLoading(true);

        const institutionCode = sessionInstitution.shortCode;
        const url = `${process.env.REACT_APP_INST_URL}/${institutionCode}/idpUser`;

        const newUsersArray = usersArray.map((val, key) => {
            return {
                firstName: val.firstName,
                lastName: val.lastName,
                dateOfBirth: val.dob,
                gender: val.gender === "Female" ? "F" : "M",
                key: key
            };
        })

        const data = {
            users: newUsersArray
        }

        const response = await connectivityService().makeApiCall(
            'post', url, data, sessionInstitution.accessToken
        )

        setLoading(false);

        if (response.code === 200 || response.code === 201) {
            navigate("/institution/home/users");
        }
        else {
            const error = await connectivityService().handleErrors(response, notify)
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.")
                    navigate("/institution/login");
                }
        }
    }

    return (
        <>
            <BulkUsersList>
                <div>
                    <img src="/favicon.png" width="50px" alt="one-2-one logo" />
                    <p className="heading">Add users</p>

                    <div className="entries">
                        {usersArray.map((val, key) => {
                            return (
                                <Formik
                                    initialValues={val}
                                    onSubmit={handleSubmit}
                                    validationSchema={SingleIDPUserSchema}
                                >
                                    {(formik: FormikProps<userObject>) => {
                                        const toggle =
                                            Object.keys(formik.errors)
                                                .length === 0;
                                        return (
                                            <Form>
                                                <div
                                                    className={`row row${key}`}
                                                >
                                                    <Input
                                                        name="firstName"
                                                        type="text"
                                                        tempOnChange={(e) => {
                                                            formik.setFieldValue(
                                                                "firstName",
                                                                e.target.value
                                                            );
                                                            handleChange(
                                                                "firstName",
                                                                e.target.value,
                                                                val.key
                                                            );
                                                            setDisabled(
                                                                !toggle
                                                            );
                                                        }}
                                                        disabled
                                                    />
                                                    <Input
                                                        name="lastName"
                                                        type="text"
                                                        tempOnChange={(e) => {
                                                            formik.setFieldValue(
                                                                "lastName",
                                                                e.target.value
                                                            );
                                                            handleChange(
                                                                "lastName",
                                                                e.target.value,
                                                                val.key
                                                            );
                                                            setDisabled(
                                                                !toggle
                                                            );
                                                        }}
                                                        disabled
                                                    />
                                                    <Select
                                                        name="gender"
                                                        tempOnChange={(e) => {
                                                            formik.setFieldValue(
                                                                "gender",
                                                                e.target.value
                                                            );
                                                            handleChange(
                                                                "gender",
                                                                e.target.value,
                                                                val.key
                                                            );
                                                            setDisabled(
                                                                !toggle
                                                            );
                                                        }}
                                                        options={[
                                                            "Female",
                                                            "Male",
                                                        ]}
                                                        disabled
                                                    />
                                                    <Input
                                                        name="dob"
                                                        type="date"
                                                        tempOnChange={(e) => {
                                                            formik.setFieldValue(
                                                                "dob",
                                                                e.target.value
                                                            );
                                                            handleChange(
                                                                "dob",
                                                                e.target.value,
                                                                val.key
                                                            );
                                                            setDisabled(
                                                                !toggle
                                                            );
                                                        }}
                                                        disabled
                                                    />
                                                    <Button
                                                        type="button"
                                                        className="img-btn"
                                                        onClick={() =>
                                                            enableRowEdit(key)
                                                        }
                                                        children={
                                                            <img
                                                                src="/assets/icons/edit.svg"
                                                                alt="edit"
                                                            />
                                                        }
                                                    />
                                                    <Button
                                                        type="button"
                                                        className="img-btn"
                                                        onClick={() =>
                                                            removeEntry(key)
                                                        }
                                                        children={
                                                            <img
                                                                src="/assets/icons/delete.svg"
                                                                alt="delete"
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            );
                        })}
                    </div>
                    <div>
                        <Formik
                            initialValues={userObj}
                            onSubmit={handleSubmit}
                            validationSchema={SingleIDPUserSchema}
                        >
                            {(formik: FormikProps<userObject>) => {
                                const toggle =
                                    Object.keys(formik.errors).length === 0;
                                return (
                                    <Form>
                                        <div
                                            className="row add"
                                            style={{
                                                marginTop: "24px",
                                                alignItems: "flex-end",
                                            }}
                                        >
                                            <Input
                                                name="firstName"
                                                title="First Name"
                                                type="text"
                                                required
                                            />
                                            <Input
                                                name="lastName"
                                                title="Last Name"
                                                type="text"
                                                required
                                            />
                                            <Select
                                                name="gender"
                                                title="Gender"
                                                options={["Female", "Male"]}
                                                required
                                            />
                                            <Input
                                                name="dob"
                                                title="DOB"
                                                type="date"
                                                required
                                            />
                                            <Button
                                                type="submit"
                                                className="img-btn"
                                                disabled={!toggle}
                                                children={
                                                    <img
                                                        src="/assets/icons/add.svg"
                                                        alt="edit"
                                                    />
                                                }
                                            />
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
                <div className="footer">
                    <Button
                        children="Back to users"
                        onClick={() => {
                            navigate("/institution/home/users");
                        }}
                    />
                    <Button
                        children="Add users"
                        disabled={disabled}
                        loading={loading}
                        onClick={addUsers}
                    />
                </div>
                <ToastContainer />
            </BulkUsersList>
        </>
    );
}

export default IDPBulkUsersLit;
