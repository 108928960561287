import { createContext, Dispatch, ReactNode, SetStateAction, useContext } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";




type SessionInstitution = {
    accessToken: string | null,
    shortCode: string | null,
    institutionName: string | null,
    institutionLogo: string | null,
    institutionType: string | null,
    userId: string | null
}


interface InstitutionSessionProviderProps {
    children: ReactNode;
}

interface InstitutionSessionContextType {
    sessionInstitution: SessionInstitution;
    setSessionInstitution: Dispatch<SetStateAction<SessionInstitution>>;
}


const InstitutionSessionContext = createContext({} as InstitutionSessionContextType);

export const useInstitutionSession = () => {
    return useContext(InstitutionSessionContext);
}

export const InstitutionSessionProvider = ({ children }: InstitutionSessionProviderProps) => {

    const [sessionInstitution, setSessionInstitution] = useLocalStorage<SessionInstitution>("sessionInstitution", {
        accessToken: null,
        shortCode: null,
        institutionName: null,
        institutionLogo: null,
        institutionType: null,
        userId: null
    });


    return(
        <InstitutionSessionContext.Provider value={{
            sessionInstitution,
            setSessionInstitution,
        }}>
            {children}
        </InstitutionSessionContext.Provider>
    )
}
