import React from 'react';
import styled from 'styled-components';


const TableRowContainer = styled.div`
    width: 100%;
`

const RowContainer = styled.div`
    display: table-cell;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`


interface RowProps {
    children: React.ReactNode,
    className: string
}


export const Row = ({children, className}:RowProps) => {
    return (
        <RowContainer className={className}>
            {
                children
            }
        </RowContainer>
    )
}

const TableRow = ({children, className}:RowProps) => {
  return (
    <TableRowContainer className={className}>
        {
            children
        }
    </TableRowContainer>
  )
}

export default TableRow
