import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ToastTypes } from "../../../../components/DataTypes";
import connectivityService from "../../../../service/ConnectivityService";
import { useInstitutionSession } from "../../../context/InstitutionSessionContext";
import { InstUserDataType } from "../../../types/DataTypes";
import { useNavigate } from "react-router-dom";

const Modal = styled.div`
    .content-inner{
        padding: 16px;
        font-family: 'Courier New';
        background-color: #ECF5FF;
        margin-top: 15px;
        margin-bottom: 30px;

        .value{
            line-height: 16px;
            color: #373737;
        }
    }

    .head-1, .head-2, .head-3, .head-4{
        font-weight: 700;
    }

    .head-1{
        font-size: 22px;
    }

    .head-2{
        font-size: 14px;
        margin-top: 30px;
    }

    .row{
        display: flex;
        flex-direction : row;
        justify-content: space-between;
    }

    .row1, .row2, .row3, .row4, .row5{
        margin-bottom: 20px;
    }
    
    .row p:(first-child){
        margin-bottom: 8px;
    }

    a {
        text-decoration: underline;
        color: #0066cc;
    }
`

type DoctorInfoType = {
    licenseCountryOfIssue: string,
    specialization: string,
    language: string,
    licenseNumber: string,
    experience: string,
    certificate: string
}

type NOKType = {
    nokFirstName: string,
    nokLastName: string,
    nokRelationship: string,
    nokPhone: string,
    nokEmail: string
}

interface ViewUserProps {
    userRep?: InstUserDataType
    isIdp?: boolean
}

const RoleFormatter = (role?: string) => {

    return <span>{role?.charAt(0).toUpperCase()}{role?.slice(1)}</span>;

};

const ViewUser = ({ userRep, isIdp }: ViewUserProps) => {
    const [user, setUser] = useState<InstUserDataType>();
    const [doctorInfo, setDoctorInfo] = useState<DoctorInfoType>();
    const [languages, setLanguages] = useState<string>();
    const [allergies, setAllergies] = useState<string>();
    const [patientNOK, setPatientNOK] = useState<NOKType>();

    const { sessionInstitution } = useInstitutionSession();
    const navigate = useNavigate();

    const notify = (type: ToastTypes, msg: string) => (
        toast(msg, { type: type, position: "top-center", theme: "colored" })
    );

    useEffect(() => {
        const fetchData = async () => {
            const url = `${process.env.REACT_APP_USER_ROOT_URL}/${userRep?.userId}`;

            const res = await connectivityService().makeApiCall("get", url, undefined, sessionInstitution.accessToken);
            const infoUrl = `${process.env.REACT_APP_DOCTOR_ROOT_URL}/${userRep?.userId}/qualification`;
            const languageUrl = `${process.env.REACT_APP_DOCTOR_ROOT_URL}/${userRep?.userId}/languages`;

            if (res.code === 200 || res.code === 201) {
                setUser(res.data);
                if (userRep?.userRole === "doctor") {
                    const infoRes = await connectivityService().makeApiCall("get", infoUrl, undefined, sessionInstitution.accessToken);
                    if (infoRes.code === 200 || infoRes.code === 201) {
                        setDoctorInfo(infoRes.data);
                        const languageRes = await connectivityService().makeApiCall("get", languageUrl, undefined, sessionInstitution.accessToken);
                        if (languageRes.code === 200 || languageRes.code === 201) {
                            const languages: Array<String> = [];
                            languageRes.data.map((val: any, ind: any) => {
                                return languages.push(val.language);
                            })
                            setLanguages(languages.join(", "));
                        }
                        else {
                            const error = await connectivityService().handleErrors(languageRes, notify)
                            if (error === "AccessDenied") {
                                notify("error", "Login session expired. Please re-login.")
                                navigate("/admin")
                            }
                        }
                    }
                    else {
                        const error = await connectivityService().handleErrors(infoRes, notify)
                        if (error === "AccessDenied") {
                            notify("error", "Login session expired. Please re-login.")
                            navigate("/admin")
                        }
                    }
                }
                if (userRep?.userRole === "patient") {
                    const nok_url = `${process.env.REACT_APP_USER_ROOT_URL}/${userRep?.userId}/nok`;
                    const allergies_url = `${process.env.REACT_APP_USER_ROOT_URL}/${userRep?.userId}/allergy`;
                    const resAllergy = await connectivityService().makeApiCall("get", allergies_url, undefined, sessionInstitution.accessToken);

                    if (resAllergy.code === 200 || resAllergy.code === 201) {
                        const allergies: Array<String> = [];
                        resAllergy.data.map((val: any, ind: any) => {
                            return allergies.push(val.allergy);
                        })
                        setAllergies(allergies.join(", "));
                        const resNOK = await connectivityService().makeApiCall("get", nok_url, undefined, sessionInstitution.accessToken);
                        if (resNOK.code === 200 || resNOK.code === 201) {
                            setPatientNOK(resNOK.data[0]);
                        }
                        else {
                            const error = await connectivityService().handleErrors(resNOK, notify)
                            if (error === "AccessDenied") {
                                notify("error", "Login session expired. Please re-login.")
                                navigate("/admin")
                            }
                        }
                    }
                    else {
                        const error = await connectivityService().handleErrors(resAllergy, notify)
                        if (error === "AccessDenied") {
                            notify("error", "Login session expired. Please re-login.")
                            navigate("/admin")
                        }
                    }
                }
            }
            else {
                const error = await connectivityService().handleErrors(res, notify)
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.")
                    navigate("/admin")
                }
            }
        }
        fetchData();
    }, []);

    return (
        <>
            <Modal>
                <p className="head-1">View</p>
                <p className="head-2">User's Details</p>
                {isIdp ?
                    <div className="content-inner">
                        <div className="row row1">
                            <div>
                                <p className="head-3">Name</p>
                                <p className="value">{user?.firstName ?? "loading..."} {user?.lastName}</p>
                            </div>
                            <div>
                                <p className="head-3">Username</p>
                                <p className="value">{user?.userName ?? "loading..."}</p>
                            </div>
                        </div>
                        <div className="row row2">
                            <div>
                                <p className="head-3">Date of Birth</p>
                                <p className="value">{user?.dateOfBirth ?? "loading..."}</p>
                            </div>
                            <div>
                                <p className="head-3">Gender</p>
                                <p className="value">{user?.gender === "M" ? "Male" : "Female" ?? "loading..."}</p>
                            </div>
                        </div>
                    </div> :
                    userRep?.userRole === "doctor" ?
                        <div className="content-inner">
                            <div className="row row1">
                                <div>
                                    <p className="head-3">Name</p>
                                    <p className="value">{user?.firstName ?? "loading..."} {user?.lastName}</p>
                                </div>
                                <div>
                                    <p className="head-3">Gender</p>
                                    <p className="value">{user?.gender === "M" ? "Male" : "Female" ?? "loading..."}</p>
                                </div>
                            </div>
                            <div className="row row1">
                                <div>
                                    <p className="head-3">Role</p>
                                    <p className="value">{RoleFormatter(userRep?.userRole) ?? "loading..."}</p>
                                </div>
                                <div>
                                    <p className="head-3">Phone Number</p>
                                    <p className="value">{user?.phoneNumber ?? "loading..."}</p>
                                </div>
                            </div>
                            <div className="row row1">
                                <div>
                                    <p className="head-3">Email Address</p>
                                    <p className="value">{user?.email ?? "loading..."}</p>
                                </div>
                            </div>
                            <div className="row row3">
                                <div>
                                    <p className="head-3">Date of Birth</p>
                                    <p className="value">{user?.dateOfBirth ?? "loading..."}</p>
                                </div>
                                <div>
                                    <p className="head-3">Country</p>
                                    <p className="value">{doctorInfo?.licenseCountryOfIssue ?? "-"}</p>
                                </div>
                            </div>
                            <div className="row row4">
                                <div>
                                    <p className="head-4">License Number</p>
                                    <p className="value">{doctorInfo?.licenseNumber ?? "-"}</p>
                                </div>
                                <div>
                                    <p className="head-4">Language</p>
                                    <p className="value">{languages ?? "-"}</p>
                                </div>
                            </div>
                            <div className="row row5">
                                <div>
                                    <p className="head-4">Specialty</p>
                                    <p className="value">{doctorInfo?.specialization ?? "-"}</p>
                                </div>
                                <div>
                                    <p className="head-4">Years of Experience</p>
                                    <p className="value">{doctorInfo?.experience ?? "-"}</p>
                                </div>
                            </div>
                            <div className="row row6">
                                <div >
                                    <p className="head-4">Certificate</p>
                                    <a target={"_blank"} href={doctorInfo?.certificate}>{doctorInfo?.certificate ? "certificate.pdf" : ""}</a>
                                </div>
                                <div >
                                    <p className="head-4">Profile Picture</p>
                                    <a target={"_blank"} href={user?.userImgUri}>{user?.userImgUri ? "picture.jpg" : ""}</a>
                                </div>
                            </div>
                        </div> :
                        <>
                            <div className="content-inner">
                                <div className="row row1">
                                    <div>
                                        <p className="head-3">Name</p>
                                        <p className="value">{user?.firstName ?? "loading..."} {user?.lastName}</p>
                                    </div>
                                    <div>
                                        <p className="head-3">Gender</p>
                                        <p className="value">{user?.gender === "M" ? "Male" : "Female" ?? "loading..."}</p>
                                    </div>
                                </div>
                                <div className="row row1">
                                    <div>
                                        <p className="head-3">Role</p>
                                        <p className="value">{RoleFormatter(userRep?.userRole) ?? "loading..."}</p>
                                    </div>
                                    <div>
                                        <p className="head-3">Phone Number</p>
                                        <p className="value">{user?.phoneNumber ?? "loading..."}</p>
                                    </div>
                                </div>
                                <div className="row row1">
                                    <div>
                                        <p className="head-3">Email Address</p>
                                        <p className="value">{user?.email ?? "loading..."}</p>
                                    </div>
                                </div>
                                <div className="row row2">
                                    <div>
                                        <p className="head-3">Date of Birth</p>
                                        <p className="value">{user?.dateOfBirth ?? "loading..."}</p>
                                    </div>
                                    <div>
                                        <p className="head-3">Allergies</p>
                                        <p className="value">{allergies ?? "-"}</p>
                                    </div>
                                </div>
                                <div className="row row3">
                                    <div >
                                        <p className="head-3">Profile Picture</p>
                                        <a target={"_blank"} href={user?.userImgUri}>{user?.userImgUri ? "picture.jpg" : ""}</a>
                                    </div>
                                </div>
                            </div>
                            <p className="head-2">Next of kin details</p>
                            <div className="content-inner">
                                <div className="row row1">
                                    <div>
                                        <p className="head-3">Name</p>
                                        <p className="value">{patientNOK?.nokFirstName ?? "-"} {patientNOK?.nokLastName}</p>
                                    </div>
                                    <div>
                                        <p className="head-3">Relationship</p>
                                        <p className="value">{patientNOK?.nokRelationship ?? "-"}</p>
                                    </div>
                                </div>
                                <div className="row row2">
                                    <div>
                                        <p className="head-3">Phone Number</p>
                                        <p className="value">{patientNOK?.nokPhone ?? "-"}</p>
                                    </div>
                                    <div>
                                        <p className="head-3">Email Address</p>
                                        <p className="value">{patientNOK?.nokEmail ?? "-"}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </Modal>
        </>
    )

}

export default ViewUser