import styled from "styled-components";
import { P } from "../../../../styles/generalStyles";

export const Container = styled.div`
    height: 100%;
    width: 100%; 
    padding:38.75px 0 26px 0;

`

export const OTPWrappper = styled(Container)`

`

export const OTPHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 106px;
    margin-bottom: 32px;

`

export const OTPnote = styled(P)`
    font-size: 14px;
    line-height: 17px;
    color: #4E4E4E; 
`