import { useState, useEffect } from "react";
import TopMenu from "../../../components/TopMenu";
import StatusWrapper from "../../../components/StatusWrapper";
import Modal from "../../../components/Modal";
import Select from "../../../components/SingleSelect";
import Pagination from "../../../components/Pagination";
import "../../styles/page-styles";
import { Head, ExportBtn } from "../../styles/page-styles";
import {
    ListMenuType,
    TableHeaderInfo,
    DoctorDataType,
    PatientDataType,
    InstitutionDataType,
} from "../../types/DataTypes";
import connectivityService from "../../../service/ConnectivityService";
import DateWrapper from "../../../components/DateWrapper";
import { ToastContainer, toast } from "react-toastify";
import { AppMenuType, ToastTypes } from "../../../components/DataTypes";
import ViewInstitution from "./templates/ViewInstitution";
import ViewDoctor from "./templates/ViewDoctor";
import ViewPatient from "./templates/ViewPatient";
import ActivateUser from "./templates/ActivateUser";
import DeactivateUser from "./templates/DeactivateUser";
import { useAdminSession } from "../../context/AdminSessionContext";
import { useNavigate } from "react-router-dom";
import {
    Loader,
    LoaderWrapper,
    SectionContainer,
    SpacedBetweenColumnBox,
} from "../../../styles";
import GenericTable from "../../../components/GenericUserTable";
import { AppDataType } from "../../../components/DataTypes";
import { sortDataByDate } from "../../../utils";

const itemsPerPage = 8;

type UserType = "Doctors" | "Institutions" | "Patients";

const UsersList = () => {
    const [selectedUser, setSelectedUser] = useState<AppDataType>();
    const [listActionMenus, setListActionMenus] = useState<ListMenuType[]>([]);
    const [showModal, setModalShow] = useState<boolean>(false);
    const [userType, setUserType] = useState<UserType>("Doctors");
    const [tableData, setTableData] = useState<AppDataType[]>();
    const [initialData, setInitialData] = useState<AppDataType[]>();
    const [selectedMenu, setSelectedMenu] = useState<AppMenuType>();
    const [loading, setLoading] = useState<boolean>(false);
    const { sessionAdmin } = useAdminSession();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageRefresh, setPageRefresh] = useState<boolean>(false);
    const [noDataMessage, setNoDataMessage] = useState<string>();
    const navigate = useNavigate();

    let tableHeader: TableHeaderInfo[];

    userType === "Doctors"
        ? (tableHeader = [
              { value: "userId", text: "S/N" },
              { value: "firstName", text: "First Name" },
              { value: "lastName", text: "Last Name" },
              { value: "phoneNumber", text: "Phone Number" },
              { value: "licenseNumber", text: "License Number" },
              { value: "specialization", text: "Specialization" },
              { value: "status", text: "Status", wrapper: StatusWrapper },
              { value: "createdAt", text: "Date", wrapper: DateWrapper },
          ])
        : userType === "Patients"
        ? (tableHeader = [
              { value: "userId", text: "S/N" },
              { value: "firstName", text: "First Name" },
              { value: "lastName", text: "Last Name" },
              { value: "phoneNumber", text: "Phone Number" },
              { value: "status", text: "Status", wrapper: StatusWrapper },
              { value: "createdAt", text: "Date", wrapper: DateWrapper },
          ])
        : (tableHeader = [
              { value: "userId", text: "S/N" },
              { value: "name", text: "Institution Name" },
              { value: "contactEmail", text: "Email Address" },
              { value: "workStatus", text: "Status", wrapper: StatusWrapper },
              { value: "createdAt", text: "Date", wrapper: DateWrapper },
          ]);

    const notify = (type: ToastTypes, msg: string) =>
        toast(msg, { type: type, position: "top-center", theme: "colored" });

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const url =
                userType === "Doctors"
                    ? process.env.REACT_APP_GET_DOCTORS_URL
                    : userType === "Patients"
                    ? process.env.REACT_APP_GET_PATIENTS_URL
                    : process.env.REACT_APP_GET_INSTITUTIONS_URL;

            const res = await connectivityService().makeApiCall(
                "get",
                url,
                undefined,
                sessionAdmin.accessToken
            );
            setLoading(false);

            if (res.code === 200 || res.code === 201) {
                if (userType === "Doctors") {
                    const doctorData = res.data.map((val: any, key: any) => {
                        return {
                            userId: val.one2one_user.userId,
                            firstName: val.one2one_user.firstName,
                            lastName: val.one2one_user.lastName,
                            phoneNumber: val.one2one_user.phoneNumber,
                            status: val.one2one_user.status,
                            gender: val.one2one_user.gender,
                            dateOfBirth: val.dateOfBirth,
                            licenseNumber: val.licenseNumber,
                            specialization: val.specialization,
                            createdAt: val.createdAt,
                        };
                    });

                    const sortedDocData = sortDataByDate(
                        doctorData,
                        "createdAt"
                    );
                    setTableData(sortedDocData);
                    setInitialData(sortedDocData);
                } else {
                    const sortedData = sortDataByDate(res.data, "createdAt");
                    setTableData(sortedData);
                    setInitialData(sortedData);
                }
            } else {
                const error = await connectivityService().handleErrors(
                    res,
                    notify
                );
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.");
                    navigate("/admin");
                }
            }
        };

        fetchData();
    }, [userType, pageRefresh, sessionAdmin.accessToken, navigate]);

    const handleMenuClick = (menu: AppMenuType) => {
        setSelectedMenu(menu);
        setModalShow(true);
    };

    const handlePatientActionClick = (item: AppDataType) => {
        setSelectedUser(item);
        const p = item as PatientDataType;
        let menus: ListMenuType[] = [];

        menus =
            p.status.toLowerCase() === "active"
                ? ["View", "Deactivate"]
                : ["View", "Activate"];

        setListActionMenus(menus);
    };

    const handleDoctorActionClick = (item: AppDataType) => {
        setSelectedUser(item);
        const p = item as DoctorDataType;
        let menus: ListMenuType[] = [];

        menus =
            p.status.toLowerCase() === "active"
                ? ["View", "Deactivate"]
                : ["View", "Activate"];

        setListActionMenus(menus);
    };

    const handleInstActionClick = (item: AppDataType) => {
        setSelectedUser(item);
        const p = item as InstitutionDataType;
        let menus: ListMenuType[] = [];

        menus =
            p.workStatus.toLowerCase() === "approved"
                ? ["View", "Suspend"]
                : p.workStatus.toLowerCase() === "pending"
                ? ["View", "Approve"]
                : ["View", "Activate"];

        setListActionMenus(menus);
    };

    const handleAction = () => {
        userType === "Institutions" ? updateInstStatus() : updateUserStatus();
    };

    const updateUserStatus = async () => {
        setLoading(true);
        const code = `${process.env.REACT_APP_ADMIN_INSITUTION_CODE}`;
        const url = `${process.env.REACT_APP_INST_URL}/${code}/user`;
        const data = {
            userId: (selectedUser as PatientDataType | DoctorDataType).userId,
            action: selectedMenu === "Activate" ? "activate" : "deactivate",
        };
        const res = await connectivityService().makeApiCall(
            "put",
            url,
            data,
            sessionAdmin.accessToken
        );
        setLoading(false);

        if (res.code === 200 || res.code === 201) {
            notify(
                "success",
                `User has been ${selectedMenu?.toLowerCase()}d successfully`
            );
            setModalShow(false);
            setPageRefresh(!pageRefresh);
        } else {
            const error = await connectivityService().handleErrors(res, notify);
            if (error === "AccessDenied") {
                notify("error", "Login session expired. Please re-login.");
                navigate("/admin");
            }
        }
    };

    const updateInstStatus = async () => {
        setLoading(true);
        const code = (selectedUser as InstitutionDataType).shortCode;
        const url = `${process.env.REACT_APP_INST_URL}/${code}/workstatus`;
        const data = {
            adminId: sessionAdmin.userId,
            action:
                selectedMenu === "Approve" || selectedMenu === "Activate"
                    ? "APPROVE"
                    : "SUSPEND",
        };
        const res = await connectivityService().makeApiCall(
            "put",
            url,
            data,
            sessionAdmin.accessToken
        );
        setLoading(false);

        const actionName =
            selectedMenu === "Approve"
                ? "Approved"
                : selectedMenu === "Activate"
                ? "Activated"
                : "Suspended";

        if (res.code === 200 || res.code === 201) {
            notify(
                "success",
                `Institution has been ${actionName.toLowerCase()} successfully`
            );
            setModalShow(false);
            setPageRefresh(!pageRefresh);
        } else {
            const error = await connectivityService().handleErrors(res, notify);
            if (error === "AccessDenied") {
                notify("error", "Login session expired. Please re-login.");
                navigate("/admin");
            }
        }
    };

    const verifyInstitution = () => {
        setSelectedMenu("Approve");
        updateInstStatus();
    };

    const downloadData = () => {
        let json = tableData as AppDataType[];
        let fields = Object.keys(json[0]);
        let csv = json.map((row) => {
            const valuesArray = Object.values(row);
            return valuesArray.toString();
        });
        csv.unshift(fields.join(","));
        let csv_ = csv.join("\r\n");

        let downloadLink = document.createElement("a");
        downloadLink.href = "data:text/csv;charset=utf-8," + encodeURI(csv_);
        downloadLink.target = "_blank";
        downloadLink.download = `${userType}.csv`;
        downloadLink.click();
    };

    const searchDoctor = (data: string) => {
        if (data === "") {
            setNoDataMessage("No doctor has been created");
            setTableData(initialData);
        } else {
            setNoDataMessage("Doctor not found");
            const result = (initialData as DoctorDataType[])?.filter((val) => {
                return (
                    val.firstName.toLowerCase().includes(data.toLowerCase()) ||
                    val.lastName.toLowerCase().includes(data.toLowerCase()) ||
                    val.specialization
                        .toLowerCase()
                        .includes(data.toLowerCase())
                );
            });
            setTableData(result);
        }
    };

    const searchPatient = (data: string) => {
        if (data === "") {
            setTableData(initialData);
            setNoDataMessage("No patient has been created");
        } else {
            setNoDataMessage("Patient not found");
            const result = (initialData as PatientDataType[])?.filter((val) => {
                return (
                    val.firstName.toLowerCase().includes(data.toLowerCase()) ||
                    val.lastName.toLowerCase().includes(data.toLowerCase())
                );
            });
            setTableData(result);
        }
    };

    const searchInstitution = (data: string) => {
        if (data === "") {
            setNoDataMessage("No institution has been created");
            setTableData(initialData);
        } else {
            setNoDataMessage("Institution not found");
            const result = (initialData as InstitutionDataType[])?.filter(
                (val) => {
                    return (
                        val.name.toLowerCase().includes(data.toLowerCase()) ||
                        val.institutionType
                            ?.toLowerCase()
                            .includes(data.toLowerCase())
                    );
                }
            );
            setTableData(result);
        }
    };

    const offset = itemsPerPage * (currentPage - 1);
    let displayData = tableData
        ? tableData.slice(offset, offset + itemsPerPage)
        : tableData;

    return (
        <SectionContainer>
            {(selectedMenu === "View" || selectedMenu === "Approve") && (
                <Modal
                    children={
                        userType === "Doctors" ? (
                            <ViewDoctor
                                doctorRep={selectedUser as DoctorDataType}
                            />
                        ) : userType === "Patients" ? (
                            <ViewPatient
                                patientRep={selectedUser as PatientDataType}
                            />
                        ) : (
                            <ViewInstitution
                                institutionRep={
                                    selectedUser as InstitutionDataType
                                }
                                Verify={verifyInstitution}
                                selectedMenu={selectedMenu}
                                loading={loading}
                            />
                        )
                    }
                    show={showModal}
                    onClose={() => setModalShow(false)}
                    style={{ width: "700px", maxHeight: "none" }}
                />
            )}
            {(selectedMenu === "Suspend" || selectedMenu === "Deactivate") && (
                <Modal
                    children={
                        <DeactivateUser
                            deactivate={handleAction}
                            formClose={() => setModalShow(false)}
                            loading={loading}
                            name={
                                userType === "Doctors"
                                    ? "doctor"
                                    : userType === "Patients"
                                    ? "patient"
                                    : "institution"
                            }
                        />
                    }
                    show={showModal}
                    onClose={() => setModalShow(false)}
                    style={{ width: "480px", maxHeight: "none" }}
                />
            )}
            {selectedMenu === "Activate" && (
                <Modal
                    children={
                        <ActivateUser
                            activate={handleAction}
                            formClose={() => setModalShow(false)}
                            loading={loading}
                            name={
                                userType === "Doctors"
                                    ? "doctor"
                                    : userType === "Patients"
                                    ? "patient"
                                    : "institution"
                            }
                        />
                    }
                    show={showModal}
                    onClose={() => setModalShow(false)}
                    style={{ width: "480px", maxHeight: "none" }}
                />
            )}
            <TopMenu
                navTag="Users"
                hasNoButton
                portal="admin"
                hasSearch
                searchBy={
                    userType === "Doctors"
                        ? "doctor name or specialization"
                        : userType === "Institutions"
                        ? "institution name or type"
                        : "patient name"
                }
                searchData={
                    userType === "Doctors"
                        ? searchDoctor
                        : userType === "Institutions"
                        ? searchInstitution
                        : searchPatient
                }
            />

            <SpacedBetweenColumnBox>
                <Head>
                    <div className="option" style={{ height: "100%" }}>
                        <div>
                            <Select
                                title="Select user type"
                                name="userType"
                                options={[
                                    "Doctors",
                                    "Patients",
                                    "Institutions",
                                ]}
                                onChange={(e) => {
                                    setTableData(undefined);
                                    setCurrentPage(1);
                                    setUserType(e.target.value as UserType);
                                }}
                            />
                        </div>
                    </div>

                    <div onClick={downloadData} className="button">
                        <ExportBtn>
                            <p>Export</p>
                            <img
                                src="/assets/admin/icons/export-icon.svg"
                                alt=""
                            />
                        </ExportBtn>
                    </div>
                </Head>

                {tableData !== undefined ? (
                    <>
                        <div className="page-section-admin">
                            <GenericTable
                                data={displayData as AppDataType[]}
                                nullMessage={
                                    noDataMessage ||
                                    (userType === "Doctors"
                                        ? "No Doctor has been created"
                                        : userType === "Patients"
                                        ? "No Patient has been created"
                                        : "No Institution has been created")
                                }
                                showPlusIcon={false}
                                tableHeader={tableHeader}
                                addTableActionBtn
                                onActionClicked={
                                    userType === "Institutions"
                                        ? handleInstActionClick
                                        : userType === "Doctors"
                                        ? handleDoctorActionClick
                                        : handlePatientActionClick
                                }
                                actionMenus={listActionMenus}
                                selectedItem={selectedUser}
                                onMenuClick={handleMenuClick}
                                offset={offset}
                            />
                        </div>
                    </>
                ) : (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                )}

                {tableData !== undefined && tableData.length > itemsPerPage && (
                    <Pagination
                        data={tableData as AppDataType[]}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        itemsPerPage={itemsPerPage}
                    />
                )}
            </SpacedBetweenColumnBox>
            <ToastContainer limit={1} />
        </SectionContainer>
    );
};

export default UsersList;
