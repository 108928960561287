import styled from "styled-components";
import Button from "../../../../components/Button";

const Modal = styled.div`
    gap: 32px;
    display: flex;
    flex-direction: column;

    .head{
        font-weight: 700;
        font-size: 22px;
    }

    .row{
        display: flex;
        flex-direction : row;
        justify-content: space-between;
        button{
            width: 47%;
        }
    }

    .question{
        color: #4E4E4E;
        font-size: 18px;
        font-weight: 400;
    }
`


interface ActivateUserProps {
    activate: () => void,
    formClose?: () => void,
    loading: boolean
}

const ActivateUser = ({ activate, formClose, loading}: ActivateUserProps) => {

    return (
        <>
            <Modal>
               <p className="head">Activate</p>
               <p className="question">Are you sure you want to activate this user?</p>
               <div className="row">
                <Button children="Yes" onClick={activate} style={{width: "200px"}} loading={loading} />
                <Button children="Cancel" onClick={formClose} style={{width: "200px"}} />
               </div>
            </Modal>
        </>
    )

}

export default ActivateUser;