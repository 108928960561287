import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LeftSideBar from './components/LeftSideBar';
import GlobalStyles from './styles/GlobalStyles';
import Login from './pages/Login/Login';
import { ForgotPassword, OTPForm, ResetPassword } from './pages/ForgotPassword';
import Home from './pages/Home/index';
import styled from 'styled-components';
import { AdminSessionProvider } from './context/AdminSessionContext';

const Container = styled.div`
  display: flex;
  height: 100vh;
  // width: 100vw;
  position: relative;

`

const AdminApp: React.FC = () => {
  return (
    <div className="App">
      <Container>
      <AdminSessionProvider>
        <GlobalStyles />
        <Routes>
          <Route element={<LeftSideBar />} >
            <Route path="/" element={<Login />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/verify-otp' element={<OTPForm />} />
            <Route path='/reset-password' element={<ResetPassword />} />
          </Route>
          <Route path='/home/*' element={<Home />} />
        </Routes>
        </AdminSessionProvider>
      </Container>
    </div>
  );
}

export default AdminApp;
