import styled from "styled-components";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { InstitutionTypeSchema } from "../InstitutionType.validators";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import { useState } from "react";
import connectivityService from "../../../../service/ConnectivityService";
import { useAdminSession } from "../../../context/AdminSessionContext";
import { ToastContainer, toast } from 'react-toastify';
import { ToastTypes } from "../../../../components/DataTypes";
import { useNavigate } from "react-router-dom";

const ModalContent = styled.div`
    margin-top : -17px;

    .title{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        p{
            font-weight: 400;
            font-size: 16px;
            a{
                color: #2A6BB0;
            }
        }
    }

    .input-blue-border{
        background: #FFFFFF;
        border: 1px solid #2A6BB0;
        border-radius: 4px;
    }
    
    .input-section{
        margin-top: 32px;
        .button {
            margin-top: 34px;
        }
        label{
            font-weight: 400;
            font-size: 16px;
            color: #4E4E4E;
        }
    }

    .bulk{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 24px;
        align-items: end;
    }

    #button:hover{
        background: #FFFFFF !important;
    }
`

interface AddEntityProps {
    type: string,
    entityName: string,
    entityAction: string,
    entityValue: string,
    formClose: () => void,
    setPageRefresh: React.Dispatch<React.SetStateAction<boolean>>,
    pageRefresh: boolean
}

const AddInstitutionType = ({ type, entityName, entityAction, formClose, entityValue, setPageRefresh, pageRefresh }: AddEntityProps) => {
    const [institutionType, setInstitutionType] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { sessionAdmin } = useAdminSession();
    const navigate = useNavigate();

    const notify = (type: ToastTypes, msg: string) => (
        toast(msg, { type: type, position: "top-center", theme: "colored" })
    );

    const institutionTypeFields = {
        institutionType: entityValue
    }

    const handleSubmit = async (values: typeof institutionTypeFields, actions: FormikHelpers<typeof institutionTypeFields>) => {
        setLoading(true);
        const endpoint = process.env.REACT_APP_INSTITUTION_TYPE_URL

        let institutionTypes = values.institutionType.split(",");

        let payload = {
            "institutionTypes": institutionTypes,
            "createdBy": sessionAdmin.userId
        }

        const res = await connectivityService().makeApiCall("post", endpoint, payload, sessionAdmin.accessToken);

        setLoading(false);

        if (res.code === 200 || res.code === 201) {
            setInstitutionType(values.institutionType);
            actions.resetForm({ values: { institutionType: "" } });
            formClose();
            setPageRefresh(!pageRefresh)
        }
        else {
            const error = await connectivityService().handleErrors(res, notify)
            if (error === "AccessDenied") {
                notify("error", "Login session expired. Please re-login.")
                navigate("/admin")
            }
        }
    }

    return (
        <>
            <Formik
                initialValues={institutionTypeFields}
                onSubmit={handleSubmit}
                validationSchema={InstitutionTypeSchema}>
                {
                    (formik: FormikProps<typeof institutionTypeFields>) => {
                        return (
                            <ModalContent>
                                <div className="title">
                                    {type} Institution Type
                                </div>
                                <div className="input-section">
                                    <Form>
                                        <div style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
                                            <Input
                                                name="institutionType"
                                                type="text"
                                                title="Institution Type"
                                                required
                                            />
                                        </div>
                                        <Button
                                            className="button"
                                            type="submit"
                                            loading={loading}
                                            children={`${entityAction} ${entityName}`}
                                            style={{ marginTop: "24px" }}
                                        />
                                    </Form>
                                </div>
                            </ModalContent>
                        )
                    }
                }
            </Formik>
            <ToastContainer limit={1}/>
        </>
    )

}

export default AddInstitutionType