import { useState, useRef, useEffect } from "react";
import { AppDataType } from "../../../../components/DataTypes";
import { ActionMenusBox } from "../../../styles/page-styles"
import {
  InstUserDataType, MenuType
} from '../../../types/DataTypes'

interface ActionMenusProps {
  display?: boolean,
  menus: MenuType[],
  onActionClicked?: (user: InstUserDataType) => void,
  activator: JSX.Element,
  selectedUser?: AppDataType,
  item?: AppDataType,
  onMenuClick: (m : MenuType) => void,
  type: string
}

const ActionMenus = ({ menus, activator, selectedUser, onMenuClick, item, type }: ActionMenusProps) => {
  const [show, setShow] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);

  const evalClick = (e: globalThis.MouseEvent) => {
    if (!show) return;
    if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
      hideShow();
    }
  };

  const toggleMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (show) window.removeEventListener("click", evalClick);
    setShow(!show);
  };

  const handleMenuClick = (menu: MenuType) => {
    onMenuClick(menu);
  }

  useEffect(() => {
    if (show) window.addEventListener("click", evalClick);
  });

  const hideShow = () => {
    setShow(false);
    window.removeEventListener("click", evalClick);
  };
  return (
    <ActionMenusBox>
    <div ref={menuRef} id="menu" style={{ display: "inline" }}>
      {
        selectedUser?.userId === item?.userId ?         
            <ul className={`popup ${!show ? "p-hide" : ""} ${type}`} onClick={hideShow}>
              {
                menus.map((menu: MenuType, ind: number) => {
                  return (
                    <li key={ind} onClick={() => handleMenuClick(menu)}>{menu}</li>
                  )
                })
              }
            </ul>
         
          : <></>
      }
      <div className='row row-action row-last' id={type} onClick={toggleMenu}>
        {activator}
      </div>
    </div>
    </ActionMenusBox>
  )
}

export default ActionMenus