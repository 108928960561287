import { useState } from 'react';
import Input from '../../../../../components/Input';
import { useCreateAccount } from '../context/PatientCreateAccountContext'
import { FormBox, FormStyle, Heading, TwoColumnInputFlex } from '../../../../styles/generalStyles';
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { NextOfKinInfoSchema } from '../PatientCreateAccount.validators';
import styled from 'styled-components';
import Select from '../../../../../components/Select';
import Button from '../../../../../components/Button';
import { toast, ToastContainer } from 'react-toastify';
import connectivityService from '../../../../../service/ConnectivityService';
import Modal from '../../../../../components/Modal';
import PictureUpload from './PictureUpload';
import PhoneInput from '../../../../components/PhoneInput';


const Btn = styled.button`
    height: 50px;
    border-radius: 8px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    outline: none;
    border: none;
    margin-top: 32px;
    width: 100%;

`
const Container = styled.div`
    padding: 108px 96px 108px 111px;

    @media (max-width : 768px) {
        padding: 80px 0 48px 45px;
    }
`
const Note = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #373737;
    text-align: center;
    margin-top: 8px;
`

const NextOfKinInfo = () => {
    const { inviteSessionUser, subPages } = useCreateAccount();
    const [curSubPage, setCurSubPage] = subPages;
    const [loading, setLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);

    if (inviteSessionUser.id === null) {
        setCurSubPage(0);
    }

    let nextOfKinInfo = {
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        gender: "",
        relationship: ""
    }

    const handleSubmit = async (values: typeof nextOfKinInfo, actions: FormikHelpers<typeof nextOfKinInfo>) => {
        setLoading(true);
        type ToastTypes = 'info' | 'success' | 'warning' | 'error' | 'default';

        const notify = (type: ToastTypes, msg: string) => (
            toast(msg, { type: type, position: "top-center", theme: "colored" })
        )

        const url = `${process.env.REACT_APP_USER_ROOT_URL}/${inviteSessionUser.id}/nok`;

        const payload = {
            nokFirstName: values.firstName,
            nokLastName: values.lastName,
            nokEmail: values.emailAddress,
            gender: values.gender === "Male" ? "M" : values.gender === "Female" ? "F" : "N",
            nokPhone: values.phoneNumber,
            nokRelationship: values.relationship
        }

        const response = await connectivityService()
            .makeApiCallWithoutToken("post", url, payload);

        const data = connectivityService().handleErrorsReturnData(response, notify);

        if (data) {
            next();
        }

        setLoading(false);
    }

    const next = () => {
        setShowModal(true);
    }

    return (
        <>
            <Modal
                children={<PictureUpload />}
                show={showModal}
                onClose={() => setShowModal(false)}
                style={{ width: "585px", padding: "40px 60px", height: 'fit-content' }}
            />
            <Formik initialValues={nextOfKinInfo}
                onSubmit={handleSubmit}
                validationSchema={NextOfKinInfoSchema}
            >
                {
                    (formik: FormikProps<typeof nextOfKinInfo>) => {
                        const toggle = Object.keys(formik.errors).length === 0;
                        return (
                            <div>
                                <Form style={FormStyle}>
                                    <TwoColumnInputFlex>
                                        <Container>
                                            <Heading
                                                style={{ marginBottom: "16px" }}
                                            >
                                                Next of Kin
                                            </Heading>
                                            <Note
                                                style={{
                                                    textAlign: "left",
                                                    marginTop: 0,
                                                }}
                                            >
                                                We need this information in case
                                                of emergency. Fill the details
                                                below.
                                            </Note>
                                            <FormBox style={{ marginTop: 24 }}>
                                                <div className="flex">
                                                    <Input
                                                        title="First name"
                                                        name="firstName"
                                                        type="text"
                                                        placeholder="Enter first name"
                                                        required
                                                    />
                                                    <Input
                                                        title="Last name"
                                                        name="lastName"
                                                        type="text"
                                                        placeholder="Enter last name"
                                                        required
                                                    />
                                                </div>
                                                <Input
                                                    title="Email Address"
                                                    name="emailAddress"
                                                    type="text"
                                                    placeholder="Enter email address"
                                                    required
                                                />
                                                <PhoneInput
                                                    title="Phone number"
                                                    name="phoneNumber"
                                                    placeholder="Phone Number"
                                                    required
                                                />
                                                <Select
                                                    title="Relationship"
                                                    name="relationship"
                                                    options={[
                                                        "Brother",
                                                        "Sister",
                                                        "Spouse",
                                                        "Mother",
                                                        "Father",
                                                    ]}
                                                    placeholder="Select an option"
                                                    required
                                                />
                                                <Select
                                                    title="Gender"
                                                    name="gender"
                                                    options={["Male", "Female"]}
                                                    placeholder="Select an option"
                                                    required
                                                />
                                            </FormBox>
                                            <div
                                                className="flex"
                                                style={{ marginTop: "88px" }}
                                            >
                                                <Btn>
                                                    <Button
                                                        type="submit"
                                                        disabled={!toggle}
                                                        loading={loading}
                                                        children="Next"
                                                    />
                                                </Btn>
                                                <Btn>
                                                    <Button
                                                        type="button"
                                                        onClick={next}
                                                        children="Skip"
                                                    />
                                                </Btn>
                                            </div>
                                            <Note style={{ marginTop: "24px" }}>
                                                Download the Patient app on your
                                                mobile device to get full access
                                            </Note>
                                            <div
                                                className="flex"
                                                style={{ marginTop: "16px" }}
                                            >
                                                <img
                                                    src="/assets/institution/icons/app-store-btn.svg"
                                                    alt="apple-store-button"
                                                />
                                                <img
                                                    src="/assets/institution/icons/google-store-btn.svg"
                                                    alt="google-play-store-button"
                                                />
                                            </div>
                                        </Container>
                                    </TwoColumnInputFlex>
                                </Form>
                                <ToastContainer limit={1} />
                            </div>
                        );

                    }
                }
            </Formik>
        </>
    )
}

export default NextOfKinInfo;
