import { TableHeaderInfo } from "./DataTypes";

const DateWrapper = (props: TableHeaderInfo) => {
    function convertDate(inputFormat: string) {
      var d = new Date(inputFormat);
      let day = d.getDate();
      let month = d.toLocaleString('default', { month: 'short' });;
      let year = d.getFullYear();
      if (isNaN(day) || !day || isNaN(year)) return "Invalid date";
      return `${day} ${month} ${year}`;
    }
  
    return <span>{convertDate(props.value)}</span>;
  };
  
  export default DateWrapper;
  