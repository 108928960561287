import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useInstitutionSession } from '../context/InstitutionSessionContext';

const Styles = styled.div`
    display: none;
    @media (max-width: 1024px) {
        display: block;
    }
`

const MenuBox = styled.div`
    height: auto;
    width: 100%;
    background-color: #2A6BB0;
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

`
const NavContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1px;

    .disabledLink{
        cursor: default !important;
    }

`

const BtnBox = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 8px;
    height: 32px;
    cursor: pointer;
    border-radius: 8px;

    div {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #2A6BB0;
    }

`

type NavButtonProps = {
    tag: string,
    imageUrl: string,
    tagColor: string,
    onClickFunc: () => void,
    bg: string,
    bd?: string
}

type NavButtonType = {
    tag: string,
    imageUrl: string,
    link: string,
    status?: string
}

const NavButton = ({ tag, imageUrl, tagColor, onClickFunc, bg, bd }: NavButtonProps) => {

    return (
        <BtnBox onClick={onClickFunc} style={{ backgroundColor: bg, border: bd ? bd : "none" }}>
            <div >
                <img src={`/assets/institution/icons/${imageUrl}.svg`} alt={`${imageUrl}`} />
                <p style={{ color: tagColor }}>{tag}</p>
            </div>
        </BtnBox>
    )
}

const InstitutionMobileMenu = () => {
    const [curNav, setCurNav] = useState<number>();
    const { sessionInstitution, setSessionInstitution } = useInstitutionSession();

    useEffect(() => {

    })

    const isInstitutionIDP = sessionInstitution.institutionType?.toLowerCase() === "idp";

    const NavButtonCollection: NavButtonType[] = isInstitutionIDP ? [
        {
            tag: "Users",
            imageUrl: "users",
            link: "users"
        },
        // {
        //     tag: "Dashboard",
        //     imageUrl: "dashboard",
        //     link: "dashboard",
        //     status: "inactive"
        // },
        // {
        //     tag: "Roles & Permission",
        //     imageUrl: "light-bulb",
        //     link: "roles-and-permission",
        //     status: "inactive"
        // },
        // {
        //     tag: "Refferals",
        //     imageUrl: "refferal",
        //     link: "referral",
        //     status: "inactive"
        // },
        // {
        //     tag: "Settings",
        //     imageUrl: "settings",
        //     link: "settings",
        //     status: "inactive"
        // },
        // {
        //     tag: "Support",
        //     imageUrl: "support",
        //     link: "support",
        //     status: "inactive"
        // }
    ] :
        [
            {
                tag: "Invites",
                imageUrl: "send",
                link: "invites"
            },
            {
                tag: "Users",
                imageUrl: "users",
                link: "users"
            },
            // {
            //     tag: "Dashboard",
            //     imageUrl: "dashboard",
            //     link: "dashboard",
            //     status: "inactive"
            // },
            // {
            //     tag: "Roles & Permission",
            //     imageUrl: "light-bulb",
            //     link: "roles-and-permission",
            //     status: "inactive"
            // },
            // {
            //     tag: "Refferals",
            //     imageUrl: "refferal",
            //     link: "referral",
            //     status: "inactive"
            // },
            // {
            //     tag: "Settings",
            //     imageUrl: "settings",
            //     link: "settings",
            //     status: "inactive"
            // },
            // {
            //     tag: "Support",
            //     imageUrl: "support",
            //     link: "support",
            //     status: "inactive"
            // }
        ]

    return (
        <Styles>
            <MenuBox>
                <NavContainer>
                    {
                        NavButtonCollection.map((nav, ind) => {
                            return (
                                <NavLink to={`/institution/home/${nav.link}`} key={ind}
                                    style={{ textDecoration: "none" }}
                                    className={nav.status === "inactive" ? "disabledLink" : ""}
                                    onClick={(e) => { nav.status === "inactive" && e.preventDefault() }}>
                                    {({ isActive }) => (
                                        <NavButton
                                            key={ind}
                                            imageUrl={`${nav.imageUrl}-${((curNav === ind || isActive) && nav.status !== "inactive") ? "fill" : "icon"}`}
                                            tag={nav.tag}
                                            tagColor={((curNav === ind || isActive) && nav.status !== "inactive")
                                                ? "#2A6BB0" : nav.status === "inactive"
                                                    ? "#c8c6c6" : "#ffffff"
                                            }
                                            onClickFunc={() => { curNav !== ind && setCurNav(ind) }}
                                            bg={((curNav === ind || isActive) && nav.status !== "inactive") ? "#ffffff" : "#2A6BB0"}
                                        />
                                    )}
                                </NavLink>
                            )
                        })
                    }
                    <NavButton
                        imageUrl="logout-icon"
                        tag="Log out"
                        tagColor="#ffffff"
                        onClickFunc={() => {
                            setSessionInstitution({
                                accessToken: null,
                                shortCode: null,
                                institutionLogo: null,
                                institutionName: null,
                                institutionType: null,
                                userId: null
                            })
                        }}
                        bg="#2A6BB0" />
                </NavContainer>
            </MenuBox>

        </Styles>
    )
}

export default InstitutionMobileMenu;