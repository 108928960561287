import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useInstitutionSession } from '../context/InstitutionSessionContext';
import {
    BtnBox,
    SideBarContainer,
    MenuBox,
    NavContainer,
    TopBox,
    Wrapper,
} from '../styles/generalStyles';

type NavButtonProps = {
    tag: string,
    imageUrl: string,
    tagColor: string,
    onClickFunc: () => void,
    bg: string,
    bd?: string
}

type NavButtonType = {
    tag: string,
    imageUrl: string,
    link: string,
    status?: string
}

const NavButton = ({ tag, imageUrl, tagColor, onClickFunc, bg, bd }: NavButtonProps) => {

    return (
        <BtnBox onClick={onClickFunc} style={{ backgroundColor: bg, border: bd ? bd : "none" }}>
            <div >
                <img src={`/assets/institution/icons/${imageUrl}.svg`} alt={`${imageUrl}`} />
                <p style={{ color: tagColor }}>{tag}</p>
            </div>
        </BtnBox>
    )
}

const LeftSideBarHome = () => {
    const [curNav, setCurNav] = useState<number>();
    const { sessionInstitution, setSessionInstitution } = useInstitutionSession();
    const institutionLogo = sessionInstitution.institutionLogo;
    const institutionName = sessionInstitution.institutionName;
    const AvatarUrl = institutionLogo
        ? (institutionLogo as string)
        : `https://ui-avatars.com/api/?name=${institutionName}&background=5BBCFF&color=fff&rounded=true`;

    useEffect(() => {

    })

    const isInstitutionIDP = sessionInstitution.institutionType?.toLowerCase() === "idp";

    const NavButtonCollection: NavButtonType[] = isInstitutionIDP ? [
        {
            tag: "Users",
            imageUrl: "users",
            link: "users"
        },
        {
            tag: "Dashboard",
            imageUrl: "dashboard",
            link: "dashboard",
            status: "inactive"
        },
        {
            tag: "Roles & Permission",
            imageUrl: "light-bulb",
            link: "roles-and-permission",
            status: "inactive"
        },
        {
            tag: "Refferals",
            imageUrl: "refferal",
            link: "referral",
            status: "inactive"
        },
        {
            tag: "Settings",
            imageUrl: "settings",
            link: "settings",
            status: "inactive"
        },
        {
            tag: "Support",
            imageUrl: "support",
            link: "support",
            status: "inactive"
        }
    ] :
        [
            {
                tag: "Invites",
                imageUrl: "send",
                link: "invites"
            },
            {
                tag: "Users",
                imageUrl: "users",
                link: "users"
            },
            {
                tag: "Dashboard",
                imageUrl: "dashboard",
                link: "dashboard",
                status: "inactive"
            },
            {
                tag: "Roles & Permission",
                imageUrl: "light-bulb",
                link: "roles-and-permission",
                status: "inactive"
            },
            {
                tag: "Refferals",
                imageUrl: "refferal",
                link: "referral",
                status: "inactive"
            },
            {
                tag: "Settings",
                imageUrl: "settings",
                link: "settings",
                status: "inactive"
            },
            {
                tag: "Support",
                imageUrl: "support",
                link: "support",
                status: "inactive"
            }
        ]

    return (
        <SideBarContainer>
            <TopBox>
                <Wrapper>
                    <img src={AvatarUrl} alt="institution-logo"
                        style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "50%"
                        }} />
                    <div>
                        <p>Welcome</p>
                        <p className='user-name'>{institutionName as string}</p>
                    </div>
                </Wrapper>
            </TopBox>
            <MenuBox>
                <NavContainer>
                    {
                        NavButtonCollection.map((nav, ind) => {
                            return (
                                <NavLink to={nav.link} key={ind}
                                    style={{ textDecoration: "none" }}
                                    className={nav.status === "inactive" ? "disabledLink" : ""}
                                    onClick={(e) => { nav.status === "inactive" && e.preventDefault() }}>
                                    {({ isActive }) => (
                                        <NavButton
                                            key={ind}
                                            imageUrl={`${nav.imageUrl}-${((curNav === ind || isActive) && nav.status !== "inactive")? "fill" : "icon"}`}
                                            tag={nav.tag}
                                            tagColor={((curNav === ind || isActive) && nav.status !== "inactive")
                                                ? "#2A6BB0" : nav.status === "inactive"
                                                    ? "#c8c6c6" : "#ffffff"
                                            }
                                            onClickFunc={() => { curNav !== ind && setCurNav(ind) }}
                                            bg={((curNav === ind || isActive) && nav.status !== "inactive") ? "#ffffff" : "#2A6BB0"}
                                        />
                                    )}
                                </NavLink>
                            )
                        })
                    }
                </NavContainer>
                <NavButton
                    imageUrl="logout-icon"
                    tag="Log out"
                    tagColor="#ffffff"
                    onClickFunc={() => {
                        setSessionInstitution({
                            accessToken: null,
                            shortCode: null,
                            institutionLogo: null,
                            institutionName: null,
                            institutionType: null,
                            userId: null
                        })
                    }}
                    bg="#2A6BB0"
                    bd='1px solid #fff' />
            </MenuBox>

        </SideBarContainer>
    )
}

export default LeftSideBarHome
