import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAdminSession } from '../context/AdminSessionContext';
import { BtnBox, SideBarContainer, MenuBox, NavContainer, TopBox, Wrapper } from '../styles/generalStyles';


type NavButtonProps = {
    tag: string,
    imageUrl: string,
    tagColor: string,
    onClickFunc: ()=>void,
    bg: string,
    bd?: string
}

type NavButtonType = {
    tag: string,
    imageUrl: string,
    link: string
}

const NavButtonCollection:NavButtonType[] = [
    {
        tag: "Type & Specialization",
        imageUrl: "institution-type",
        link: "typeandspecialization"
    },
    {
        tag: "Users",
        imageUrl: "users",
        link: "users"
    },
    {
        tag: "Products",
        imageUrl: "products",
        link: "products"
    },
    {
        tag: "Institution",
        imageUrl: "institution",
        link: "institution"
    },
    {
        tag: "Accounts",
        imageUrl: "account",
        link: "accounts"
    },
]

const NavButton = ({tag, imageUrl, tagColor, onClickFunc, bg, bd}:NavButtonProps) => {

    return(
        <BtnBox onClick={onClickFunc} style={{backgroundColor: bg, border: bd ? bd : "none"}}>
            <div >
                <img src={`/assets/admin/icons/${imageUrl}.svg`} alt={`${imageUrl}`} />
                <p style={{color: tagColor}}>{tag}</p>
            </div>
        </BtnBox>
    )
}

const LeftSideBarHome = () => {
    const [curNav, setCurNav] = useState<number>();
    const {sessionAdmin, setSessionAdmin} = useAdminSession();
    const profileImageUrl = sessionAdmin.adminImage
        ? (sessionAdmin.adminImage as string)
        : `https://ui-avatars.com/api/?name=${sessionAdmin.adminName}&background=5BBCFF&color=fff&rounded=true`;
  return (
    <SideBarContainer>
        <TopBox>
            <Wrapper>
                <img src={profileImageUrl} alt="admin"/>
                <div>
                    <p>Welcome</p>
                    <p className='user-name'>{sessionAdmin.adminName}</p>
                </div>
            </Wrapper>
        </TopBox>
        <MenuBox>
            <NavContainer>
                {
                    NavButtonCollection.map((nav, ind) => {
                        return (
                            <NavLink to={nav.link} key={ind} style={{textDecoration: "none"}}>
                            {({ isActive }) => (
                                <NavButton
                                    key={ind}
                                    imageUrl={`${nav.imageUrl}-${curNav === ind || isActive ? "fill" : "icon"}`}
                                    tag={nav.tag}
                                    tagColor={curNav === ind || isActive ? "#2A6BB0" : "#ffffff"}
                                    onClickFunc={() => { curNav !== ind && setCurNav(ind) }}
                                    bg={curNav === ind || isActive ? "#ffffff" : "#2A6BB0"}
                                    />
                            )}
                        </NavLink>
                        )
                    })
                }
            </NavContainer>
            <NavButton
                imageUrl="logout-icon"
                tag="Log out"
                tagColor="#ffffff"
                onClickFunc={()=> {
                    setSessionAdmin({
                        userId: null,
                        adminImage: null,
                        adminName: null,
                        accessToken: null
                    })
                }}
                bg="#2A6BB0"
                bd='1px solid #fff'/>
        </MenuBox>

    </SideBarContainer>
  )
}

export default LeftSideBarHome
