import styled from "styled-components";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { SingleInviteSchema, BulkInviteSchema } from "../Invites.validators";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import connectivityService from "../../../../service/ConnectivityService";
import { ToastContainer, toast } from "react-toastify";
import { ToastTypes } from "../../../../components/DataTypes";
import { useState } from "react";
import { InviteDataType } from "../../../types/DataTypes";
import toggleButton from "../../../utils/toggleButton";
import { useInstitutionSession } from "../../../context/InstitutionSessionContext";
import PhoneInput from "../../../components/PhoneInput";

const ModalContent = styled.div`
    margin-top: -17px;

    .phone-number-form-group {
        flex: 1;
        width: 200px;

        select {
            padding: 5px;
        }
    }

    .file-input {
        height: 50px;
        width: 100%;
        padding: 13px;
    }

    .title {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        p {
            font-weight: 400;
            font-size: 16px;
            a {
                color: #2a6bb0;
            }
        }
    }

    .input-blue-border {
        background: #ffffff;
        border: 1px solid #2a6bb0;
        border-radius: 4px;
    }

    .input-section {
        margin-top: 16px;
        .button {
            margin-top: 34px;
        }
        label {
            font-weight: 400;
            font-size: 16px;

            color: #4e4e4e;
        }
    }

    .bulk {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 24px;
        align-items: end;
    }

    #button.img-btn:hover {
        background: #2a6bb0 !important;
    }
`;

type InviteObj = {
    email: string;
    phoneNumber: string;
    role: string;
};

interface AddEntityProps {
    type: string;
    entityName: string;
    addSingleInvite: (invite: InviteDataType) => void;
    formClose: () => void;
}

const AddInvite = ({
    type,
    entityName,
    formClose,
    addSingleInvite,
}: AddEntityProps) => {
    const [loading, setLoading] = useState<boolean>(false);

    const { sessionInstitution } = useInstitutionSession();

    const institutionCode = sessionInstitution.shortCode;
    const url = `${process.env.REACT_APP_INST_URL}/${institutionCode}/userInvites`;

    const navigate = useNavigate();
    const notify = (type: ToastTypes, msg: string) =>
        toast(msg, { type: type, position: "top-center", theme: "colored" });

    const singleFields = {
        email: "",
        phoneNumber: "",
        role: "",
    };

    const bulkFields = {
        file: [],
    };

    const handleSingleSubmit = async (
        values: typeof singleFields,
        actions: FormikHelpers<typeof singleFields>
    ) => {
        setLoading(true);

        const dataObj = {
            email: values.email,
            phoneNumber: values.phoneNumber,
            userRole: values.role.toLowerCase(),
        };

        const data = {
            invites: [dataObj],
            host: "institution",
        };

        const response = await connectivityService().makeApiCall(
            "post",
            url,
            data,
            sessionInstitution.accessToken
        );

        setLoading(false);

        if (response.code === 200 || response.code === 201) {
            notify("success", "User invited successfuly");
            actions.resetForm({
                values: { email: "", phoneNumber: "", role: "" },
            });
            formClose();
            addSingleInvite(dataObj as InviteDataType);
        } else {
            const error = await connectivityService().handleErrors(
                response,
                notify
            );
            if (error === "AccessDenied") {
                notify("error", "Login session expired. Please re-login.");
                navigate("/institution/login");
            }
        }
    };

    const handleBulkSubmit = (
        values: typeof bulkFields,
        actions: FormikHelpers<typeof bulkFields>
    ) => {
        convertToObject(values.file);
    };

    const notifyError = () => {
        notify("error", "File format is incorrect");
        return;
    };

    const validateCsv = (header: string, data: string) => {
        const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        const phoneRegex =
            /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
        if (data === "") {
            return false;
        } else if (header === "email") {
            if (!data.match(emailRegex)) {
                return false;
            }
        } else if (header === "phoneNumber") {
            if (!data.match(phoneRegex)) {
                return false;
            }
        } else {
            if (data !== "Doctor" && data !== "Patient") {
                return false;
            }
        }
        return true;
    };

    const convertToObject = (data: File[]) => {
        const fileReader = new FileReader();
        fileReader.readAsText(data[0]);
        fileReader.onload = async (r) => {
            const arr = (fileReader.result as string).split("\n");
            const jsonObj = [];
            const headers_ = arr[0].split(",");

            const headersText: Array<string> = [];
            const headers: Array<string> = [];

            headers_.forEach((val) => {
                headersText.push(val.trim().toLowerCase());
            });

            if (
                headersText.length !== 3 ||
                !headersText.includes("email address") ||
                !headersText.includes("phone number") ||
                !headersText.includes("role")
            ) {
                notifyError();
            } else {
                headers_.forEach((val, key) => {
                    if (val.trim().toLowerCase() === "email address") {
                        headers.push("email");
                    } else if (val.trim().toLowerCase() === "phone number") {
                        headers.push("phoneNumber");
                    } else {
                        headers.push("role");
                    }
                });
                for (let i = 1; i < arr.length; i++) {
                    let data: Array<string> = arr[i].split(",");
                    let obj: InviteObj = {
                        email: "",
                        phoneNumber: "",
                        role: "",
                    };
                    for (var j = 0; j < data.length; j++) {
                        if (!validateCsv(headers[j], data[j].trim())) {
                            notifyError();
                            return;
                        }
                        obj[headers[j] as keyof InviteObj] = data[j].trim();
                    }
                    jsonObj.push(obj);
                }
                navigate("/institution/home/invites/list", {
                    state: { invites: jsonObj },
                });
            }
        };
    };

    return type === "Single" ? (
        <>
            <Formik
                initialValues={singleFields}
                onSubmit={handleSingleSubmit}
                validationSchema={SingleInviteSchema}
            >
                {(formik: FormikProps<typeof singleFields>) => {
                    const toggle =
                        toggleButton<InviteObj>(formik.values) &&
                        Object.keys(formik.errors).length === 0;
                    return (
                        <ModalContent>
                            <div className="title">{type} Invite</div>
                            <div className="input-section">
                                <Form>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "15px",
                                        }}
                                    >
                                        <Input
                                            name="email"
                                            type="text"
                                            title="Email Address"
                                            required
                                        />
                                        <PhoneInput
                                            title="Phone number"
                                            name="phoneNumber"
                                            placeholder="Phone Number"
                                            required
                                        />
                                        {/* <Input
                                                    name="phoneNumber"
                                                    type="text"
                                                    title="Phone Number"
                                                    required
                                                /> */}
                                        <Select
                                            name="role"
                                            title="Role"
                                            options={["Patient", "Doctor"]}
                                            placeholder="Select role"
                                            required
                                        />
                                    </div>
                                    <Button
                                        className="button"
                                        type="submit"
                                        loading={loading}
                                        disabled={!toggle}
                                        children={`Add ${entityName}`}
                                        style={{ marginTop: "24px" }}
                                    />
                                </Form>
                            </div>
                        </ModalContent>
                    );
                }}
            </Formik>
            <ToastContainer limit={1} />
        </>
    ) : (
        <>
            <Formik
                initialValues={bulkFields}
                onSubmit={handleBulkSubmit}
                validationSchema={BulkInviteSchema}
            >
                {(formik: FormikProps<typeof bulkFields>) => {
                    return (
                        <ModalContent>
                            <div className="title">
                                {type} Invite
                                <p>
                                    Your csv file should follow this{" "}
                                    <a
                                        href="https://docs.google.com/spreadsheets/d/1jk7Y1c3x1XHXvG58E5EOtks3368Q6JAJ-Ymp1fcmT38/edit#gid=565447122"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        format
                                    </a>
                                </p>
                            </div>
                            <div className="input-section">
                                <Form>
                                    <div className="bulk">
                                        <input
                                            className="input-blue-border file-input"
                                            name="file"
                                            title="Choose file"
                                            type="file"
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                formik.setFieldValue(
                                                    "file",
                                                    e.currentTarget.files
                                                );
                                            }}
                                            accept=".csv, text/csv"
                                        />
                                        <Button
                                            type="submit"
                                            className="button img-btn"
                                            disabled={false}
                                            children={
                                                <img
                                                    src="/assets/institution/icons/upload-icon.svg"
                                                    alt=""
                                                />
                                            }
                                        />
                                    </div>
                                </Form>
                            </div>
                        </ModalContent>
                    );
                }}
            </Formik>
            <ToastContainer limit={1} />
        </>
    );
};

export default AddInvite;
