import styled from "styled-components";
import React from 'react';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    gap: 4px;


    .star{
        position: absolute;
        bottom: 16px;
        left: 10px;
        pointer-events: none;
        color: grey;
        transition: .5s;
        transform: translateY(-20px);
        font-size: 12px;
    }
`

const Box = styled.div`
    width: 100%;
    height: 105px;
    background: #ECF5FF;
    border: 1px dashed #2A6BB0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Label = styled.div`
    float: lefet;
`

interface FileInputProps {
    title: string;
    children?: JSX.Element
    required?: boolean
    style?: React.CSSProperties
    align?: string
    files: File[]
    placeholder?: string
}

const FileInput = ({ title, children, required, style, align, files, placeholder }: FileInputProps) => {
    return (
        <Container style={{ alignItems: `${align ? align : 'start'}` }}>
            <Label className="label">
                {title}
                <span style={{ marginLeft: "3px" }}>{required && "*"}</span>
                <span style={{marginLeft: '8px'}}>
                    {placeholder? placeholder : files.length > 0 && `(${files[0].name})`}
                </span>
            </Label>
            <Box style={style}>
                {children}
            </Box>
        </Container>
    )
}

export default FileInput
