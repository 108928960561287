import { useEffect, useState } from "react";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { FormBox, FormStyle, Heading } from "../../styles/generalStyles";
import Input from "../../../components/Input";
import AuthPagesFooter from "../../components/AuthPagesFooter";
import toggleButton from "../../utils/toggleButton";
import { ResetPasswordSchema } from "./ForgotPassword.validators";
import { ResetPasswordWrapper } from "./ForgotPassword.style";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastTypes } from "../../types/DataTypes";
import { toast, ToastContainer } from "react-toastify";
import connectivityService from "../../../service/ConnectivityService";
import DarkModal from "../../../components/DarkModal";
import EmailSuccessModal from "../../../components/EmailSuccessModal";

type ResetPasswordData = {
    password: string;
    confirmPassword: string;
};

type StateType = {
    userId: string;
};

const ResetPassword = () => {
    const resetPasswordData = {
        password: "",
        confirmPassword: "",
    };

    const [loading, setLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);

    const navigate = useNavigate();

    const location = useLocation();

    const userId = (location?.state as StateType)?.userId;

    useEffect(() => {
        if (!userId) {
            navigate(-1);
        }
    }, [navigate, userId]);

    const handleSubmit = async (
        values: ResetPasswordData,
        actions: FormikHelpers<ResetPasswordData>
    ) => {
        setLoading(true);

        const notify = (type: ToastTypes, msg: string) =>
            toast(msg, {
                type: type,
                position: "top-center",
                theme: "colored",
            });

        const url = `${process.env.REACT_APP_USER_ROOT_URL}/admin/${userId}/updatePassword`;

        const data = {
            newPassword: values.password,
        };

        const response = await connectivityService().makeApiCallWithoutToken(
            "post",
            url,
            data
        );

        if (response.code === 200 || response.code === 201) {
            setShowModal(true);
        } else {
            connectivityService().handleErrors(response, notify);
        }

        setLoading(false);
    };

    return (
        <Formik
            initialValues={resetPasswordData}
            onSubmit={handleSubmit}
            validationSchema={ResetPasswordSchema}
        >
            {(formik: FormikProps<ResetPasswordData>) => {
                const toggle =
                    toggleButton<ResetPasswordData>(formik.values) &&
                    Object.keys(formik.errors).length === 0;
                return (
                    <ResetPasswordWrapper>
                        <Form style={FormStyle}>
                            <div style={{ padding: "0 40px 0 55px" }}>
                                <p
                                    className="close"
                                    onClick={() =>
                                        navigate("/institution/login")
                                    }
                                >
                                    X
                                </p>
                                <Heading>Enter new password</Heading>
                                <FormBox>
                                    <Input
                                        title="New password"
                                        name="password"
                                        type="password"
                                        placeholder="Password"
                                    />

                                    <Input
                                        title="Confirm password"
                                        name="confirmPassword"
                                        type="password"
                                        placeholder="Password"
                                    />
                                </FormBox>
                            </div>
                            <AuthPagesFooter
                                note="Are you new? "
                                link="Create a free account"
                                to="/"
                                btn="Confirm"
                                loading={loading}
                                shouldDisable={!toggle}
                            />
                        </Form>
                        {showModal && (
                            <DarkModal>
                                <EmailSuccessModal
                                    handleProceed={() => {
                                        setShowModal(false);
                                        navigate("/institution/login");
                                    }}
                                    cta="Proceed To Login"
                                    msg="Your password has been reset successfully."
                                />
                            </DarkModal>
                        )}
                        <ToastContainer limit={1} />
                    </ResetPasswordWrapper>
                );
            }}
        </Formik>
    );
};

export default ResetPassword;
