import * as yup from 'yup';

export const SingleInviteSchema = yup.object({
    email: yup
        .string()
        .trim()
        .email('This is not a valid email')
        .required('Email field is empty'),
    phoneNumber: yup
        .string()
        .trim()
        .min(9, "Phone number should be up to 9 characters")
        .required("This field is required"),
    role: yup
        .string()
        .required("This field is required")
});

export const BulkInviteSchema = yup.object({
    file: yup
        .mixed()
        .required('File is required')
});