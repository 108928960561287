import { useState, useEffect } from 'react';
import Input from '../../../../../components/Input';
import { useCreateAccount } from '../context/DoctorCreateAccountContext'
import { FormBox, FormStyle, Heading, TwoColumnInputFlex } from '../../../../styles/generalStyles';
import { Formik, Form, FormikProps, FormikHelpers } from 'formik';
import { ProfessionalInfoSchema } from '../DoctorCreateAccount.validators';
import styled from 'styled-components';
import Select from '../../../../../components/Select';
import Button from '../../../../../components/Button';
import TextArea from '../../../../../components/TextArea';
import en from "react-phone-number-input/locale/en.json";
import ClickUpload from "../../../../../institution/components/ClickUpload"
import FileInput from '../../../../components/FileInput';
import Modal from '../../../../../components/Modal';
import UploadPicture from './PictureUpload';
import toggleButton from '../../../../utils/toggleButton';
import connectivityService from '../../../../../service/ConnectivityService';
import { toast, ToastContainer } from 'react-toastify';
import { ToastTypes } from '../../../../types/DataTypes';

const LanguagesList = styled.div`
    display: flex;
    gap: 16px;
    color: #2A6BB0;
    width: 100%;
    flex-wrap: wrap;
    p {
        padding: 8px;
        border: 1px solid #2A6BB0;
        border-radius: 17px;
        span{
            margin-left: 8px;
            margin-right: 2px;
            cursor: pointer;
        }
    }
`

const Btn = styled.div`
    height: 50px;
    border-radius: 8px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    outline: none;
    border: none;
    margin-top: 32px;
    width: 100%;

`
const Container = styled.div`
    padding: 108px 96px 108px 111px;

    @media (max-width : 768px) {
        padding: 80px 0 48px 45px;
    }
`

const Note = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #373737;
    margin-bottom: 24px;
`
const Flex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .position{
        width: fit-content;
        height: fit-content;
        position: relative;
        img{
            position: absolute;
            top: 0;
            left: 0;
        }
    }
`

const ProfessionalInfo = () => {
    const { inviteSessionUser, subPages } = useCreateAccount();
    const [loading, setLoading] = useState<boolean>(false);
    const [curSubPage, setCurSubPage] = subPages;
    const [file, setFile] = useState<File[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [languageArray, setLanguageArray] = useState<Array<string>>([]);
    const [isLanguageFirstMount, setIsLanguageFirstMount] = useState<boolean>(true);
    const [specializations, setSpecializations] = useState<string[]>([]);


    if (inviteSessionUser.id === null) {
        setCurSubPage(0);
    }

    const professionalInfo = {
        licenseNumber: "",
        country: "",
        specialty: "",
        yearsOfExperience: "",
        language: "",
        about: "",
        certificate: []
    };
    const countries = Object.values(en).slice(3, 255).sort();

    const handleRemoval = (name: string) => {
        const newLanguageArray = languageArray.filter((val) => (val !== name));
        setLanguageArray(newLanguageArray);
    }

    const notify = (type:ToastTypes , msg: string) => (
        toast(msg, {type: type, position: "top-center", theme: "colored"})
    )

    const handleSubmit = async (values: typeof professionalInfo, actions: FormikHelpers<typeof professionalInfo>) => {
        console.log(values);
        let formData = new FormData();

        const qualificationObject = {
            licenseNumber: values.licenseNumber,
            specialization: values.specialty,
            experience: values.yearsOfExperience,
            licenseCountryOfIssue: values.country,
            certificate: values.certificate
        }


        formData.append("doctorBiography", JSON.stringify({biography: values.about}));
        formData.append("doctorLanguages", JSON.stringify({languages: languageArray.toString()}));
        formData.append("doctorQualification", JSON.stringify(qualificationObject));
        formData.append("certificate", file[0]);

        const url = `${process.env.REACT_APP_DOCTOR_ROOT_URL}/${inviteSessionUser.id}/professionalInformation`;
        setLoading(true);
        const response = await connectivityService().makeApiCallWithFormData("post", url, formData, true);

        const data = connectivityService().handleErrorsReturnData(response, notify);

        if (data) {
            setShowModal(true);

        }
        setLoading(false);

    }

    useEffect(() => {
        const fetchSpecializations = async () => {
            const url = process.env.REACT_APP_SPECIALIZATION_URL;

            const res = await connectivityService().makeApiCallWithoutToken(
                "get",
                url,
                undefined,
            );

            if (res.code === 200 || res.code === 201) {
                const sortedSpecializations = res.data
                .map((item: { specialization: string }) => item.specialization)
                .sort();

                setSpecializations(sortedSpecializations);
            } else {
                connectivityService().handleErrors(
                    res,
                    notify
                );
            }
        };

        fetchSpecializations();
    }, []);

    return (
        <>
            <Modal
            children={<UploadPicture />}
            show={showModal}
            onClose={() => setShowModal(false)}
            style={{width: "585px", padding: "40px 60px", height: 'fit-content'}}
            />
            <Formik initialValues={professionalInfo}
                onSubmit={handleSubmit}
                validationSchema={ProfessionalInfoSchema}
            >
                {
                    (formik: FormikProps<typeof professionalInfo>) => {
                        const toggle = toggleButton<typeof professionalInfo>(formik.values) && languageArray.length !== 0;
                        return (
                            <div >
                                <Form style={FormStyle}>
                                    <TwoColumnInputFlex>
                                        <Container>
                                            <Heading style={{ marginBottom: '16px' }} >Professional Information</Heading>
                                            <Note style={{}}>Something about health for Patient and why we are collecting the information</Note>
                                            <FormBox>
                                                <div className='flex'>
                                                    <Input
                                                        title='License number'
                                                        name='licenseNumber'
                                                        type='text'
                                                        placeholder='Enter license number'
                                                        required
                                                    />
                                                    <Select
                                                        title='Country'
                                                        name='country'
                                                        options={countries}
                                                        placeholder="Pick an option"
                                                        required
                                                    />
                                                </div>
                                                <div className='flex'>
                                                    <Select
                                                        title='Specialty'
                                                        name='specialty'
                                                        options={specializations}
                                                        placeholder="Select specialty"
                                                        required
                                                    />
                                                    <Input
                                                        title='Years of Experience'
                                                        name='yearsOfExperience'
                                                        type='text'
                                                        placeholder='Enter years of experience'
                                                        required
                                                    />
                                                </div>


                                                <Select
                                                    title='Language'
                                                    name='language'
                                                    options={['Select language', 'English', 'Yoruba', 'Hausa', 'Igbo', 'German', 'French', 'Spanish', 'Igala']}
                                                    placeholder="Select language"
                                                    forceError={!isLanguageFirstMount && languageArray.length === 0 }
                                                    tempOnChange={(e)=>{
                                                        !languageArray.includes(e.target.value) && e.target.value !== "" &&
                                                        e.target.value !== 'Select language' && setLanguageArray([...languageArray, e.target.value]);
                                                        isLanguageFirstMount && formik.setFieldValue('language', 'Select language');
                                                        isLanguageFirstMount && setIsLanguageFirstMount(false);
                                                    }}
                                                    required
                                                />

                                                <p style={{
                                                    transform: "translateY(-22px)",
                                                    fontSize: "14px",
                                                    color: "#ED1C24",
                                                    margin: 0,
                                                    padding: 0

                                                }}
                                                    >{!isLanguageFirstMount && languageArray.length === 0 && "This field is required"}</p>
                                                <LanguagesList>
                                                    {languageArray.length > 0 && languageArray.map((language: string, index: number) => {
                                                        return <p key={index}>{language}<span onClick={() => handleRemoval(language)}>x</span></p>
                                                    })}
                                                </LanguagesList>

                                                <TextArea
                                                    title='Tell us about yourself'
                                                    name='about'
                                                    placeholder='Type here...'
                                                    required
                                                />
                                                <FileInput
                                                    title="Upload your certificate"
                                                    children={
                                                        <Flex>
                                                            <div className='position'>
                                                                <ClickUpload
                                                                    label=''
                                                                    fileType='file'
                                                                    prevState={file}
                                                                    stateFunc={setFile}
                                                                    multiple={false}
                                                                />
                                                                <img src="/assets/institution/images/upload.png" alt="upload" />
                                                            </div>
                                                            <ClickUpload
                                                                label='Upload your Certificate here (.pdf only)'
                                                                textStyle={{
                                                                    color: "#2A6BB0",
                                                                    width: "205px",
                                                                    fontSize: "12px",
                                                                    borderBottom: "1px solid #2A6BB0",
                                                                    cursor: "pointer",
                                                                    marginTop: "32px"
                                                                }}
                                                                fileType='file'
                                                                prevState={file}
                                                                stateFunc={setFile}
                                                                multiple={false}
                                                            />
                                                        </Flex>
                                                    }
                                                    style={{ padding: "27px 0" }}
                                                    files={file}
                                                />
                                            </FormBox>
                                            <Btn><Button type='submit' disabled={!toggle} children='Next' loading={loading}/></Btn>
                                        </Container>
                                    </TwoColumnInputFlex>
                                </Form>
                                <ToastContainer limit={1}/>
                            </div>
                        )
                    }
                }
            </Formik>
        </>
    )
}

export default ProfessionalInfo;
