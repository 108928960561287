import * as yup from 'yup';

const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
export const SingleInviteSchema = yup.object({
    email: yup
        .string()
        .trim()
        .email('This is not a valid email')
        .required('Email field is empty'),
    phoneNumber: yup
        .string()
        .trim()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required("This field is required"),
    role: yup
        .string()
        .required("This field is required")
});

export const BulkInviteSchema = yup.object({
    file: yup
        .mixed()
        .required('File is required')
});