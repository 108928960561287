import { useState } from "react";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { FormBox, FormStyle, Heading } from '../../../../styles/generalStyles';
import OTPInput from '../../../../components/OTPInput';
import { OTPHeader, OTPnote, OTPWrappper } from '../styles/OtpInput.style'
import { useCreateAccount } from '../context/DoctorCreateAccountContext'
import Button from "../../../../../components/Button";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import connectivityService from "../../../../../service/ConnectivityService";
import { ToastTypes } from "../../../../types/DataTypes";

const ResendNote = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    color: #4E4E4E;
    margin-top: 45px;
        a{
            color: #2A6BB0;
            margin-left: 8px;
        }
`

const Centralize = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        button{
            width: 200px !important;
        }
    }
`

const Note = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #373737;
    text-align: center;
    margin-top: 8px;
`

const OtpInput = () => {
    const { inviteSessionUser, subPages} = useCreateAccount();
    const [curSubPage, setCurSubPage] = subPages;
    const [loading, setLoading] = useState<boolean>(false);

    if (inviteSessionUser.id === null) {
        setCurSubPage(0);
    }

    const otpInfo = {
        otp: ""
    };

    const handleSubmit = async (values: typeof otpInfo, actions: FormikHelpers<typeof otpInfo>) => {;
        setLoading(true);

        const notify = (type:ToastTypes , msg: string) => (
            toast(msg, {type: type, position: "top-center", theme: "colored"})
        )

        const url = `${process.env.REACT_APP_USER_ROOT_URL}/${inviteSessionUser.id}/verify`;

        const response = await connectivityService()
              .makeApiCallWithoutToken("post", url, {code: values.otp});

        const data = connectivityService().handleErrorsReturnData(response, notify);

        if (data) {

            setCurSubPage(2);

        }

        setLoading(false);

    }

    return (
        <Formik initialValues={otpInfo}
            onSubmit={handleSubmit}
        >
            {
                (formik: FormikProps<typeof otpInfo>) => {
                    const toggle = formik.values.otp.length === 4;
                    return (
                        <OTPWrappper>
                            <Form style={FormStyle}>
                                <OTPHeader style={{ marginTop: "20px" }}>
                                    <Heading style={{ margin: 0 }}>Verify Phone number</Heading>
                                    <OTPnote style={{ marginTop: "16px" }}>Please enter the verification code sent to {inviteSessionUser.phoneNumber}</OTPnote>
                                </OTPHeader>
                                <FormBox>
                                    <OTPInput
                                        name='otp' />
                                </FormBox>
                                <ResendNote>Didn’t receive code? <Link to="#">Resend</Link></ResendNote>
                                <Centralize style={{ marginTop: "150px" }}>
                                    <Button type="submit"
                                        children="Verify"
                                        disabled={!toggle}
                                        loading={loading}
                                        style={{ width: "550px" }} />
                                </Centralize>
                                <Note style={{ marginTop: "24px" }}>Download the Patient app on your mobile device to get full access</Note>
                                <Centralize style={{ marginTop: "16px", gap: "24px" }}>
                                    <img src="/assets/institution/icons/app-store-btn.svg" alt="apple-store-button" />
                                    <img src="/assets/institution/icons/google-store-btn.svg" alt="google-play-store-button" />
                                </Centralize>
                            </Form>
                            <ToastContainer limit={1}/>
                        </OTPWrappper>
                    )
                }
            }
        </Formik>
    )
}

export default OtpInput;

