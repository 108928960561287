const connectivityService = () => {
  const constructRequest = (method, payload, accessToken) => {
    // const token = localStorage.getItem("accessToken");
    let request;
    if (method === "post" || method === "put") {
      request = {
        method,
        body: JSON.stringify(payload),
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
    } else {
      request = {
        method,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
    }

    return request;
  };

  const makeApiCall = async (method, url, payload, accessToken) => {
    if (!accessToken) {
      return {
        Error: "Access Token Missing.",
        msg: "This request cannot be completed unless you are logged in.",
      };
    }
    const response = await fetch(
      url,
      constructRequest(method, payload, accessToken)
    )
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        return {
          Error: err,
          msg: "Failed to fetch. Please check your internet connection",
        };
      });

    return response;
  };

  const makeApiCallWithoutToken = async (method, url, payload) => {
    if (method !== "post" && method !== "get") {
      return {
        Error: "Can only make post and get request without token",
        msg: "This request cannot be completed unless you are logged in.",
      };
    }
    const response = await fetch(url, constructRequest(method, payload, ""))
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        return {
          Error: err,
          msg: "Failed to fetch. Please check your internet connection",
        };
      });

    return response;
  };

  const makeApiCallWithFormData = async (
    method,
    url,
    formData,
    accessToken
  ) => {
    // const token = localStorage.getItem("accessToken");
    if (method !== "post" && method !== "put") {
      return {
        Error: "Can only make post and post request with form data",
        msg: "This request cannot be completed.",
      };
    }
    const response = await fetch(url, {
      method,
      body: formData,
      headers: { Authorization: `Bearer ${accessToken ?? ""}` },
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        return {
          Error: err,
          msg: "Failed to fetch. Please check your internet connection",
        };
      });

    return response;
  };

  const handleErrors = (response, msgHandler) => {
    if (response.Error) {
      if (
        response.Error?.message?.replaceAll("'", "") ===
        'Unexpected token A, "Access denied" is not valid JSON'
      ) {
        console.log("AccessDenied");
        return "AccessDenied";
      }
      if (
        response.msg ===
        "An account already exists with the credentials provided."
      ) {
        return "AccountExists";
      }
      msgHandler("error", response.msg);
    } else if (Array.isArray(response)) {
      console.log(response);
    } else if (response.status && response.status === "success") {
      msgHandler("success", response.message);
      return response;
    } else {
      console.log({ unHandledError: response });
      msgHandler(
        "error",
        "Something went wrong while trying to process your request. Please try again."
      );
    }
  };

  const handleErrorsReturnData = (response, msgHandler) => {
    if (response.Error) {
      if (
        response.Error?.message?.replaceAll("'", "") ===
        'Unexpected token A, "Access denied" is not valid JSON'
      ) {
        console.log("AccessDenied");
        return "AccessDenied";
      }
      if (
        response.msg ===
        "An account already exists with the credentials provided."
      ) {
        return "AccountExists";
      }
      console.log(response.Error);
      msgHandler("error", response.msg);
    } else if (Array.isArray(response)) {
      msgHandler("error", response[0].msg);
      console.log(response);
    } else if (response.status && response.status === "success") {
      return response.data ?? "success";
    } else {
      console.log({ unHandledError: response });
      msgHandler(
        "error",
        "Something went wrong while trying to process your request. Please try again."
      );
    }
  };

  return {
    makeApiCall,
    handleErrors,
    makeApiCallWithoutToken,
    makeApiCallWithFormData,
    handleErrorsReturnData,
  };
};

module.exports = connectivityService;
