import { useLocation } from "react-router-dom"
import GeneralBulkUsersList from './GeneralBulkUsersList'
import IDPBulkUsersList from './IDPBulkUsersList'

type userObject = {
    email?: string,
    phoneNumber?: string,
    firstName?: string,
    lastName?: string,
    dob?: string,
    role: string,
    key?: number
}

interface stateType {
    users: userObject[],
    isIDP: boolean
}

const BulkUsersList = () => {
    const location = useLocation();

    return (
        (location?.state as stateType).isIDP ?
            <IDPBulkUsersList users={(location?.state as stateType).users}/> :
            <GeneralBulkUsersList users={(location?.state as stateType).users}/>
    )
}

export default BulkUsersList;