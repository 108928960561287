import React, { useEffect, useState } from "react";
import TopMenu from "../../../components/TopMenu";
import PriceTable from "./templates/PriceTable";
import StatusWrapper from "../../../components/StatusWrapper";
import Modal from "../../../components/Modal";
import AddPrice from "./templates/AddPrice";
import Pagination from "../../components/Pagination";
import {
    PriceListMenuType,
    MenuType,
    PriceDataType,
    ProductDataType,
} from "../../types/DataTypes";
import DateWrapper from "../../../components/DateWrapper";
import connectivityService from "../../../service/ConnectivityService";
import { ToastContainer, toast } from "react-toastify";
import { ToastTypes } from "../../../components/DataTypes";
import ViewPrice from "./templates/ViewPrice";
import { useLocation, useNavigate } from "react-router-dom";
import { useAdminSession } from "../../context/AdminSessionContext";
import { Loader, LoaderWrapper, SectionContainer, SpacedBetweenColumnBox } from "../../../styles";
import { sortDataByDate } from "../../../utils";

const tableHeader = [
    { value: "priceId", text: "ID" },
    { value: "productName", text: "Procuct Name" },
    { value: "price", text: "Price" },
    { value: "volume", text: "Volume" },
    { value: "validFrom", text: "Valid From", wrapper: DateWrapper },
    { value: "validTo", text: "Valid To", wrapper: DateWrapper },
    { value: "isActive", text: "Status", wrapper: StatusWrapper },
];

interface StateType {
    product: ProductDataType;
}

const itemsPerPage = 8;

const Price = () => {
    const [selectedPrice, setSelectedPrice] = useState<PriceDataType>();
    const [listActionMenus, setListActionMenus] =
        useState<PriceListMenuType[]>();
    const [modalType, setModalType] = useState<string>("");
    const [showModal, setModalShow] = useState<boolean>(false);
    const [tableData, setTableData] = useState<PriceDataType[]>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageRefresh, setPageRefresh] = useState<boolean>(false);
    const { sessionAdmin } = useAdminSession();

    const location = useLocation();
    const navigate = useNavigate();

    const handleAddPrice = () => {
        setModalShow(true);
        setModalType("Add");
    };

    const handleMenuClick = async (menu: MenuType) => {
        if (menu === "View details") {
            setModalShow(true);
            setModalType("View");
        } else {
            let data: { action: string | undefined } = {
                action:
                    menu === "Activate"
                        ? "activate"
                        : menu === "Deactivate"
                        ? "deactivate"
                        : undefined,
            };

            if (data.action) {
                const url = `${process.env.REACT_APP_PRODUCT_ROOT_URL}/${product?.productId}/price/${selectedPrice?.id}`;
                const res = await connectivityService().makeApiCall(
                    "post",
                    url,
                    data,
                    sessionAdmin.accessToken
                );
                if (res.code === 200 || res.code === 201) {
                    setPageRefresh(!pageRefresh);
                } else {
                    const error = await connectivityService().handleErrors(
                        res,
                        notify
                    );
                    if (error === "AccessDenied") {
                        notify(
                            "error",
                            "Login session expired. Please re-login."
                        );
                        navigate("/admin");
                    }
                }
            }
        }
    };

    const addPrice = () => {
        console.log("clicked add price");
    };

    const notify = (type: ToastTypes, msg: string) =>
        toast(msg, { type: type, position: "top-center", theme: "colored" });

    useEffect(() => {
        const fetchData = async () => {
            const url = `${process.env.REACT_APP_PRODUCT_ROOT_URL}/${product.productId}/prices`;
            const res = await connectivityService().makeApiCall(
                "get",
                url,
                undefined,
                sessionAdmin.accessToken
            );
            if (res.code === 200 || res.code === 201) {
                const sortedData = sortDataByDate(res.data, "validFrom");
                setTableData(sortedData);
            } else {
                const error = await connectivityService().handleErrors(
                    res,
                    notify
                );
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.");
                    navigate("/admin");
                }
            }
        };
        fetchData();
    }, [pageRefresh]);

    if (!(location?.state as StateType).product) {
        navigate(-1);
    }

    const product = (location?.state as StateType).product;

    const offset = itemsPerPage * (currentPage - 1);
    let displayData = tableData
        ? tableData.slice(offset, offset + itemsPerPage)
        : tableData;

    return (
        <SectionContainer>
            {showModal && (
                <Modal
                    children={
                        modalType === "View" ? (
                            <ViewPrice
                                price={selectedPrice}
                                productName={product.productName}
                            />
                        ) : (
                            <AddPrice
                                addPrice={addPrice}
                                formClose={() => setModalShow(false)}
                                productId={product.productId}
                                pageRefresh={pageRefresh}
                                setPageRefresh={setPageRefresh}
                            />
                        )
                    }
                    show={showModal}
                    onClose={() => setModalShow(false)}
                    style={{ width: "704px" }}
                />
            )}

            <TopMenu
                navTag={`Products`}
                subTag={`${product.productName}`}
                btnAction="Add Price"
                onBtnClick={handleAddPrice}
                portal="admin"
                hasSubMenus={false}
                btnStyle={{ width: "142px" }}
                searchData={() => {}}
            />

            <SpacedBetweenColumnBox>
                {tableData !== undefined ? (
                    <>
                        <div className="page-section">
                            <PriceTable
                                data={displayData as PriceDataType[]}
                                offset={offset}
                                nullMessage="No Price added for Product"
                                showPlusIcon={true}
                                additionMessage="Add Price"
                                tableHeader={tableHeader}
                                addTableActionBtn={true}
                                onActionClicked={(item: PriceDataType) => {
                                    const actionArray: PriceListMenuType[] =
                                        item.isActive === "Active"
                                            ? ["Deactivate"]
                                            : [];
                                    setSelectedPrice(item);
                                    setListActionMenus([
                                        "View details",
                                        ...actionArray,
                                    ]);
                                }}
                                actionMenus={
                                    listActionMenus as PriceListMenuType[]
                                }
                                selectedPrice={selectedPrice}
                                onMenuClick={(menu) => {
                                    handleMenuClick(menu);
                                }}
                                onAddClick={handleAddPrice}
                                productName={product.productName}
                                productUnit={product.unit}
                            />
                        </div>

                        {tableData !== undefined &&
                            tableData.length > itemsPerPage && (
                                <Pagination
                                    data={tableData as PriceDataType[]}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    itemsPerPage={itemsPerPage}
                                />
                            )}
                    </>
                ) : (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                )}
            </SpacedBetweenColumnBox>
            <ToastContainer limit={1} />
        </SectionContainer>
    );
};

export default Price;
