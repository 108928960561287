import * as yup from 'yup';

export const DiscountSchema = yup.object({
    productName: yup
        .string()
        .trim()
        .min(2, "Product name must be at least 2 characters")
        .max(100, "Product name too long")
        .required("This field is required"),
    institutionName: yup
        .string()
        .trim()
        .min(2, "Institution name must be at least 2 characters")
        .max(100, "Institution name too long")
        .required("This field is required"),
    institutionCode: yup
        .string()
        .trim()
        .min(4, "Intitution code must be 4 character long.")
        .max(4, "Intitution code must be 4 character long.")
        .required("This field is required"),
    discount: yup
        .number()
        .required("This field is required"),
    validFrom: yup
        .string()
        .required("This field is required"),
    validTo: yup
        .string()
        .required("This field is required")
});
