import * as yup from 'yup';

export const ProductSchema = yup.object({
    category: yup
        .string()
        .trim()
        .required("This field is required"),
    name: yup
        .string()
        .trim()
        .required("This field is required"),
    description: yup
        .string()
        .required("This field is required"),
    unitType: yup
        .string()
        .required("This field is required"),
    unit: yup
        .string()
        .required("This field is required"),
});
