import {createGlobalStyle} from "styled-components";


const GlobalStyles = createGlobalStyle`

    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    body {
        overflow-x: hidden;
    }

    .error {
        position: absolute;
        top: 80px;
        left:0;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */
        color: #ED1C24;
    }

    .label {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #4E4E4E;
    }

    .close {
        text-align: right;
        width: 100% !important;
        margin-bottom: 10px;
        cursor: pointer;
        // padding: 0 40px 0 55px;
    }

    
    //      MODAL STYLES        //

    .modal {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.2);
        display: none;
    }

    .modal.active-modal {
        display: block;
    }

    .modal-window {
        background-color: #FFFFFF;
        border-radius: 8px;
        margin: 150px auto;
    }

    .add-user .modal-window {
        width: 473px;
        height: 279px;
        padding: 24px;
    }

    .add-user .modal-window h1 {
        font-weight: 700;
        font-size: 22px;
        color: #373737;
    }

    .add-user .modal-window .close-btn {
        position: relative;
        top: -30.75px;
        float: right;
        font-size: 30.5px;
        width: 30.5px;
        height: 30.5px;
        background-color: #FFFFFF;
    }

    .add-user .modal-window .label {
        font-weight: 400;
        font-size: 16px;
        color: #4E4E4E;
        padding-top: 32px;
        padding-bottom: 8px;
    }

    .add-user .modal-window .phone-number {
        width: 425px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #2A6BB0;
        border-radius: 4px;
        margin-bottom: 39px;
    }

    .add-user .modal-window .phone-number .country-code-container {
        padding-right: 18.2px;
        border-right: 1px solid #E5E5E5;
        display: inline-flex;
        padding-left: 16px;
        height: 47px;
    }

    .textarea {
        padding-left: 16px;
        width: 320px;
        height: 47px;
    }

    .add-user .modal-window .phone-number #country-code, .textarea {
        font-size: 16px;
        font-weight: 500;
        color: #373737;        
    }

    .add-user .modal-window .add-user-btn {
        font-weight: 700;
        font-size: 16px;
    }

`

export default GlobalStyles;