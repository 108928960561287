import styled from "styled-components";

const ModalDiv = styled.div`
#modal {
    display: inline !important;
    position: relative;
    z-index: 400;
    overflow-y: scroll;
    overflow-y: hidden;
  }
  #modal .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease;
  }
  
  #modal .overlay.m-hidden {
    visibility: hidden;
    transform: scale(0.2);
  }
  
  #modal .content {
    background-color: white;
    max-height: 80vh;
    width: 470px;
    padding: 24px 24px 30px 24px;
    border-radius: 8px;
    position: relative;
    margin: 0 12px;
  }
  
  #close-button {
    position: relative;
    right: 0px;
    top: -10px;
    cursor: pointer;
    background: none;
  }

  
`

interface ModalProps {
    children: JSX.Element | string,
    show: boolean,
    onClose: () => void,
    style?: React.CSSProperties
}

const Modal = ({ children, show,  onClose, style }: ModalProps) => {
    return (
        <ModalDiv>
            <div id="modal">
                <div className={`overlay ${!show ? "m-hidden" : ""}`}>
                    <div
                        style={{ ...style, maxWidth: "600px", overflowY: "scroll" }}
                        className={`content`}
                    >
                        <div style={{ position: "sticky", top: "0px", textAlign: "right" }}>
                            <button
                                type="button"
                                id="close-button"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                <img src="/assets/institution/icons/close-icon.svg" alt="" />
                            </button>
                        </div>
                        <>{show && children}</>
                    </div>
                </div>
            </div>
        </ModalDiv>
    );
}

export default Modal;
