import { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import styled from "styled-components";
import LeftSideBarHome from '../../components/LeftSideBarHome';
import Users from '../ManageUsers';
import EmailInvite from '../EmailInvite/EmailInvite';
import TypeAndSpecialization from '../TypeAndSpecialization';
import BulkInvitesList from '../Invites/templates/BulkInvitesList';
import Institution from '../Institution';
import Discount from '../Discount';
import Products from '../Products';
import Price from '../Price';
import Account from '../Accounts/Accounts';
import Transaction from '../Transactions';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAdminSession } from '../../context/AdminSessionContext';

const Container = styled.div`
    display: flex;
    width: 100vw;

`


const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { sessionAdmin } = useAdminSession();

  const adminId = sessionAdmin.userId;

  useEffect(() => {
    if (!adminId) {
      navigate('/admin');
    }
  }, [adminId, navigate]);

  return (
    <Container>
      {
        adminId ?
          <>
              {location.pathname !== '/admin/home/invites/list' && <LeftSideBarHome />}
            <div style={{ flex: 5 }}>
              <Routes>
                <Route path='/invites/list' element={<BulkInvitesList />} />
                <Route path='/typeandspecialization' element={<TypeAndSpecialization />} />
                <Route path='/users' element={<Users />} />
                <Route path='/products' element={<Products />} />
                <Route path='/product/:productName/price' element={<Price />} />
                <Route path='/accounts' element={<Account />} />
                <Route path='/account/:accountId/transactions' element={<Transaction />} />
                <Route path="/invitation" element={<EmailInvite />} />
                <Route path="/institution" element={<Institution />} />
                <Route path="/institution/:institutionName/discount" element={<Discount />} />
              </Routes>
            </div>
          </> :
          <></>
      }
    </Container>
  )
}

export default Home
