import React, { useState } from 'react'
import {Formik, Form, FormikProps, FormikHelpers} from "formik";
import { FormBox, FormStyle, Heading } from '../../styles/generalStyles';
import AuthPagesFooter from '../../../institution/components/AuthPagesFooter';
import OTPInput from '../../components/OTPInput';
import { OTPHeader, OTPnote, OTPWrappper } from './ForgotPassword.style';

type OTPFormData = {
    otp: string;  
}


const OTPForm = () => {
    const [OTPFormData, setOTPFormData] = useState<OTPFormData>({
        otp: "",   
    });


   const handleSubmit = (values: OTPFormData, actions:FormikHelpers<OTPFormData> ) => {
        setOTPFormData(values);
   }

   const [loading, setLoading] = useState<boolean>(false);

  return (
    <Formik initialValues={OTPFormData}
            onSubmit = {handleSubmit}
            // validationSchema = {OTPFormSchema}
        >
        {
            (formik: FormikProps<OTPFormData>) => {
                const toggle = formik.values.otp.length === 4;

                return(
                    <OTPWrappper> 
                        <Form style={FormStyle}>
                            <div style={{padding: "0 40px 0 55px"}}>
                                <p className='close'>X</p>
                                <OTPHeader>
                                    <Heading style = {{margin: 0}}>Verify</Heading>
                                    <OTPnote>Enter the 4-digit code we just sent to oauthc@gmail.com</OTPnote>
                                </OTPHeader>
                                <FormBox>
                                    <OTPInput 
                                        name='otp'/>  
                                </FormBox>
                                
                            </div>
                            <AuthPagesFooter
                                note="Didn't recieve code? "
                                link='Resend'
                                to='/'
                                btn='Verify'
                                loading={loading}
                                shouldDisable = {!toggle}/>
                        </Form>
                    </OTPWrappper>
                
                )

            }  
        }
    </Formik>
  )
}

export default OTPForm;

