import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";




type SessionAdmin = {
    accessToken: string | null,
    userId: string | null, 
    adminName: string | null,
    adminImage: string | null
}


interface AdminSessionProviderProps {
    children: ReactNode;
}

interface AdminSessionContextType {
    sessionAdmin: SessionAdmin;
    setSessionAdmin: Dispatch<SetStateAction<SessionAdmin>>;
}


const AdminSessionContext = createContext({} as AdminSessionContextType);

export const useAdminSession = () => {
    return useContext(AdminSessionContext);
}

export const AdminSessionProvider = ({ children }: AdminSessionProviderProps) => {

    const [sessionAdmin, setSessionAdmin] = useLocalStorage<SessionAdmin>("sessionAdmin", {
        accessToken: null,
        userId: null, 
        adminName: null,
        adminImage: null
    });

 
    return(
        <AdminSessionContext.Provider value={{
            sessionAdmin,
            setSessionAdmin,
        }}>
            {children}
        </AdminSessionContext.Provider>
    )
}