import { useEffect, useState } from "react";



const useHandleFileSizeErrors = (initValues: File[]) => {

    const [localState, setLocalState] = useState<string[]>([]);



    useEffect(()=> {

        let newState:string[] = [];
        for (let i = 0; i < initValues.length; i++) {
            if (!initValues[i]?.size || initValues[i]?.size < 5000000) {
                newState[i] = ""
            }else {
                newState[i] = "file too large. Please select a file less than 5mb."
            }
            
        }

        setLocalState(newState);

    },initValues);


    return localState;






    
    



}


export default useHandleFileSizeErrors;