import styled from 'styled-components';

export const TopMenuContainer = styled.div`
    height: 114px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
    border-bottom: 1px solid #F1F2F6;
`

export const LocationBox = styled.div`
    display: flex;
    flex-direction: column;

    h1,p {
        font-family: 'Lato';
        font-style: normal;
        color: #2A6BB0;
    }

    h1 {
        font-weight: 700;
        font-size: 22px;
        line-height: 22px;
    }

    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }
`

export const SearchBox = styled.div`
    display: flex;
    gap: 20px;



`

export const LameBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px;
    width: 50px;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 4px;

`

export const ExportBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 13px 20px;
    gap: 4px;
    width: 132px;
    height: 50px;
    background-color: #2A6BB0;
    border-radius: 8px;

    p{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;

        color: #FFFFFF;
    }

`

export const InviteBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 13px 20px;
    gap: 4px;
    width: 132px;
    height: 50px;
    background-color: #2A6BB0;
    border-radius: 8px;

    p{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;

        color: #FFFFFF;
    }

`

export const UserTableContainer = styled.div`
    width: 99%;
    overflow-x: auto;
    overflow-y: auto;

    @media (max-width: 1024px){
        margin-top: 0;
    }

    .table-row:not(first-child){
        border-top: 1px solid #E0E7ED;
    }

    .table-row{
        height: 64px;
        padding: 8px 6px;
        gap: 15px;
        p{
            font-weight: 500;
        }
    }

    .table-head{
        height: 56px;
        background-color: #DDE2E7;
        padding: 8px 6px;
        gap: 15px;
        p{
            font-weight: 700 !important;
        }
    }

    .row{
        p {
            font-family: 'Lato';
            font-style: normal;
            font-size: 14px;
            line-height: 14px;
            color: #373737;
            font-weight: 500;
        }
    }

    .active, .inactive, .pending{
        padding: 8px 12px;
        border-radius: 8px;
        font-weight: 700;
    }

    .active {
        background: rgba(159, 247, 157, 0.41);
        color: #347D32;
    }

    .pending {
        background: rgba(237, 204, 28, 0.2);
        color: #7B6A10;
    }

    .inactive{
        background: rgba(237, 28, 36, 0.1);
        color: #ED1C24;
    }
`

export const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 510px;

    p{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #4E4E4E;
    }

    p.btn {
        color: #2A6BB0;
        cursor: pointer;
    }

    div {
        display: flex;
        flex-direction: column;
        gap: 18px;
    }

`

export const ActionMenusBox = styled.div`
    #menu {
        position: relative;

        .tableAction, .topAction {
            color: #4E4E4E;
            position: absolute;
            background: #FFFFFF;
            box-shadow: 0 1px 7px rgb(120 120 120 / 25%);
            border-radius: 8px;
            padding: 8px;
            font-size: 14px;
        }

       .tableAction {
        left: -16px;
        top: 41px;
        width: 118px;
        z-index: 999;
        }

        .topAction {
            left: -127px;
            top: 14px;
            width: 110px;
        }
    }

    .topAction li{
        padding: 4px;
    }
    #menu .popup.p-hide{
        visibility: hidden;
    }
    ul {
        li{
            list-style: none;
            cursor: pointer;
            padding: 2px;
        }
    }
    li:hover{
        background-color: #2a6bb0;
        color: white;
    }

    .row-action{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        p{
            padding: 6px 0 6px 12px;
        }
        img{
            padding: 6px 12px 6px 0;
        }
    }

    #topAction{
        gap: 4px;
    }

    #tableAction{
        gap: 8px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16);
        border-radius: 6px;
        width: 80%;
    }
`

export const Head = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 28px;
    align-items: center;

    @media (max-width: 1024px) {
        .option{
            margin-bottom: 4px;
        }
    }
`
