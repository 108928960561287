import { useState } from 'react'
import {Formik, Form, FormikProps, FormikHelpers} from "formik";
import { FormBox, FormStyle, Heading } from '../../styles/generalStyles';
import Input from '../../../components/Input';
import AuthPagesFooter from '../../components/AuthPagesFooter';
import toggleButton from '../../utils/toggleButton';
import { ForgotPasswordSchema } from './ForgotPassword.validators';
import { ForgotPasswordWrapper } from './ForgotPassword.style';
import { useNavigate } from 'react-router-dom';
import { ToastTypes } from '../../types/DataTypes';
import { toast, ToastContainer } from 'react-toastify';
import connectivityService from '../../../service/ConnectivityService';
import DarkModal from '../../../components/DarkModal';
import EmailSuccessModal from '../../../components/EmailSuccessModal';



type ForgotPasswordData = {
    email: string;  
}


const ForgotPassword = () => {
    const [forgotPasswordData, setForgotPasswordData] = useState<ForgotPasswordData>({
        email: "",   
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);

    const navigate = useNavigate();


   const handleSubmit = async(values: ForgotPasswordData, actions:FormikHelpers<ForgotPasswordData> ) => {
        setForgotPasswordData(values);
        setLoading(true);    

        const notify = (type:ToastTypes , msg: string) => (
            toast(msg, {type: type, position: "top-center", theme: "colored"})
        )
          
        const url = process.env.REACT_APP_ADMIN_REQUEST_PASSWORD_RESET_URL;

        const data = {
            email: values.email,
            role: "institution-admin"
        }
    
        const response = await connectivityService()
              .makeApiCallWithoutToken("post", url, data);

        if (response.code === 200 || response.code === 201) {
            setShowModal(true);
        }
        else {
            connectivityService().handleErrors(response, notify);
            
        }

        setLoading(false);
        // navigate('/institution/reset-password');
   }

  return (
    <Formik initialValues={forgotPasswordData}
            onSubmit = {handleSubmit}
            validationSchema = {ForgotPasswordSchema}
        >
        {
            (formik: FormikProps<ForgotPasswordData>) => {
                const toggle = toggleButton<ForgotPasswordData>(formik.values) &&
                Object.keys(formik.errors).length === 0;
                return(
                    <ForgotPasswordWrapper> 
                        <Form style={FormStyle}>
                            <div style={{padding: "0 40px 0 55px"}}>
                            <p className='close' onClick={() => navigate('/institution/login')}>X</p>
                                <Heading>Forgot Password?</Heading>
                                <FormBox>
                                    <Input 
                                        title= "Email address"
                                        name = "email"
                                        type='text'
                                        placeholder='Enter email'
                                        required
                                        />   
                                </FormBox>
                            </div>
                            <AuthPagesFooter
                                note='Are you new? '
                                link='Create a free account'
                                to='/'
                                btn='Continue'
                                loading={loading}
                                shouldDisable = {!toggle}/>
                        </Form>
                        {
                            showModal &&
                            <DarkModal>
                                <EmailSuccessModal 
                                    email={forgotPasswordData.email}
                                    handleProceed={()=>{setShowModal(false)}}
                                    cta="Close"/>

                            </DarkModal>
                        }
                        <ToastContainer limit={1}/>
                    </ForgotPasswordWrapper>
                
                )

            }  
        }
    </Formik>
  )
}

export default ForgotPassword;

