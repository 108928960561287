import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ToastTypes } from "../../../../components/DataTypes";
import connectivityService from "../../../../service/ConnectivityService";
import { useAdminSession } from "../../../context/AdminSessionContext";
import { PatientDataType } from "../../../types/DataTypes";
import { useNavigate } from "react-router-dom";

const Modal = styled.div`
    .content-inner{
        padding: 16px;
        font-family: 'Courier New';
        background-color: #ECF5FF;
        margin-top: 15px;
        margin-bottom: 30px;

        .value{
            line-height: 16px;
            color: #373737;
        }
    }

    .head-1, .head-2, .head-3{
        font-weight: 700;
    }

    .head-1{
        font-size: 22px;
    }

    .head-2{
        font-size: 14px;
        margin-top: 30px;
    }

    .row{
        display: flex;
        flex-direction : row;
        justify-content: space-between;
    }

    .row1, .row2, .row3, .row4, .row5{
        margin-bottom: 20px;
    }
    
    .row p:(first-child){
        margin-bottom: 8px;
    }

    a {
        text-decoration: underline;
        color: #0066cc;
    }
`

type NOKType = {
    nokFirstName: string,
    nokLastName: string,
    nokRelationship: string,
    nokPhone: string,
    nokEmail: string
}

interface ViewPatientProps {
    patientRep?: PatientDataType
}

const ViewPatient = ({ patientRep }: ViewPatientProps) => {
    const [patient, setPatient] = useState<PatientDataType>();
    const [allergies, setAllergies] = useState<string>();
    const [patientNOK, setPatientNOK] = useState<NOKType>();

    const { sessionAdmin } = useAdminSession();
    const navigate = useNavigate();

    const notify = (type: ToastTypes, msg: string) => (
        toast(msg, { type: type, position: "top-center", theme: "colored" })
    );

    useEffect(() => {
        const fetchData = async () => {
            const url = `${process.env.REACT_APP_USER_ROOT_URL}/${patientRep?.userId}`;
            const nok_url = `${process.env.REACT_APP_USER_ROOT_URL}/${patientRep?.userId}/nok`;
            const allergies_url = `${process.env.REACT_APP_USER_ROOT_URL}/${patientRep?.userId}/allergy`;

            const res = await connectivityService().makeApiCall("get", url, undefined, sessionAdmin.accessToken);

            if (res.code === 200 || res.code === 201) {
                setPatient(res.data);
                const resAllergy = await connectivityService().makeApiCall("get", allergies_url, undefined, sessionAdmin.accessToken);

                if (resAllergy.code === 200 || resAllergy.code === 201) {
                    const allergies: Array<String> = [];
                    resAllergy.data.map((val: any, ind: any) => {
                        return allergies.push(val.allergy);
                    })
                    setAllergies(allergies.join(", "));
                    const resNOK = await connectivityService().makeApiCall("get", nok_url, undefined, sessionAdmin.accessToken);
                    if (resNOK.code === 200 || resNOK.code === 201) {
                        setPatientNOK(resNOK.data[0]);
                    }
                    else {
                        const error = await connectivityService().handleErrors(resNOK, notify)
                        if (error === "AccessDenied") {
                            notify("error", "Login session expired. Please re-login.")
                            navigate("/admin")
                        }
                    }
                }
                else {
                    const error = await connectivityService().handleErrors(resAllergy, notify)
                    if (error === "AccessDenied") {
                        notify("error", "Login session expired. Please re-login.")
                        navigate("/admin")
                    }
                }
            }
            else {
                const error = await connectivityService().handleErrors(res, notify)
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.")
                    navigate("/admin")
                }
            }
        }
        fetchData();
    }, []);

    return (
        <>
            <Modal>
                <p className="head-1">View</p>
                <p className="head-2">Patient's Details</p>
                <div className="content-inner">
                    <div className="row row1">
                        <div>
                            <p className="head-3">Name</p>
                            <p className="value">{patient?.firstName ?? "loading..."} {patient?.lastName}</p>
                        </div>
                        <div>
                            <p className="head-3">Gender</p>
                            <p className="value">{patient?.gender === "M" ? "Male" : "Female" ?? "loading..."}</p>
                        </div>
                    </div>
                    <div className="row row2">
                        <div>
                            <p className="head-3">Phone Number</p>
                            <p className="value">{patient?.phoneNumber ?? "loading..."}</p>
                        </div>
                        <div>

                            <p className="head-3">Email Address</p>
                            <p className="value">{patient?.email ?? "loading..."}</p>
                        </div>
                    </div>
                    <div className="row row2">
                        <div>
                            <p className="head-3">Date of Birth</p>
                            <p className="value">{patient?.dateOfBirth ?? "loading..."}</p>
                        </div>
                        <div>
                            <p className="head-3">Allergies</p>
                            <p className="value">{allergies ?? "-"}</p>
                        </div>
                    </div>
                    <div className="row row3">
                        <div >
                            <p className="head-3">Profile Picture</p>
                            <a target={"_blank"} href={patient?.userImgUri}>{patient?.userImgUri ? "picture.jpg" : ""}</a>
                        </div>
                    </div>
                </div>
                <p className="head-2">Next of kin details</p>
                <div className="content-inner">
                    <div className="row row1">
                        <div>
                            <p className="head-3">Name</p>
                            <p className="value">{patientNOK?.nokFirstName ?? "-"} {patientNOK?.nokLastName}</p>
                        </div>
                        <div>
                            <p className="head-3">Relationship</p>
                            <p className="value">{patientNOK?.nokRelationship ?? "-"}</p>
                        </div>
                    </div>
                    <div className="row row2">
                        <div>
                            <p className="head-3">Phone Number</p>
                            <p className="value">{patientNOK?.nokPhone ?? "-"}</p>
                        </div>
                        <div>
                            <p className="head-3">Email Address</p>
                            <p className="value">{patientNOK?.nokEmail ?? "-"}</p>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )

}

export default ViewPatient