import React, { useState, useRef } from "react";
import styled from 'styled-components';
import FileInput from '../../../../components/FileInput';
import ClickUpload from '../../../../components/ClickUpload';
import { useCreateAccount } from '../context/PatientCreateAccountContext';
import Button from '../../../../../components/Button';
import Webcam from "react-webcam";
import connectivityService from "../../../../../service/ConnectivityService";
import { ToastTypes } from "../../../../types/DataTypes";
import { toast, ToastContainer } from "react-toastify";

const Modal = styled.div`
    .heading{
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 16px;
        color: #000000;
    }
    p{
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #373737;
    }
    .foot-note{
        color: #2A6BB0;
        margin-top: 32px;
        text-align: center;
        cursor: pointer;
    }
    .foot-buttons{
        display: flex;
        flex-direction: row;
        gap: 24px;
        justify-content: center;
            margin-top: 48px;
        #button{
            padding: 16px 36px;
            width: 216px;
        }
    }
    .video{
        border-radius: 50%;
    }
`

const Flex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .position{
        width: 41px;
        height: fit-content;
        position: relative;
        img{
            position: absolute;
            top: 0;
            left: 0;
        }
    }
`

const ImageStyle = styled.div`
    .reload{
        position: relative;
        top: 150px;
    }
    .image {
        border-radius: 50%;
        height: 321px;
        width: 321px;
        margin-top: -19px;
        object-fit: cover;
        object-position: center;
    }
`

const PictureUpload = () => {
    const { subPages } = useCreateAccount();
    const [imageFile, setImageFile] = useState<File[]>([]);
    const [curSubPage, setCurSubPage] = subPages;
    const [loading, setLoading] = useState<boolean>(false);

    const webcamRef = useRef<Webcam>(null);
    const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
    const [capture, setCapture] = useState<boolean>(false);
    const [cameraState, setCameraState] = useState<boolean>(false);
    const { inviteSessionUser } = useCreateAccount();



    if (inviteSessionUser.id === null) {
        setCurSubPage(0);
    }

    const notify = (type:ToastTypes , msg: string) => (
        toast(msg, {type: type, position: "top-center", theme: "colored"})
    )


    const videoConstraints = {
        width: 318,
        height: 318,
        facingMode: "user"
    };

    const toggle = imageFile.length > 0;

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        let formData = new FormData();

        formData.append("image", imageFile[0]);

        const url = `${process.env.REACT_APP_USER_ROOT_URL}/${inviteSessionUser.id}/profileImage`;

        const response = await connectivityService().makeApiCallWithFormData("post", url, formData, true);

        const data = connectivityService().handleErrorsReturnData(response, notify);

        if (data) {
            next(); 
        }
        setLoading(false);
    }


    const next = () => {

        setCurSubPage(4);
        
        
    }

    const takePhoto = () => {
        setCapture(true);
        setCameraState(true);
    };

    const upload = () => {
        setCameraState(false);
        setCapture(false);
        setImageSrc(undefined);
        setImageFile([]);
    };

    const capturePhoto = React.useCallback(async () => {
        if (webcamRef.current) {
            const src = webcamRef.current.getScreenshot();
            const file = await convertToFile(src as string);
            const fileArray = [file];
            setImageSrc(src as string);
            setImageFile(fileArray);
            setCapture(false);
        }
    }, [webcamRef]);

    const convertToFile = async (url: string) => {
        const res = await fetch(url);
        const buf = await res.arrayBuffer();
        return new File([buf], "picture.png", { type: 'text/plain' });
    }

    return (
        <Modal>
            <form onSubmit={handleSubmit} >
                <p className='heading'>Upload a picture</p>
                <p>Please upload your picture</p>
                <FileInput
                    title=''
                    align='center'
                    children={
                        imageFile.length > 0 && !capture ?
                            <ImageStyle>
                                <Flex>
                                    {!cameraState && <div className='position reload'>
                                        <ClickUpload
                                            label='reload'
                                            fileType='img'
                                            prevState={imageFile}
                                            stateFunc={setImageFile}
                                            multiple={false}
                                            textStyle={{ opacity: 0 }}
                                        />
                                        <img src='/assets/institution/images/reload.png' alt='upload' />
                                    </div>}
                                    {cameraState && <div className='reload'>
                                        <img onClick={takePhoto} src='/assets/institution/images/reload.png' alt='upload' />
                                    </div>}
                                </Flex>
                                <img className='image' src={URL.createObjectURL(imageFile[0] as Blob)} alt="uploaded profile" />
                            </ImageStyle>
                            :
                            capture === true ?
                                <>
                                    <Webcam
                                        ref={webcamRef}
                                        audio={false}
                                        screenshotFormat="image/png"
                                        videoConstraints={videoConstraints}
                                        mirrored={true}
                                        className='video'
                                    />
                                </>
                                :
                                <Flex>
                                    <div className='position'>
                                        <ClickUpload
                                            label=' '
                                            fileType='img'
                                            prevState={imageFile}
                                            stateFunc={setImageFile}
                                            multiple={false}
                                        />
                                        <img src='/assets/institution/images/camera.png' alt='upload' />
                                    </div>
                                    <ClickUpload
                                        label='Click to upload picture'
                                        textStyle={{
                                            color: '#2A6BB0',
                                            width: '119px',
                                            fontSize: '12px',
                                            borderBottom: '1px solid #2A6BB0',
                                            cursor: 'pointer',
                                            marginTop: '32px'
                                        }}
                                        fileType='img'
                                        prevState={imageFile}
                                        stateFunc={setImageFile}
                                        multiple={false}
                                    />
                                </Flex>
                    }
                    required
                    style={{ padding: '27px 0', borderRadius: '50%', width: '318px', height: '318px' }}
                    files={imageFile}
                    placeholder=" "
                />
                {!cameraState && !capture && !imageSrc && <p className='foot-note' onClick={takePhoto}>Take a picture</p>}
                {cameraState && !capture && imageSrc && <p className='foot-note' onClick={takePhoto}>Take another picture</p>}
                {capture && <p className='foot-note' onClick={capturePhoto}>Capture Photo</p>}
                {cameraState && <p className='foot-note' onClick={upload}>Upload a picture</p>}
                <div className='foot-buttons'>
                    <Button type='submit' disabled={!toggle} loading={loading} children='Next' />
                    <Button type='submit' onClick={next} children='Skip' />
                </div>
            </form>
            <ToastContainer limit={1}/>
        </Modal>
    )
}

export default PictureUpload;