import React, { useEffect, useState } from 'react';
import { InviteBtn, LameBtn, LocationBox, SearchBox, TopMenuContainer } from '../institution/styles/page-styles';
import SearchBar from '../institution/components/SearchBar';
import { ExportBtn } from '../admin/styles/page-styles';
import { MenuType as AdminMenuType } from '../admin/types/DataTypes';
import { MenuType as InstitutionMenuType } from '../institution/types/DataTypes';
import ActionMenus from './ActionMenus'
import { useNavigate } from 'react-router-dom';
import InstitutionMobileMenu from '../institution/components/MobileMenu';
import AdminMobileMenu from '../admin/components/MobileMenu';

type AppMenuType = AdminMenuType | InstitutionMenuType;

interface TopMenuProps {
    navTag: string,
    subTag?: string,
    btnAction?: string,
    topActionMenus?: AppMenuType[],
    onMenuClick?: (menu: AppMenuType) => void,
    onBtnClick?: () => void
    hasSubMenus?: boolean
    hasNoButton?: boolean
    hasSearch?: boolean
    btnStyle?: React.CSSProperties;
    addExportButton?: boolean;
    portal: string;
    searchBy?: string;
    searchData: (name: string) => void;
}

const TopMenu = ({ navTag, subTag, btnAction, topActionMenus, onMenuClick, hasSubMenus, hasSearch,
    btnStyle, onBtnClick, hasNoButton, addExportButton, portal, searchBy, searchData }: TopMenuProps) => {
    const [searchInput, setSearchInput] = useState<string>('');
    const [toggle, setToggle] = useState<boolean>(false);

    const navigate = useNavigate();

    const navigateToHome = () => {
        navigate(`/${portal}/home`)
    }

    useEffect(() => {
        searchData(searchInput);
    }, [searchInput])

    const toggleMenu = () => {
        setToggle(!toggle);
    }

    return (
        <>
            <TopMenuContainer>
                <LocationBox>
                    <div className='menu-btn' onClick={toggleMenu}>
                        <img src="/assets/icons/menu-icon.svg" alt="" />
                    </div>
                    <div>
                        <h1 style={{ color: '#373737' }}>{navTag}</h1>
                        {!subTag ? <p><span onClick={navigateToHome}>{`Home/`}</span><span>{`${navTag}`}</span></p> :
                            <p><span onClick={navigateToHome}>{`Home/`}</span><span onClick={() => navigate(-1)}>{`${navTag}/`}</span>
                                <span className='allow-pointer'>{`${subTag}`}</span></p>}
                    </div>
                </LocationBox>
                <SearchBox>
                    {hasSearch &&
                        <>
                            <SearchBar
                                handleChange={(e) => { setSearchInput(e.target.value) }}
                                inputValue={searchInput}
                                placeHolder={`Search for ${searchBy || 'name'}`}
                                handleSubmit={(e) => { e.preventDefault() }}
                            />
                            <LameBtn>
                                <img src="/assets/institution/icons/line-arrow.svg" alt="" />
                            </LameBtn>
                        </>
                    }
                    {addExportButton ?
                        <div onClick={onBtnClick} className="button">
                            <ExportBtn>
                                <p>Export</p>
                                <img src="/assets/admin/icons/export-icon.svg" alt="" />
                            </ExportBtn>
                        </div> :
                        hasNoButton ? "" :
                            hasSubMenus ?
                                <InviteBtn style={btnStyle}>
                                    <ActionMenus
                                        activator={
                                            <>
                                                <p>{btnAction}</p>
                                                <img src="/assets/institution/icons/arrow-head-down.svg" alt="" />
                                            </>}
                                        menus={topActionMenus as AppMenuType[]}
                                        onMenuClick={onMenuClick as (menu: AppMenuType) => void}
                                        type="topAction"
                                    /></InviteBtn> :
                                <InviteBtn style={btnStyle}>
                                    <div style={{ display: "flex", gap: "10px" }} onClick={onBtnClick}>
                                        <p>{btnAction}</p>
                                        <img src="/assets/icons/add.svg" alt="" />
                                    </div>
                                </InviteBtn>
                    }
                </SearchBox>
            </TopMenuContainer>
            {toggle && portal === "institution" ?
                <InstitutionMobileMenu /> :
                toggle && portal === "admin" ? <AdminMobileMenu /> :
                    ""}
        </>
    )
}


export default TopMenu
