import { CreateAccountProvider, useCreateAccount } from './context/PatientCreateAccountContext';
import { Container } from '../CreateAccount.style';
import { InviteDataType } from './templates/GeneralInfo';
import AllergiesInfo from './templates/AllergiesInfo';
import NextOfKinInfo from './templates/NextOfKinInfo';
import OtpInput from './templates/OtpInput';
import SuccessCard from './templates/SuccessCard';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import GeneralInfo from './templates/GeneralInfo';

const Close = styled.div`    
    float: right;
    margin-right: 42px;

`
const Body = styled.div`
`

const Home = () => {
    const { subPages } = useCreateAccount();
    const [curSubPage, setCurSubPage] = subPages;

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Container>
            <Body>
                {curSubPage !== 4 &&
                    <Close>
                        <p className='close' onClick={() => navigate('/institution')}>X</p>
                    </Close>
                }
                {
                    curSubPage === 0 ?
                        <GeneralInfo inviteData={location?.state as InviteDataType} /> :
                        curSubPage === 1 ?
                            <OtpInput /> :
                            curSubPage === 2 ?
                                <AllergiesInfo /> :
                                curSubPage === 3 ?
                                    <NextOfKinInfo /> : <SuccessCard />
                }
            </Body>
        </Container>
    )
}

const CreateAccount = () => {

    return (
        <CreateAccountProvider>
            <Home />
        </CreateAccountProvider>
    )
}

export default CreateAccount
