import styled from "styled-components";

export const FormStyle: React.CSSProperties = {
    height: "100%",
    width: "100%"
}

export const H = styled.h1`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #373737;
`

export const TwoColumnInputFlex = styled.div`
.flex{
    display: flex;
    gap: 24px;
    justify-content: center;
}

@media (max-width: 920px) {
    .flex{
        display: block;
        text-align: center;
    }
  }
`

export const P = styled.p`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #373737;
`

export const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Heading = styled(H)`
    margin-bottom: 32px;
`

export const FormBox = styled.div`
    display : flex;
    gap: 24px;
    flex-direction: column;
        .form-textarea {
            height: 100px;
            width: 100%;
        }
`

export const LoginBackButton = styled.div`
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #2A6BB0;
    margin-bottom: 20px;
    border: 1px solid #2A6BB0;
    padding: 10px;
    background-color: white;
    transition: all 0.3s ease;
    position: absolute;
    top: 20px;
    right: 40px;
    z-index: 100;
    border-radius: 10px;

    &:hover {
        background-color: #2A6BB0;
        color: white;
    }
`

export const SideBarContainer = styled.div`
    background-color: #2a6bb0;
    flex-basis: 250px;
    width: 250px;
    height: auto;

    @media (max-width: 1024px) {
        display: none;
    }
`;

export const TopBox = styled.div`
    width: 100%;
    height: 114px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const Wrapper = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;

    img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }

    div {
        display: flex;
        flex-direction: column;
    }

    p{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        color: #fff;
    }

    .user-name {
        font-size: 18px;
        color: #fff;
    }

`

export const MenuBox = styled.div`
    height: calc(100vh - 130px);
    width: 100%;
    background-color: #2A6BB0;
    padding:30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
export const NavContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const BtnBox = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    width: 210px;
    height: 44px;
    cursor: pointer;
    border-radius: 8px;

    div {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #2A6BB0;
    }
`
