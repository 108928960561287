import React, { useEffect, useState } from "react";
import TopMenu from "../../../components/TopMenu";
import UserTable from "./templates/UserTable";
import StatusWrapper from "../../../components/StatusWrapper";
import DateWrapper from "../../../components/DateWrapper";
import RoleWrapper from "../../../components/RoleWrapper";
import Modal from "../../../components/Modal";
import AddUser from "./templates/AddUser";
import Pagination from "../../../components/Pagination";
import {
    InstUserDataType,
    UserTopMenuType,
    MenuType,
    InstDataType,
} from "../../types/DataTypes";
import { AppMenuType } from "../../../components/DataTypes";
import { AppDataType } from "../../../components/DataTypes";
import connectivityService from "../../../service/ConnectivityService";
import { ToastContainer, toast } from "react-toastify";
import { ToastTypes } from "../../../components/DataTypes";
import ActivateUser from "./templates/ActivateUser";
import DeactivateUser from "./templates/DeactivateUser";
import ViewUser from "./templates/ViewUser";
import ResetPassword from "./templates/ResetPassword";
import { useInstitutionSession } from "../../context/InstitutionSessionContext";
import { useNavigate } from "react-router-dom";
import { sortDataByDate } from "../../../utils";
import {
    Loader,
    LoaderWrapper,
    SectionContainer,
    SpacedBetweenColumnBox,
} from "../../../styles";

const tableHeader = [
    { value: "id", text: "S/N" },
    { value: "firstName", text: "First Name" },
    { value: "lastName", text: "Last Name" },
    { value: "email", text: "Email Address" },
    { value: "phoneNumber", text: "Phone Number" },
    { value: "status", text: "Status", wrapper: StatusWrapper },
    { value: "userRole", text: "Role", wrapper: RoleWrapper },
];

const tableHeaderIDP = [
    { value: "id", text: "S/N" },
    { value: "firstName", text: "First Name" },
    { value: "lastName", text: "Last Name" },
    { value: "gender", text: "Gender" },
    { value: "dob", text: "DOB", wrapper: DateWrapper },
    { value: "createdBy", text: "Created By" },
    { value: "createdAt", text: "Created On", wrapper: DateWrapper },
    { value: "status", text: "Status", wrapper: StatusWrapper },
];

const UsersList = () => {
    const topActionMenus: UserTopMenuType[] = ["Single User", "Bulk User"];
    const itemsPerPage = 8;
    const [selectedUser, setSelectedUser] = useState<AppDataType>();
    const [listActionMenus, setListActionMenus] = useState<MenuType[]>([]);
    const [modalType, setModalType] = useState<string>("");
    const [showModal, setModalShow] = useState<boolean>(false);
    const [tableData, setTableData] = useState<InstDataType[]>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageRefresh, setPageRefresh] = useState<boolean>(false);
    const [initialData, setInitialData] = useState<InstDataType[]>();
    const [noDataMessage, setNoDataMessage] = useState<string>();
    const { sessionInstitution } = useInstitutionSession();

    const navigate = useNavigate();

    const isInstitutionIDP =
        sessionInstitution.institutionType?.toLowerCase() === "idp";
    const code = sessionInstitution.shortCode;

    const toggleModalDisplay = () => {
        setModalShow(!showModal);
    };

    const handleTopMenuClick = (menu: AppMenuType) => {
        toggleModalDisplay();
        setModalType(menu.split(" ")[0]);
    };

    const handleMenuClick = (menu: MenuType) => {
        setModalShow(true);
        menu === "View"
            ? setModalType("View")
            : menu === "Reset Password"
            ? setModalType("PasswordReset")
            : menu === "Activate"
            ? setModalType("Activate")
            : setModalType("Deactivate");
    };

    const activateUser = () => {
        updateStatus("Activate");
    };

    const deactivateUser = () => {
        updateStatus("Deactivate");
    };

    const updateStatus = async (action: MenuType) => {
        setLoading(true);

        const url = isInstitutionIDP
            ? `${process.env.REACT_APP_INST_URL}/${code}/idpUser`
            : `${process.env.REACT_APP_INST_URL}/${code}/user`;

        const data = {
            userId: selectedUser?.userId,
            action: action === "Activate" ? "activate" : "deactivate",
        };
        const res = await connectivityService().makeApiCall(
            "put",
            url,
            data,
            sessionInstitution.accessToken
        );

        setLoading(false);

        const actionName = action === "Activate" ? "activated" : "deactivated";

        if (res.code === 200 || res.code === 201) {
            notify("success", `User has been ${actionName} successfully`);
            setModalShow(false);
            (selectedUser as InstUserDataType).status =
                action === "Activate" ? "Active" : "Inactive";
        } else {
            const error = await connectivityService().handleErrors(res, notify);
            if (error === "AccessDenied") {
                notify("error", "Login session expired. Please re-login.");
                navigate("/admin");
            }
        }
    };

    const notify = (type: ToastTypes, msg: string) =>
        toast(msg, { type: type, position: "top-center", theme: "colored" });

    useEffect(() => {
        const url = isInstitutionIDP ? `${code}/idpUser` : `findUsers/${code}`;
        const fetchData = async () => {
            const res = await connectivityService().makeApiCall(
                "get",
                `${process.env.REACT_APP_INST_URL}/${url}`,
                undefined,
                sessionInstitution.accessToken
            );

            if (res.code === 200 || res.code === 201) {
                const newData = res.data
                    .reverse()
                    .map((value: any, key: number) => {
                        return isInstitutionIDP
                            ? {
                                  userId: value.one2one_user.userId,
                                  firstName: value.one2one_user.firstName,
                                  lastName: value.one2one_user.lastName,
                                  status: value.one2one_user.status,
                                  dob: value.one2one_user.dateOfBirth,
                                  gender:
                                      value.one2one_user.gender === "F"
                                          ? "Female"
                                          : "Male",
                                  createdAt: value.one2one_user.createdAt,
                                  createdBy: value.createdBy,
                              }
                            : {
                                  userId: value.one2one_user.userId,
                                  firstName: value.one2one_user.firstName,
                                  lastName: value.one2one_user.lastName,
                                  status: value.one2one_user.status,
                                  email: value.one2one_user.email,
                                  phoneNumber: value.one2one_user.phoneNumber,
                                  createdAt: value.one2one_user.createdAt,
                                  userRole: value.userRole,
                              };
                    });

                const sortedData = sortDataByDate(newData, "createdAt");
                setTableData(sortedData);
                setInitialData(sortedData);
            } else {
                const error = await connectivityService().handleErrors(
                    res,
                    notify
                );
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.");
                    navigate("/institution/login");
                }
            }
        };
        fetchData();
    }, [pageRefresh]);

    const searchData = (data: string) => {
        if (data === "") {
            setTableData(initialData);
            setNoDataMessage("No user here");
        } else {
            setNoDataMessage("User not found");
            const result = (initialData as InstUserDataType[])?.filter(
                (val) => {
                    return (
                        val.firstName
                            .toLowerCase()
                            .includes(data.toLowerCase()) ||
                        val.lastName.toLowerCase().includes(data.toLowerCase())
                    );
                }
            );
            setTableData(result);
        }
    };

    const offset = itemsPerPage * (currentPage - 1);
    let displayData = tableData
        ? tableData.slice(offset, offset + itemsPerPage)
        : tableData;

    return (
        <SectionContainer>
            {showModal && (
                <Modal
                    children={
                        modalType === "View" ? (
                            <ViewUser
                                userRep={selectedUser as InstUserDataType}
                                isIdp={isInstitutionIDP}
                            />
                        ) : modalType === "PasswordReset" ? (
                            <ResetPassword
                                notifyResetSuccess={() =>
                                    notify(
                                        "success",
                                        "Password reset was successful"
                                    )
                                }
                                formClose={() => setModalShow(false)}
                                selectedUserId={selectedUser?.userId}
                            />
                        ) : modalType === "Activate" ? (
                            <ActivateUser
                                activate={activateUser}
                                loading={loading}
                                formClose={() => setModalShow(false)}
                            />
                        ) : modalType === "Deactivate" ? (
                            <DeactivateUser
                                deactivate={deactivateUser}
                                loading={loading}
                                formClose={() => setModalShow(false)}
                            />
                        ) : (
                            <AddUser
                                type={modalType}
                                isIDP={isInstitutionIDP}
                                pageRefresh={() => setPageRefresh(!pageRefresh)}
                                formClose={() => setModalShow(false)}
                            />
                        )
                    }
                    show={showModal}
                    onClose={() => setModalShow(false)}
                    style={{ width: "500px" }}
                />
            )}

            {isInstitutionIDP ? (
                <TopMenu
                    navTag="Users"
                    btnAction="Add user"
                    topActionMenus={topActionMenus}
                    hasSearch
                    hasSubMenus={true}
                    onMenuClick={handleTopMenuClick}
                    portal="institution"
                    searchData={searchData}
                />
            ) : (
                <TopMenu
                    navTag="Users"
                    hasSearch
                    hasNoButton
                    portal="institution"
                    searchData={searchData}
                />
            )}

            <SpacedBetweenColumnBox>
                {tableData !== undefined ? (
                    <>
                        <div className="page-section">
                            <UserTable
                                data={displayData as InstUserDataType[]}
                                offset={offset}
                                nullMessage={noDataMessage || "No user here"}
                                showPlusIcon={isInstitutionIDP ? true : false}
                                additionMessage={
                                    isInstitutionIDP ? "Add user" : ""
                                }
                                tableHeader={
                                    isInstitutionIDP
                                        ? tableHeaderIDP
                                        : tableHeader
                                }
                                addTableActionBtn={true}
                                onActionClicked={(item: InstUserDataType) => {
                                    const actionText =
                                        item.status === "Active"
                                            ? "Deactivate"
                                            : "Activate";
                                    setSelectedUser(item);
                                    setListActionMenus(
                                        isInstitutionIDP &&
                                            actionText !== "Activate"
                                            ? [
                                                  "View",
                                                  actionText,
                                                  "Reset Password",
                                              ]
                                            : ["View", actionText]
                                    );
                                }}
                                actionMenus={listActionMenus}
                                topActionMenus={topActionMenus}
                                onTopMenuClick={handleTopMenuClick}
                                selectedUser={selectedUser}
                                onMenuClick={(menu) => {
                                    handleMenuClick(menu);
                                }}
                            />
                        </div>
                        {tableData !== undefined &&
                            tableData.length > itemsPerPage && (
                                <Pagination
                                    data={tableData as InstDataType[]}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    itemsPerPage={itemsPerPage}
                                />
                            )}
                    </>
                ) : (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                )}
            </SpacedBetweenColumnBox>

            <ToastContainer limit={1} />
        </SectionContainer>
    );
};

export default UsersList;
