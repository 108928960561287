import { useEffect, useState } from "react";
import TopMenu from "../../../components/TopMenu";
import AccountTable from "./templates/AccountTable";
import StatusWrapper from "../../../components/StatusWrapper";
import Modal from "../../../components/Modal";
import Pagination from "../../components/Pagination";
import { MenuType, AccountDataType } from "../../types/DataTypes";
import DateWrapper from "../../../components/DateWrapper";
import connectivityService from "../../../service/ConnectivityService";
import ActivateAccount from "./templates/ActivateAccount";
import DeactivateAccount from "./templates/DeactivateAccount";
import { ToastContainer, toast } from "react-toastify";
import { ToastTypes } from "../../../components/DataTypes";
import { useNavigate } from "react-router-dom";
import { useAdminSession } from "../../context/AdminSessionContext";
import { sortDataByDate } from "../../../utils";
import {
    Loader,
    LoaderWrapper,
    SectionContainer,
    SpacedBetweenColumnBox,
} from "../../../styles";

const tableHeader = [
    { value: "accountId", text: "ID" },
    { value: "customerName", text: "Customer Name" },
    { value: "customerEmail", text: "Customer Email" },
    { value: "accountBalance", text: "Account Balance" },
    { value: "accountType", text: "Account Type" },
    {
        value: "lastTransaction",
        text: "Last Transaction Date",
        wrapper: DateWrapper,
    },
    { value: "status", text: "Status", wrapper: StatusWrapper },
];

const itemsPerPage = 8;

const Account = () => {
    const [selectedAccount, setSelectedAccount] = useState<AccountDataType>();
    const [listActionMenus, setListActionMenus] = useState<MenuType[]>();
    const [modalType, setModalType] = useState<string>("");
    const [showModal, setModalShow] = useState<boolean>(false);
    const [tableData, setTableData] = useState<AccountDataType[]>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { sessionAdmin } = useAdminSession();
    const [pageRefresh, setPageRefresh] = useState<boolean>(false);
    const [selectedMenu, setSelectedMenu] = useState<MenuType>();

    const handleAccount = async () => {
        let data: { status: string | undefined } = {
            status:
                selectedMenu === "Activate"
                    ? "Active"
                    : selectedMenu === "Deactivate"
                    ? "Inactive"
                    : undefined,
        };

        if (data.status) {
            const url = `${process.env.REACT_APP_PRODUCT_ROOT_URL}/${selectedAccount?.accountId}`;
            const res = await connectivityService().makeApiCall(
                "post",
                url,
                data,
                sessionAdmin.accessToken
            );

            if (res.code === 200 || res.code === 201) {
                setPageRefresh(!pageRefresh);
            } else {
                const error = await connectivityService().handleErrors(
                    res,
                    notify
                );
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.");
                    navigate("/admin");
                }
            }
        }
    };

    const handleMenuClick = async (menu: MenuType) => {
        if (menu === "Credit Limit") {
        } else if (menu === "Transactions") {
            navigate(
                `/admin/home/account/${selectedAccount?.accountId}/transactions`,
                { state: { account: selectedAccount } }
            );
        } else {
            setModalShow(true);
            setModalType(menu);
        }
    };

    const notify = (type: ToastTypes, msg: string) =>
        toast(msg, { type: type, position: "top-center", theme: "colored" });

    useEffect(() => {
        const fetchData = async () => {
            const res = await connectivityService().makeApiCall(
                "get",
                `${process.env.REACT_APP_ACCOUNT_ROOT_URL}`,
                undefined,
                sessionAdmin.accessToken
            );

            if (res.code === 200 || res.code === 201) {
                const accountData = res.data.map((val: any, ind: any) => {
                    return {
                        accountId: val.accountId,
                        customerId: val.customerId,
                        customerEmail: val.customerEmail,
                        customerName: val.customerName,
                        accountBalance: val.one2one_account.accountBalance,
                        accountType: val.one2one_account.accountType,
                        lastTransaction: val.one2one_account.updatedAt,
                        status: "active",
                    };
                });
                const sortedData = sortDataByDate(
                    accountData,
                    "lastTransaction"
                );
                setTableData(sortedData);
            } else {
                const error = await connectivityService().handleErrors(
                    res,
                    notify
                );
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.");
                    navigate("/admin");
                }
            }
        };
        fetchData();
    }, []);

    const offset = itemsPerPage * (currentPage - 1);
    let displayData = tableData
        ? tableData.slice(offset, offset + itemsPerPage)
        : tableData;

    return (
        <SectionContainer>
            {showModal && (
                <Modal
                    children={
                        modalType === "Activate" ? (
                            <ActivateAccount
                                activate={handleAccount}
                                formClose={() => setModalShow(false)}
                                loading={loading}
                            />
                        ) : (
                            <DeactivateAccount
                                deactivate={handleAccount}
                                formClose={() => setModalShow(false)}
                                loading={loading}
                            />
                        )
                    }
                    show={showModal}
                    onClose={() => setModalShow(false)}
                />
            )}

            <TopMenu
                navTag="Account"
                hasNoButton
                hasSubMenus={false}
                portal="admin"
                searchData={() => {}}
            />

            <SpacedBetweenColumnBox>
                {tableData !== undefined ? (
                    <>
                        <div className="page-section">
                            <AccountTable
                                data={displayData as AccountDataType[]}
                                offset={offset}
                                nullMessage="No account information is available"
                                showPlusIcon={false}
                                tableHeader={tableHeader}
                                addTableActionBtn={true}
                                onActionClicked={(item: AccountDataType) => {
                                    setSelectedAccount(item);
                                    setListActionMenus(["Transactions"]);
                                }}
                                actionMenus={listActionMenus as MenuType[]}
                                selectedAccount={selectedAccount}
                                onMenuClick={(menu) => {
                                    setSelectedMenu(menu);
                                    handleMenuClick(menu);
                                }}
                            />
                        </div>

                        {tableData !== undefined &&
                            tableData.length > itemsPerPage && (
                                <Pagination
                                    data={tableData as AccountDataType[]}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    itemsPerPage={itemsPerPage}
                                />
                            )}
                    </>
                ) : (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                )}
            </SpacedBetweenColumnBox>

            <ToastContainer limit={1} />
        </SectionContainer>
    );
};

export default Account;
