import React from "react";
import TableRow, { Row } from "./TableRow";
import { Box, UserTableContainer } from "../styles";
import ActionMenus from "./ActionMenus";
import { TableHeaderInfo } from "../types";
import { AppDataType, AppMenuType } from "./DataTypes";

interface GenericTableProps<T extends AppDataType> {
    data: T[];
    nullMessage: string;
    showPlusIcon: boolean;
    additionMessage?: string;
    tableHeader: TableHeaderInfo[];
    addTableActionBtn: boolean;
    onActionClicked: (item: T) => void;
    actionMenus: AppMenuType[];
    selectedItem?: T;
    onMenuClick: (m: AppMenuType) => void;
    offset: number;
    onAddClick?: () => void;
}

const GenericTable = <T extends AppDataType>({
    data,
    nullMessage,
    showPlusIcon,
    additionMessage,
    addTableActionBtn,
    tableHeader,
    onActionClicked,
    actionMenus,
    selectedItem,
    onMenuClick,
    offset,
    onAddClick,
}: GenericTableProps<T>) => {
    const fetchFromObject = (obj: any, prop?: string): string => {
        return obj[prop as keyof typeof obj] || "";
    };

    const fillRowData = (
        item: any,
        header: TableHeaderInfo,
        key: number,
        dataIndex: number
    ): React.ReactNode => {
        let Data: React.ReactNode;
        if (header.wrapper) {
            Data = (
                <header.wrapper
                    value={fetchFromObject(item, header.value)}
                ></header.wrapper>
            );
        } else if (key === 0) {
            Data = dataIndex + 1 + offset;
        } else {
            Data = `${fetchFromObject(item, header.value)}`;
        }
        return (
            <Row key={key} className={`row row${key}`}>
                <p>{Data || "N/A"}</p>
            </Row>
        );
    };

    return (
        <UserTableContainer>
            <TableRow className="table-head">
                {tableHeader.map((s, index) => (
                    <Row key={index} className={`row row${index}`}>
                        <p>{s.text}</p>
                        {/* {index !== 0 && (
                            <img
                                src="/assets/institution/icons/sort-icon.svg"
                                alt=""
                            />
                        )} */}
                    </Row>
                ))}
                {addTableActionBtn && (
                    <Row className={`row row-last}`}>
                        <p>Action</p>
                        {/* <img
                            src="/assets/institution/icons/sort-icon.svg"
                            alt=""
                        /> */}
                    </Row>
                )}
            </TableRow>
            {data === undefined ? (
                <></>
            ) : data.length > 0 ? (
                data.map((item, dataIndex) => (
                    <TableRow className="table-row" key={dataIndex}>
                        {tableHeader.map((header, index) =>
                            fillRowData(item, header, index, dataIndex)
                        )}
                        {addTableActionBtn && (
                            <div onClick={() => onActionClicked(item)}>
                                <ActionMenus
                                    menus={actionMenus}
                                    selectedUser={selectedItem as AppDataType}
                                    item={item}
                                    onMenuClick={
                                        onMenuClick as (m: AppMenuType) => void
                                    }
                                    activator={
                                        <>
                                            <p>Action</p>
                                            <img
                                                src="/assets/institution/icons/dropdown.svg"
                                                alt=""
                                            />
                                        </>
                                    }
                                    type="tableAction"
                                />
                            </div>
                        )}
                    </TableRow>
                ))
            ) : (
                <Box style={{ backgroundColor: "none" }}>
                    <div>
                        <p>{nullMessage}</p>
                        {showPlusIcon && (
                            <div onClick={onAddClick}>
                                <p className="btn">
                                    <span>+</span>
                                    <span
                                        style={{
                                            textDecoration: "underline",
                                            marginLeft: "10px",
                                        }}
                                    >
                                        {additionMessage}
                                    </span>
                                </p>
                            </div>
                        )}
                    </div>
                </Box>
            )}
        </UserTableContainer>
    );
};

export default GenericTable;
