import * as yup from 'yup';


export const ForgotPasswordSchema = yup.object({
    email: yup
        .string()
        .trim()
        .email('This is not a valid email')
        .required('Email field is empty')
});


export const ResetPasswordSchema = yup.object({
    password: yup
        .string()
        .trim()
        .required("This field is required")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            "Must contain 8 characters, one uppercase, one lower case, one number and one special case character."
        ),
    confirmPassword: yup
        .string()
        .trim()
        .oneOf([yup.ref("password"), null], "Password doesn't match")
        .required('Confirm password is required')
});

