import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';




const ResetPasswordRedirect = () => {

    const {portal, id} = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        navigate(`/${portal}/reset-password`, {state:{userId: id}});
    }, [portal, id])

  return (
    <div>
        
    </div>
  )
}

export default ResetPasswordRedirect
