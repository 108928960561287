import {
    CreateAccountProvider,
    useCreateAccount,
} from "./context/DoctorCreateAccountContext";
import { Container } from "../CreateAccount.style";
import DoctorInformation, { InviteDataType } from "./templates/GeneralInfo";
import OtpInput from "./templates/OtpInput";
import ProfessionalInfo from "./templates/ProfessionalInfo";
import SuccessCard from "./templates/SuccessCard";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Close = styled.div`
    float: right;
    margin-right: 42px;
`;
const Body = styled.div``;

const Home = () => {
    const { subPages } = useCreateAccount();
    const [curSubPage] = subPages;

    const navigate = useNavigate();
    const location = useLocation();

    function renderSubPage() {
        switch (curSubPage) {
            case 0:
                return <DoctorInformation inviteData={location?.state as InviteDataType } />;
            case 1:
                return <OtpInput />;
            case 2:
                return <ProfessionalInfo />;
            default:
                return <SuccessCard />;
        }
    }

    return (
        <Container>
            <Body>
                {curSubPage !== 3 && (
                    <Close>
                        <p
                            className="close"
                            onClick={() => navigate("/institution")}
                        >
                            X
                        </p>
                    </Close>
                )}
                {renderSubPage()}
            </Body>
        </Container>
    );
};

const CreateAccount = () => {
    return (
        <CreateAccountProvider>
            <Home />
        </CreateAccountProvider>
    );
};

export default CreateAccount;
