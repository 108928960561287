import * as yup from 'yup';


export const LoginSchema = yup.object({
    userName: yup
        .string()
        .trim()
        .email('This is not a valid email')
        .required('Email field is empty'),
    password: yup
        .string()
        .trim()
        .required("This field is required")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must contain 8 characters, one uppercase, one lower case, one number and one special case character."
        )
});

