import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../../../../components/Button";
import { ToastTypes } from "../../../../components/DataTypes";
import connectivityService from "../../../../service/ConnectivityService";
import { useAdminSession } from "../../../context/AdminSessionContext";
import { InstitutionDataType, MenuType } from "../../../types/DataTypes";
import { useNavigate } from "react-router-dom";

const Modal = styled.div`
    .content-inner{
        padding: 16px;
        font-family: 'Courier New';
        background-color: #ECF5FF;
        margin-top: 15px;
        margin-bottom: 30px;

        .value{
            line-height: 16px;
            color: #373737;
        }
    }

    .head-1, .head-2, .head-3{
        font-weight: 700;
    }

    .head-1{
        font-size: 22px;
    }

    .head-2{
        font-size: 14px;
        margin-top: 30px;
    }

    .row{
        display: flex;
        flex-direction : row;
        justify-content: space-between;
    }

    .row1, .row2, .row3, .row4, .row5{
        margin-bottom: 20px;
    }
    
    .row p:(first-child){
        margin-bottom: 8px;
    }
`


interface ViewInstitutionProps {
    Verify: () => void,
    institutionRep?: InstitutionDataType
    selectedMenu?: MenuType
    loading: boolean
}

const ViewInstitution = ({ Verify, institutionRep, selectedMenu, loading}: ViewInstitutionProps) => {
    const [institution, setInstitution] = useState<InstitutionDataType>();
    const {sessionAdmin} = useAdminSession();

    const navigate = useNavigate();

    const notify = (type: ToastTypes, msg: string) => (
        toast(msg, { type: type, position: "top-center", theme: "colored" })
    );

    useEffect(() => {
        const fetchData = async () => {
            const url = `${process.env.REACT_APP_INST_URL}/${institutionRep?.shortCode}`;
            const res = await connectivityService().makeApiCall("get", url, undefined, sessionAdmin.accessToken);
            if (res.code === 200 || res.code === 201) {
                setInstitution(res.data);
            }
            else {
                const error = await connectivityService().handleErrors(res, notify)
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.")
                    navigate("/admin")
                }
            }
        }
        fetchData();
    }, []);

    return (
        <>
            <Modal>
                <p className="head-1">Review</p>
                <p className="head-2">Institution Details</p>
                <div className="content-inner">
                    <div className="row row1">
                        <div>
                            <p className="head-3">Name</p>
                            <p className="value">{institution?.name ?? "loading..."}</p>
                        </div>
                        <div>
                            <p className="head-3">Type</p>
                            <p className="value">{institution?.institutionType ?? "loading..."}</p>
                        </div>
                    </div>
                    <div className="row row2">
                        {institution?.contactPhone && <div>
                            <p className="head-3">Phone Number</p>
                            <p className="value">{institution?.contactPhone ?? "loading..."}</p>
                        </div>
                        }
                        <div>
                            <p className="head-3">Email Address</p>
                            <p className="value">{institution?.contactEmail ?? "loading..."}</p>
                        </div>
                    </div>
                    <div className="row3">
                        <p className="head-3">Institution Summary</p>
                        <p className="value">{institution?.institutionSummary ?? "loading..."}</p>
                    </div>
                    <div className="row row4">
                        <div>
                            <p className="head-3">Address</p>
                            <p className="value">{institution?.addressLine1 ?? "loading..."} {institution?.addressLine2 || ""}</p>
                        </div>
                        <div>
                            <p className="head-3">Town</p>
                            <p className="value">{institution?.town ?? "loading..."}</p>
                        </div>
                    </div>
                    <div className="row row5">
                        <div>
                            <p className="head-3">State</p>
                            <p className="value">{institution?.state ?? "loading..."}</p>
                        </div>
                        <div>
                            <p className="head-3">Country</p>
                            <p className="value">{institution?.countryOfRegistration ?? "loading..."}</p>
                        </div>
                    </div>

                    <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <div >
                            <p className="head-3">CAC Document</p>
                            <a target={"_blank"} href={institution?.legalDocumentUri}>cac.pdf</a>
                        </div>
                        
                        <div >
                            <p className="head-3">Logo</p>
                            <a target={"_blank"} href={institution?.logoUri}>logo.jpg</a>
                        </div>
                    </div>
                </div>
                <Button disabled={institution?.workStatus === "APPROVED"} onClick={Verify} children="Verify" loading={loading} />
            </Modal>
        </>
    )

}

export default ViewInstitution