import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import connectivityService from "../../../../../service/ConnectivityService";


export type subPagesType = 0|1|2|3;

type adminInfo = {
    firstName: string,
    lastName: string,
    password: string
}


type basicInfo = {
    institutionName: string,
    institutionType: string,
    institutionEmail: string,
    institutionPhoneNumber: string,
    institutionSummary: string

}

type AddressInfo = {
    companyAddress: string,
    town: string,
    state: string,
    country: string
}

type AccountDocuments = {
    cacDocument: File[];
    logo: File[];
}



interface CreateAccountProviderProps {
    children: ReactNode;
}

type ContextType<T> = [T, Dispatch<SetStateAction<T>>];
interface CreateAccountContextType {
    admin: ContextType<adminInfo>;
    basic: [basicInfo, Dispatch<SetStateAction<basicInfo>>];
    subPages: ContextType<subPagesType>;
    address: ContextType<AddressInfo>;
    documents: {setAccountDocuments: Dispatch<SetStateAction<AccountDocuments>>};
    createAccount: (cacDocument:File[], logo:File[], newRole:boolean) => Promise<any>;

}


const CreateAccountContext = createContext({} as CreateAccountContextType);

export const useCreateAccount = () => {
    return useContext(CreateAccountContext);
}

export const CreateAccountProvider = ({children}:CreateAccountProviderProps) => {
    const [curSubPage, setCurSubPage] = useState<subPagesType>(0);

    const [adminInfo, setAdminInfo] = useState<adminInfo>(
        {
            firstName: "",
            lastName: "",
            password: ""
        }
    );

    const [basicInfo, setBasicInfo] = useState<basicInfo>(
        {
            institutionName: "",
            institutionType: "",
            institutionEmail: "",
            institutionPhoneNumber: "",
            institutionSummary: ""
        }
    );

    const [addressInfo, setAddressInfo] = useState<AddressInfo>({
        companyAddress: "",
        town: "",
        state: "",
        country: ""
    });

    const [accountDocuments, setAccountDocuments] = useState<AccountDocuments>({
        cacDocument: [],
        logo: []
    });

    const createAccount = async (cacDocument:File[], logo:File[], newRole:boolean) => {

        const formData = new FormData();

        formData.append("name", basicInfo.institutionName);
        formData.append("institutionType", basicInfo.institutionType);
        formData.append("institutionSummary", basicInfo.institutionSummary);
        formData.append("contactFirstName", adminInfo.firstName);
        formData.append("contactLastName", adminInfo.lastName);
        formData.append("password", adminInfo.password);
        formData.append("contactEmail", basicInfo.institutionEmail);
        formData.append("contactPhone", basicInfo.institutionPhoneNumber);
        formData.append("addressLine1", addressInfo.companyAddress);
        formData.append("town", addressInfo.town);
        formData.append("state", addressInfo.state);
        formData.append("country", addressInfo.country);
        formData.append("registrationId", "65927kdh");
        formData.append("countryOfRegistration", addressInfo.country);
        formData.append("workStatus", "PENDING");
        formData.append("cacDocument", cacDocument[0]);
        formData.append("logoDocument", logo[0]);
        formData.append("newRole", newRole.toString());
        
        const resp = await connectivityService().makeApiCallWithFormData(
            "post", 
            process.env.REACT_APP_CREATE_INSTITUTION_URL,
            formData,
        );

        return(resp);
             
     
    }
    return(
        <CreateAccountContext.Provider value={{
            admin: [adminInfo, setAdminInfo],
            basic: [basicInfo, setBasicInfo],
            subPages: [curSubPage, setCurSubPage],
            address: [addressInfo, setAddressInfo],
            documents: {setAccountDocuments},
            createAccount: createAccount
        }}>
            {children}
        </CreateAccountContext.Provider>
    )
}