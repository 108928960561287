import React, { useState } from 'react'
import { useCreateAccount } from '../context/InstCreateAccountContext'
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { FormStyle } from './BasicInformation';
import { DoubleInputWrapper, FormBox, Heading } from '../../CreateAccount.style';
import Input from '../../../../../components/Input';
import toggleButton from '../../../../utils/toggleButton';
import Select from '../../../../../components/Select';
import Footer from './Footer';
import en from "react-phone-number-input/locale/en.json";
import { AddressSchema } from '../InstCreateAccount.validators';




const AddressInformation = () => {
    const { address, subPages } = useCreateAccount();
    const [addressInfo, setAddressInfo] = address;
    const [curSubPage, setCurSubPage] = subPages;
    const [loading, setLoading] = useState<boolean>(false);

    const countries = Object.values(en).slice(3, 255).sort();

    const handleSubmit = (values: typeof addressInfo, actions: FormikHelpers<typeof addressInfo>) => {
        setLoading(true);
        setAddressInfo(values);
        setCurSubPage(3);
        setLoading(false);
    }
    return (
        <Formik
            initialValues={addressInfo}
            onSubmit={handleSubmit}
            validationSchema={AddressSchema}>
            {
                (formik: FormikProps<typeof addressInfo>) => {
                    const toggle = toggleButton<typeof addressInfo>(formik.values);

                    return (
                        <div style={{ height: "100%" }}>
                            <Form style={FormStyle}>
                                <div style={{ padding: "0 40px 0 55px" }}>
                                    <Heading>Institution Information</Heading>
                                    <FormBox>
                                        <Input
                                            title="Company address"
                                            name="companyAddress"
                                            type='text'
                                            placeholder='Enter company address'
                                        />
                                        <DoubleInputWrapper>
                                            <Input
                                                title="Town"
                                                name="town"
                                                type='text'
                                                placeholder='Town'
                                            />

                                            <Input
                                                title="State"
                                                name="state"
                                                type='text'
                                                placeholder='State'
                                            />
                                        </DoubleInputWrapper>
                                        <Select
                                            title='Country'
                                            name='country'
                                            options={countries}
                                            placeholder="Pick an option"
                                        />
                                    </FormBox>
                                </div>
                                <Footer shouldDisable={!toggle}
                                    loading={loading} />
                            </Form>
                        </div>
                    )
                }
            }

        </Formik>
    )
}

export default AddressInformation
