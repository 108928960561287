import { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { useLocation } from "react-router-dom";
import { SingleInviteSchema } from "../Invites.validators";
import connectivityService from "../../../../service/ConnectivityService";
import { ToastContainer, toast } from "react-toastify";
import { ToastTypes } from "../../../../components/DataTypes";
import { useNavigate } from "react-router-dom";
import { useInstitutionSession } from "../../../context/InstitutionSessionContext";
import PhoneInput from "../../../components/PhoneInput";

const InvitesList = styled.div`
    width: 100%;
    padding: 50px 40px 0 40px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .phone-number-form-group {
        select {
            width: 100px;
        }
    }
    .heading {
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        color: #373737;
        margin-top: 30px;
    }

    .row {
        display: flex;
        flex-direction: row;
        gap: 24px;
        padding-bottom: 24px;
        align-items: flex-end;
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 14px 0;
        border-top: 2px solid #2a6bb0;
    }

    input,
    select {
        padding: 13px;
        width: 100%;
        height: 50px;
        background: #ffffff;
        border: 1px dashed #2a6bb0 !important;
        border-radius: 4px;
    }

    .row label {
        display: none;
    }

    .add input,
    .add select {
        border: 1px solid #2a6bb0 !important;
    }

    .add label {
        display: block !important;
    }

    button {
        border-radius: 4px;
    }

    #button.img-btn:hover {
        background: #2a6bb0 !important;
    }

    .entries {
        padding-top: 24px;
        border-bottom: 1px solid #2a6bb0;
    }
`;

interface stateType {
    invites: InviteObject[];
}

type InviteObject = {
    email: string;
    phoneNumber: string;
    role: string;
    key?: number;
};

type InviteData = {
    email: string;
    phoneNumber: string;
    userRole: string;
};

const BulkInvitesList = () => {
    const location = useLocation();
    const [inviteArray, setInviteArray] = useState<InviteObject[]>([]);
    const [lastEditedRow, setLastEditedRow] = useState<number>();
    const [lastKey, setLastKey] = useState<number>(0);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const { sessionInstitution } = useInstitutionSession();

    const navigate = useNavigate();

    const inviteObj = {
        email: "",
        phoneNumber: "",
        role: "",
    };

    useEffect(() => {
        const invites = (location.state as stateType).invites;
        let newInviteArray = [];
        for (let i = 0; i < invites.length; i++) {
            const newObject = { ...invites[i], key: i };
            newInviteArray.push(newObject);
        }
        setInviteArray(newInviteArray);
        setLastKey(newInviteArray.length - 1);
    }, [location.state]);

    const notify = (type: ToastTypes, msg: string) =>
        toast(msg, { type: type, position: "top-center", theme: "colored" });

    const disablePreviousRow = () => {
        document
            .querySelector(`.row${lastEditedRow} input[name="email"]`)
            ?.setAttribute("disabled", "");
        document
            .querySelector(`.row${lastEditedRow} input[name="phoneNumber"]`)
            ?.setAttribute("disabled", "");
        document
            .querySelector(`.row${lastEditedRow} select[name="role"]`)
            ?.setAttribute("disabled", "");
    };

    const enableRowEdit = (key: number) => {
        disablePreviousRow();
        document
            .querySelector(`.row${key} input[name="email"]`)
            ?.removeAttribute("disabled");
        document
            .querySelector(`.row${key} input[name="phoneNumber"]`)
            ?.removeAttribute("disabled");
        document
            .querySelector(`.row${key} select[name="role"]`)
            ?.removeAttribute("disabled");
        setLastEditedRow(key);
    };

    const handleChange = (name: string, value: string, key?: number) => {
        const newState = inviteArray.map((val, i) => {
            if (val.key === key) {
                return { ...val, [name]: value };
            } else {
                return val;
            }
        });

        setInviteArray(newState);
        setLastKey(newState.length - 1);
    };

    const removeEntry = (key: number) => {
        const newState = inviteArray.filter((val, ind) => ind !== key);
        setInviteArray(newState);
        setLastKey(newState.length);
    };

    const handleSubmit = (
        values: InviteObject,
        actions: FormikHelpers<InviteObject>
    ) => {
        const newInvite = {
            email: values.email,
            phoneNumber: values.phoneNumber,
            role: values.role,
            key: lastKey + 1,
        };
        const newInviteArray = [...inviteArray, newInvite];
        setInviteArray(newInviteArray);
        setLastKey(newInviteArray.length - 1);
        actions.resetForm({ values: { email: "", phoneNumber: "", role: "" } });
    };

    const addInvites = async () => {
        setLoading(true);

        const institutionCode = sessionInstitution.shortCode;
        const url = `${process.env.REACT_APP_INST_URL}/${institutionCode}/userInvites`;

        const inviteData: InviteData[] = [];

        inviteArray.forEach((val) => {
            inviteData.push({
                email: val.email,
                phoneNumber: val.phoneNumber,
                userRole: val.role.toLowerCase(),
            });
        });

        const data = {
            invites: inviteData,
            host: "institution",
        };

        const response = await connectivityService().makeApiCall(
            "post",
            url,
            data,
            sessionInstitution.accessToken
        );

        setLoading(false);

        if (response.code === 200 || response.code === 201) {
            navigate("/institution/home/invites");
        } else {
            const error = await connectivityService().handleErrors(
                response,
                notify
            );
            if (error === "AccessDenied") {
                notify("error", "Login session expired. Please re-login.");
                navigate("/institution/login");
            }
        }
    };

    return (
        <>
            <InvitesList>
                <div>
                    <img src="/favicon.png" width="50px" alt="one-2-one logo" />
                    <p className="heading">Add Invites</p>

                    <div className="entries">
                        {inviteArray.map((val, key) => {
                            return (
                                <Formik
                                    initialValues={val}
                                    onSubmit={handleSubmit}
                                    validationSchema={SingleInviteSchema}
                                >
                                    {(formik: FormikProps<InviteObject>) => {
                                        const toggle =
                                            Object.keys(formik.errors)
                                                .length === 0;
                                        return (
                                            <Form>
                                                <div
                                                    className={`row row${key}`}
                                                    key={key}
                                                >
                                                    <Input
                                                        name="email"
                                                        type="text"
                                                        tempOnChange={(e) => {
                                                            formik.setFieldValue(
                                                                "email",
                                                                e.target.value
                                                            );
                                                            handleChange(
                                                                "email",
                                                                e.target.value,
                                                                val.key
                                                            );
                                                            setDisabled(
                                                                !toggle
                                                            );
                                                        }}
                                                        disabled
                                                    />
                                                    <Input
                                                        name="phoneNumber"
                                                        type="text"
                                                        tempOnChange={(e) => {
                                                            formik.setFieldValue(
                                                                "phoneNumber",
                                                                e.target.value
                                                            );
                                                            handleChange(
                                                                "phoneNumber",
                                                                e.target.value,
                                                                val.key
                                                            );
                                                            setDisabled(
                                                                !toggle
                                                            );
                                                        }}
                                                        disabled
                                                    />
                                                    <Select
                                                        name="role"
                                                        tempOnChange={(e) => {
                                                            formik.setFieldValue(
                                                                "role",
                                                                e.target.value
                                                            );
                                                            handleChange(
                                                                "role",
                                                                e.target.value,
                                                                val.key
                                                            );
                                                            setDisabled(
                                                                !toggle
                                                            );
                                                        }}
                                                        options={[
                                                            "Patient",
                                                            "Doctor",
                                                        ]}
                                                        disabled
                                                    />
                                                    <Button
                                                        type="button"
                                                        className="img-btn"
                                                        onClick={() =>
                                                            enableRowEdit(key)
                                                        }
                                                        children={
                                                            <img
                                                                src="/assets/icons/edit.svg"
                                                                alt="edit"
                                                            />
                                                        }
                                                    />
                                                    <Button
                                                        type="button"
                                                        className="img-btn"
                                                        onClick={() =>
                                                            removeEntry(key)
                                                        }
                                                        children={
                                                            <img
                                                                src="/assets/icons/delete.svg"
                                                                alt="delete"
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            );
                        })}
                    </div>
                    <div>
                        <Formik
                            initialValues={inviteObj}
                            onSubmit={handleSubmit}
                            validationSchema={SingleInviteSchema}
                        >
                            {(formik: FormikProps<InviteObject>) => {
                                return (
                                    <Form>
                                        <div
                                            className="row add"
                                            style={{
                                                marginTop: "24px",
                                                alignItems: "flex-end",
                                            }}
                                        >
                                            <Input
                                                name="email"
                                                title="Email"
                                                type="text"
                                                required
                                            />
                                            <PhoneInput
                                                title="Phone number"
                                                name="phoneNumber"
                                                placeholder="Phone Number"
                                                required
                                            />
                                            <Select
                                                name="role"
                                                title="Role"
                                                placeholder="Select role"
                                                options={["Patient", "Doctor"]}
                                                required
                                            />
                                            <Button
                                                type="submit"
                                                className="img-btn"
                                                children={
                                                    <img
                                                        src="/assets/icons/add.svg"
                                                        alt="edit"
                                                    />
                                                }
                                            />
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
                <div className="footer">
                    <Button
                        children="Back to Invites"
                        disabled={false}
                        onClick={() => {
                            navigate("/institution/home/invites");
                        }}
                    />

                    <Button
                        children="Add Invites"
                        disabled={disabled}
                        loading={loading}
                        onClick={addInvites}
                    />
                </div>
                <ToastContainer />
            </InvitesList>
        </>
    );
};

export default BulkInvitesList;
