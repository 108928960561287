import styled from "styled-components";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { ProductSchema } from "../Products.validators";
import Button from "../../../../components/Button";
import Select from "../../../../components/Select";
import TextArea from "../../../../components/TextArea";
import { FormBox } from "../../../styles/generalStyles";
import { TwoColumnInputFlex } from "../../../styles/generalStyles";
import connectivityService from "../../../../service/ConnectivityService";
import { ToastContainer, toast } from 'react-toastify';
import { ToastTypes } from '../../../../components/DataTypes';
import { useState } from "react";
import { ProductDataType } from "../../../types/DataTypes";
import toggleButton from "../../../../institution/utils/toggleButton";
import { useAdminSession } from "../../../context/AdminSessionContext";
import { useNavigate } from "react-router-dom";

const ModalContent = styled.div`
    margin-top : -17px;

    .file-input{
        height: 50px;
        width: 100%;
        padding: 13px;
    }

    .title{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        p{
            font-weight: 400;
            font-size: 16px;
            a{
                color: #2A6BB0;
            }
        }
    }

    .input-blue-border{
        background: #FFFFFF;
        border: 1px solid #2A6BB0;
        border-radius: 4px;
    }
    
    .input-section{
        margin-top: 16px;
        .button {
            margin-top: 34px;
        }
        label{
            font-weight: 400;
            font-size: 16px;
            color: #4E4E4E;
        }
    }

    .bulk{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 24px;
        align-items: end;
    }

    #button.img-btn:hover{
        background: #2A6BB0 !important;
    }
`

type ProductObjType = {
    category?: string,
    name?: string,
    description?: string,
    unitType?: string,
    unit?: string
}

interface AddProductProps {
    type: string,
    formClose: () => void,
    selectedProduct?: ProductDataType,
    pageRefresh: boolean,
    setPageRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

const AddProduct = ({ type, formClose, selectedProduct, pageRefresh, setPageRefresh }: AddProductProps) => {
    const [productData, setProductData] = useState<ProductObjType>(
        {
            category: "",
            name: "",
            description: "",
            unitType: "",
            unit: ""
        }
    )

    const selectedProductData = {
        category: selectedProduct?.productCategory,
        name: selectedProduct?.productName,
        description: selectedProduct?.productDescription,
        unitType: selectedProduct?.unitType,
        unit: selectedProduct?.unit
    }

    const [loading, setLoading] = useState<boolean>(false);
    const {sessionAdmin} = useAdminSession();

    const navigate = useNavigate();

    const notify = (type: ToastTypes, msg: string) => (
        toast(msg, { type: type, position: "top-center", theme: "colored" })
    )

    const handleSubmit = async (values: ProductObjType, actions: FormikHelpers<ProductObjType>) => {
        setLoading(true);
        setProductData(values);

        const productObj = {
            productCategory: values.category,
            productName: values.name,
            productDescription: values.description,
            unitType: values.unitType,
            unit: values.unit,
            createdBy: sessionAdmin.userId
        }
        const baseUrl = process.env.REACT_APP_PRODUCT_ROOT_URL;
        const url = type === "Add" ? baseUrl : `${baseUrl}/${selectedProduct?.productId}`;

        const response = await connectivityService().makeApiCall(
            'post', url, productObj, sessionAdmin.accessToken
        )

        setLoading(false);

        if (response.code === 200 || response.code === 201) {
            type === "Add" ?
            notify("success", "Product added successfuly"):
            notify("success", "product details updated");
            type === "Add" &&
            actions.resetForm({ values: { 
                category: "", 
                name: "", 
                description: "",
                unit: "",
                unitType: ""} });
            setPageRefresh(!pageRefresh);
            formClose();
        }
        else {
            const error = await connectivityService().handleErrors(response, notify)
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.")
                    navigate("/admin")
                }
        }
    }


    return (
        <>
            <Formik
                initialValues={type === "Add" ? productData : selectedProductData}
                onSubmit={handleSubmit}
                validationSchema={ProductSchema}>
                {
                    (formik: FormikProps<ProductObjType>) => {
                        const toggle = toggleButton<ProductObjType>(formik.values) &&
                            Object.keys(formik.errors).length === 0;
                        return (
                            <ModalContent>
                                <div className="title">
                                    {type} Product
                                </div>
                                <div className="input-section">
                                    <Form>
                                        <TwoColumnInputFlex>
                                            <FormBox>
                                                <div className="flex">
                                                    <Select
                                                        name="category"
                                                        title="Product Category"
                                                        options={['Consultation']}
                                                        placeholder="Select category"
                                                        required
                                                    />
                                                    <Select
                                                        name="name"
                                                        title="Product Name"
                                                        options={['Immediate Consultation']}
                                                        placeholder="Select name"
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                    <TextArea
                                                        name="description"
                                                        title="Product Description"
                                                        placeholder='Type here...'
                                                        width="100%"
                                                        required
                                                    />
                                                </div>
                                                <div className="flex">
                                                    <Select
                                                        name="unitType"
                                                        title="Unit Type"
                                                        options={['Time']}
                                                        placeholder="Select unit type"
                                                        required
                                                    />
                                                    <Select
                                                        name="unit"
                                                        title="Unit"
                                                        options={['Min', 'Hr']}
                                                        placeholder="Select unit"
                                                        required
                                                    />
                                                </div>
                                                <Button
                                                    className="button"
                                                    type="submit"
                                                    loading={loading}
                                                    disabled={!toggle}
                                                    children={`${type} Product`}
                                                    style={{ marginTop: "24px" }}
                                                />
                                            </FormBox>
                                        </TwoColumnInputFlex>
                                    </Form>
                                </div>
                            </ModalContent>
                        )
                    }
                }
            </Formik>
            <ToastContainer limit={1} />
        </>
    )

}

export default AddProduct;