

const toggleButton = <T>(inputValues: T) => {
    const emptyField = Object.values(inputValues).filter((f) => (f === ""));
    if (emptyField.length === 0) return true;
    return false;
    
}


export default toggleButton