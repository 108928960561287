import { useEffect, useState } from "react";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import { FormBox, FormStyle, Heading } from "../../styles/generalStyles";
import Input from "../../../components/Input";
import AuthPagesFooter from "../../components/AuthPagesFooter";
import toggleButton from "../../utils/toggleButton";
import { LoginSchema } from "./Login.validators";
import { ForgotPassword } from "./Login.style";
import { Link } from "react-router-dom";
import connectivityService from "../../../service/ConnectivityService";
import { ToastContainer, toast } from "react-toastify";
import { ToastTypes } from "../../../components/DataTypes";
import { useInstitutionSession } from "../../context/InstitutionSessionContext";
import { useLocation } from "react-router-dom";
import { LoginBackButton } from "../../../admin/styles/generalStyles";
import styled from "styled-components";

const MainSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 100vh;
    width: 60%;

    @media (max-width: 768px) {
        width: 100%;
`;

const FormContent = styled.div`
    width: 100%;
    padding: 42px;

    @media (max-width: 768px) {
        padding: 14px;
    }
`;

type LoginData = {
    userName: string;
    password: string;
};

type stateType = {
    notifySuccess: boolean;
    email: string;
};

const Login = () => {
    const { setSessionInstitution } = useInstitutionSession();
    const [loginData, setLoginData] = useState<LoginData>({
        userName: "",
        password: "",
    });

    const [loading, setLoading] = useState<boolean>(false);

    const location = useLocation();

    const notify = (type: ToastTypes, msg: string) =>
        toast(msg, { type: type, position: "top-center", theme: "colored" });

    const navigate = useNavigate();

    useEffect(() => {
        if ((location.state as stateType)?.notifySuccess)
            notify("success", "Password reset was successful");

        if ((location.state as stateType)?.email) {
            setLoginData((prevState) => ({
                ...prevState,
                userName: (location.state as stateType).email,
            }));

            notify(
                "success",
                "Account created successfully. Login to continue"
            );
        }
    }, [location.state]);

    const handleSubmit = async (
        values: LoginData,
        actions: FormikHelpers<LoginData>
    ) => {
        setLoading(true);
        setLoginData(values);

        const data = {
            userName: values.userName,
            password: values.password,
            role: "institution-admin",
            type: "email",
        };

        const response = await connectivityService().makeApiCallWithoutToken(
            "post",
            process.env.REACT_APP_LOGIN_URL,
            data
        );

        setLoading(false);

        if (response.code === 200 || response.code === 201) {
            setSessionInstitution({
                accessToken: response.data.token.access_token,
                shortCode: response.data.institution.shortCode,
                institutionName: response.data.institution.name,
                institutionLogo: response.data.institution.logoUri,
                institutionType: response.data.institution.institutionType,
                userId: response.data.user[0].userId,
            });

            navigate("/institution/home/users");
        } else {
            await connectivityService().handleErrors(response, notify);
        }
    };

    return (
        <MainSection>
            <Formik
                initialValues={loginData}
                onSubmit={handleSubmit}
                validationSchema={LoginSchema}
            >
                {(formik: FormikProps<LoginData>) => {
                    const toggle =
                        toggleButton<LoginData>(formik.values) &&
                        Object.keys(formik.errors).length === 0;
                    return (
                        <FormContent>
                            <Form style={FormStyle}>
                                <div style={{paddingBottom: '16px'}}>
                                    <LoginBackButton
                                        onClick={() => navigate("/institution")}
                                    >
                                        {" "}
                                        Back{" "}
                                    </LoginBackButton>
                                    <Heading>Hello! Welcome back</Heading>
                                    <FormBox>
                                        <Input
                                            title="Institution Admin email address"
                                            name="userName"
                                            type="text"
                                            placeholder="Enter email"
                                            required
                                        />

                                        <Input
                                            title="Password"
                                            name="password"
                                            type="password"
                                            placeholder="Password"
                                            required
                                        />

                                        <ForgotPassword>
                                            <Link
                                                to="/institution/forgot-password"
                                                style={{
                                                    color: "rgb(42, 107, 176)",
                                                    textDecoration:
                                                        "underline",
                                                }}
                                            >
                                                Forgot your password?
                                            </Link>
                                        </ForgotPassword>
                                    </FormBox>
                                </div>
                                <AuthPagesFooter
                                    note="Are you new? "
                                    link="Create a free account"
                                    to="/institution/sign-up"
                                    btn="Login"
                                    loading={loading}
                                    shouldDisable={!toggle}
                                />
                            </Form>
                        </FormContent>
                    );
                }}
            </Formik>
            <ToastContainer limit={1} />
        </MainSection>
    );
};

export default Login;
