
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: inline;
    // align-items: center;
    // justify-content: center;
    position: relative;
    // border: 1px solid black;
    width: fit-content;

    input {
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 0;
        position: absolute;
        left: 0;
        top:0;
        border: none;
        opacity: 0;
    }

`
interface ClickUploadProps {
    label: string;
    fileType: "img" | "file" | "csv" | "both";
    stateFunc: Dispatch<SetStateAction<File[]>>;
    prevState: File[];
    multiple: boolean;
    textStyle?: React.CSSProperties
}


const ACCEPTED_IMAGE_FORMATS = "image/png, image/jpeg, image/jpg, image/svg, image/svg-xml";
const ACCEPTED_CSV_FORMATS = ".csv, text/csv";
const ACCEPTED_DOC_FORMATS = "application/pdf,application/msword," +
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
const ACCEPTED_FILE_FORMATS = ACCEPTED_IMAGE_FORMATS + ACCEPTED_CSV_FORMATS + ACCEPTED_DOC_FORMATS;

const ClickUpload = (props: ClickUploadProps) => {

    const { label, stateFunc, prevState, fileType, multiple, textStyle } = props;

    const filesUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const uploadedFiles = e.target.files;
        if (multiple && uploadedFiles !== null) {
            const newState = Array.from(uploadedFiles as FileList);
            stateFunc([...newState, ...prevState]);
            return;
        } else if (!multiple && uploadedFiles !== null) {
            stateFunc(Array.from(uploadedFiles as FileList));
            return;
        }

    }

    return (
        <Container className="click-upload-group">
            <p style={textStyle}>{label}</p>
            <input style={{ zIndex: "9999999" }} type="file" name="file" id=""
                onChange={filesUpload}
                multiple={multiple}
                accept={fileType === "img" ? ACCEPTED_IMAGE_FORMATS : fileType === "file" ? ACCEPTED_DOC_FORMATS :
                    fileType === "csv" ? ACCEPTED_CSV_FORMATS : ACCEPTED_FILE_FORMATS} />
        </Container>
    )
}


export default ClickUpload
