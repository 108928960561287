import { useState } from 'react'
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { FormBox, FormStyle, Heading } from '../../../styles/generalStyles';
import Input from '../../../../components/Input';
import toggleButton from '../../../utils/toggleButton';
import { ResetPasswordSchema } from '../../ForgotPassword/ForgotPassword.validators';
import { ResetPasswordWrapper } from '../../ForgotPassword/ForgotPassword.style';
import Button from '../../../../components/Button';
import connectivityService from '../../../../service/ConnectivityService';
import { ToastContainer, toast } from 'react-toastify';
import { ToastTypes } from '../../../../components/DataTypes';
import { Id } from 'react-toastify';
import { useInstitutionSession } from '../../../context/InstitutionSessionContext';
import { useNavigate } from 'react-router-dom';

type ResetPasswordData = {
    password: string;
    confirmPassword: string;
}

interface ResetPasswordProps {
    notifyResetSuccess: () => Id,
    selectedUserId?: string,
    formClose: () => void
}


const ResetPassword = ({ notifyResetSuccess, formClose, selectedUserId }: ResetPasswordProps) => {
    const [resetPasswordData, setResetPasswordData] = useState<ResetPasswordData>({
        password: "",
        confirmPassword: ""
    });

    const [loading, setLoading] = useState<boolean>(false);
    const { sessionInstitution } = useInstitutionSession();
    const navigate = useNavigate();

    const notify = (type: ToastTypes, msg: string) => (
        toast(msg, { type: type, position: "top-center", theme: "colored" })
    );


    const handleSubmit = async (values: ResetPasswordData, actions: FormikHelpers<ResetPasswordData>) => {
        setLoading(true);

        setResetPasswordData(values);

        const code = sessionInstitution.shortCode;
        const adminId = sessionInstitution.userId;
        const url = `${process.env.REACT_APP_INST_URL}/${code}/idpUser/updatePassword`;
        const data = {
            "userId": selectedUserId,
            "newPassword": values.password,
            "adminId": adminId
        }
        const res = await connectivityService().makeApiCall("post", url, data, sessionInstitution.accessToken);

        setLoading(false);

        if (res.code === 200 || res.code === 201) {
            notifyResetSuccess();
            formClose();
            actions.resetForm({ values: { password: "", confirmPassword: "" } });
        }
        else {
            const error = await connectivityService().handleErrors(res, notify)
            if (error === "AccessDenied") {
                notify("error", "Login session expired. Please re-login.")
                navigate("/admin")
            }
        }
    }

    return (
        <>
            <Formik initialValues={resetPasswordData}
                onSubmit={handleSubmit}
                validationSchema={ResetPasswordSchema}
            >
                {
                    (formik: FormikProps<ResetPasswordData>) => {
                        const toggle = toggleButton<ResetPasswordData>(formik.values) &&
                            Object.keys(formik.errors).length === 0;
                        return (
                            <ResetPasswordWrapper style={{ marginTop: "-20px" }}>
                                <Form style={FormStyle}>
                                    <Heading>Reset Password</Heading>
                                    <FormBox style={{ gap: "42px" }}>
                                        <Input
                                            title="New password"
                                            name="password"
                                            type='password'
                                            placeholder='Password'
                                        />

                                        <Input
                                            title="Confirm password"
                                            name="confirmPassword"
                                            type='password'
                                            placeholder='Confirm Password'
                                        />

                                        <Button
                                            disabled={!toggle}
                                            loading={loading}
                                            children='Save'
                                        />
                                    </FormBox>
                                </Form>
                            </ResetPasswordWrapper>

                        )

                    }
                }
            </Formik>
            <ToastContainer />
        </>
    )
}

export default ResetPassword;

