import React, { useState } from 'react'
import connectivityService from '../../../../../service/ConnectivityService';
import ImageInput from '../../../../components/ImageInput';
import { useCreateAccount } from '../context/InstCreateAccountContext';
import { FormBox, Heading } from '../../CreateAccount.style';
import { FormStyle } from './BasicInformation';
import Footer from './Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DarkModal from '../../../../../components/DarkModal';
import AccountAlreadyExists from '../../../../modals/CreateAccount/AccountAlreadyExists';
import useHandleFileSizeErrors from '../../../../../hooks/useHandleFileSizeErrors';
import { useNavigate } from 'react-router-dom';

const DocumentUpload = () => {
    const [cacDocument, setCacDocument] = useState<File[]>([]);
    const [logo, setLogo] = useState<File[]>([]);
    const { createAccount } = useCreateAccount();
    const [showAccountExistsModal, setShowAccountExistsModal] = useState<boolean>(false);
    const [newRole, setNewRole] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const errors = useHandleFileSizeErrors([cacDocument[0], logo[0]]);
    const toggle = cacDocument.length > 0 && logo.length > 0 &&
        errors.every((error) => (Boolean(error) === false));

    type ToastTypes = 'info' | 'success' | 'warning' | 'error' | 'default';

    const notify = (type: ToastTypes, msg: string) => (
        toast(msg, { type: type, position: "top-center", theme: "colored" })
    );

    const navigate = useNavigate();

    const handleSubmitInner = async () => {
    const response = await createAccount(cacDocument, logo, newRole);
    const resp = connectivityService().handleErrors(response, notify);
    setNewRole(false);

    if (resp === "AccountExists") {
        setShowAccountExistsModal(true);
        return;
    }

    if (resp.status === "success" || resp.status === 200) {
        const email = resp.data.institution.contactEmail;
        setLoading(false);
        navigate("/institution/login", { state: { email } });
    }
}

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        setLoading(true);
        e.preventDefault();
        await handleSubmitInner();
    }

    return (
        <>
            <form style={FormStyle} onSubmit={handleSubmit}>
                <div style={{ padding: "0 40px 0 55px" }}>
                    <Heading>Institution Information</Heading>
                    <FormBox>
                        <ImageInput
                            title="Upload CAC document (accepts only pdf, jpeg, or png files)"
                            placeholder="Select a file"
                            fileType="file"
                            files={cacDocument}
                            setFiles={setCacDocument}
                        />
                        <p
                            style={{
                                transform: "translateY(-22px)",
                                fontSize: "14px",
                                color: "#ED1C24",
                                margin: 0,
                                padding: 0,
                            }}
                        >
                            {errors[0] ?? ""}
                        </p>

                        <ImageInput
                            title="Upload logo (accepts only png, jpg, or jpeg files)"
                            placeholder="Select a file"
                            fileType="img"
                            files={logo}
                            setFiles={setLogo}
                            required
                        />
                        <p
                            style={{
                                transform: "translateY(-22px)",
                                fontSize: "14px",
                                color: "#ED1C24",
                                margin: 0,
                                padding: 0,
                            }}
                        >
                            {errors[1] ?? ""}
                        </p>
                    </FormBox>
                </div>
                <Footer shouldDisable={!toggle} loading={loading} />
            </form>
            <ToastContainer limit={1} />
            {showAccountExistsModal && (
                <DarkModal>
                    <AccountAlreadyExists
                        handleDiscard={() => {
                            setShowAccountExistsModal(false);
                        }}
                        handleProceed={() => {
                            setNewRole(true);
                            handleSubmitInner();
                            setShowAccountExistsModal(false);
                        }}
                    />
                </DarkModal>
            )}
        </>
    );
}

export default DocumentUpload
