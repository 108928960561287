import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 32px;
    width: 290px;
    height: 255px;
    margin: auto;
    margin-top: 50px;
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 8px;

`

const Text = styled.p`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    width: 248px;
    color: #373737;


`
const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

`

const Button = styled.button`
    padding: 10px 8px;
    width: 121px;
    height: 50px;
    border-radius: 8px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

`
const DiscardBtn = styled(Button)`
    border: 1px solid #2A6BB0;
    background-color: #FFFFFF;
    color: #2A6BB0;
`

const ProceedBtn = styled(Button)`
    background-color: #2A6BB0;
    color: #FFFFFF;

`


const DecorImage = styled.img`


`

interface AccountAlreadyExistsProps {
    handleDiscard: () => void;
    handleProceed: () => void;

}

const AccountAlreadyExists = ({handleDiscard, handleProceed}:AccountAlreadyExistsProps) => {


    return (
        <Container>
            <DecorImage src="/assets/icons/featured-icon.svg"/>

            <Text>
                An account already exists for this user, do you want to proceed to create new role?
            </Text>
            <ButtonContainer>
                <DiscardBtn type="button" onClick={handleDiscard}>Discard</DiscardBtn>
                <ProceedBtn type="button" onClick={handleProceed}>Proceed</ProceedBtn>
            </ButtonContainer>
        </Container>
    )
}



export default AccountAlreadyExists;