import React, { useState } from 'react'
import Input from '../../../../../components/Input';
import { useCreateAccount } from '../context/InstCreateAccountContext'
import { FormBox, Heading } from '../../CreateAccount.style';
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import toggleButton from '../../../../utils/toggleButton';
import Footer from './Footer';
import { AdminInfoSchema } from '../InstCreateAccount.validators';
import { useNavigate } from 'react-router-dom';



export const FormStyle: React.CSSProperties = {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
}

const AdminUserInformation = () => {
    const { admin, subPages } = useCreateAccount();
    const [adminInfo, setAdminInfo] = admin;
    const [curSubPage, setCurSubPage] = subPages;
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();


    const handleSubmit = (values: typeof adminInfo, actions: FormikHelpers<typeof adminInfo>) => {
        setLoading(true);
        setAdminInfo(values);
        setCurSubPage(1);
        setLoading(false);
    }

    return (
        <Formik initialValues={adminInfo}
            onSubmit={handleSubmit}
            validationSchema={AdminInfoSchema}
        >
            {
                (formik: FormikProps<typeof adminInfo>) => {
                    const toggle = toggleButton<typeof adminInfo>(formik.values);
                    return (
                        <div style={{ height: "100%" }}>
                            <Form style={FormStyle}>
                                <div style={{ padding: "0 40px 0 55px" }}>
                                    <Heading >Hi, Let’s get you started</Heading>
                                    <FormBox>
                                        <Input
                                            title="First name"
                                            name="firstName"
                                            type='text'
                                            placeholder='Enter admin user first name'
                                            required
                                        />
                                        <Input
                                            title="Last name"
                                            name="lastName"
                                            type='text'
                                            placeholder='Enter admin user last name'
                                            required
                                        />
                                        <Input
                                            title="Password"
                                            name="password"
                                            type='password'
                                            placeholder='Enter password'
                                            required
                                        />
                                    </FormBox>
                                    <div
                                        style={{
                                            marginTop: "30px"
                                        }}>
                                        <p>Already have an account? <span style={{
                                            color: "#2A6BB0",
                                            cursor: "pointer",
                                            textDecoration: "underline"
                                        }} onClick={() => {
                                            navigate('/institution/login')
                                        }}>Sign-in</span></p>
                                    </div>
                                </div>

                                <Footer
                                    shouldDisable={!toggle}
                                    loading={loading} />
                            </Form>
                        </div>

                    )

                }
            }
        </Formik>
    )
}

export default AdminUserInformation
