import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import { useLocalStorage } from "../../../../../hooks/useLocalStorage";

export type subPagesType = 0 | 1 | 2 | 3;

type InviteSessionUser = {
    id: string | null,
    firstName: string | null,
    phoneNumber: string | null,
    shortCode: string | null | undefined
}


interface CreateAccountProviderProps {
    children: ReactNode;
}

type ContextType<T> = [T, Dispatch<SetStateAction<T>>];
interface CreateAccountContextType {
    inviteSessionUser: InviteSessionUser;
    setInviteSessionUser: Dispatch<SetStateAction<InviteSessionUser>>;
    subPages: ContextType<subPagesType>;
}


const CreateAccountContext = createContext({} as CreateAccountContextType);

export const useCreateAccount = () => {
    return useContext(CreateAccountContext);
}

export const CreateAccountProvider = ({ children }: CreateAccountProviderProps) => {
    const [curSubPage, setCurSubPage] = useState<subPagesType>(0);

    const [inviteSessionUser, setInviteSessionUser] = useLocalStorage<InviteSessionUser>("inviteSessionUser", {
        id: null,
        firstName: null,
        phoneNumber: null,
        shortCode: null
    });

 
    return(
        <CreateAccountContext.Provider value={{
            inviteSessionUser,
            setInviteSessionUser,
            subPages: [curSubPage, setCurSubPage],
        }}>
            {children}
        </CreateAccountContext.Provider>
    )
}