import {getCountries, getCountryCallingCode} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import {Formik, Form, FormikProps, ErrorMessage, useField} from "formik";
import Input from "react-phone-number-input/input";
import { useState } from 'react';
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    position: relative;
    width: 100%;
    gap: 4px;

    .label {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #4E4E4E; 
    }



`
const InputWrapper = styled.div`

    height: 50px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items:center;
    // padding:1px;
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;

    select, input {
        height: 50px;
        padding: 16px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #373737;
        border: none;
        background-color: transparent;
        outline: none;
    }

    select {
        border-right: 1px solid #E5E5E5;
    }


`

interface PhoneInputProps {
    name: string;
    title: string;
    placeholder: string;
    required?: boolean;
}

const PhoneInput = ({title,placeholder,required,...props}: PhoneInputProps) => {
    const countryCodeType = getCountries()[0];
    const Countries = Array.from(new Set(getCountries().map((c)=>(getCountryCallingCode(c)))));
    const [code, setCode] = useState<typeof countryCodeType>("NG");

    
    const [field, meta, helpers] = useField(props);

    return(
        <Container className = "phone-number-form-group" >
            <p className='label'>{title}<span style={{ marginLeft: "3px" }}>{required && "*"}</span></p>
            <InputWrapper style={{borderColor: meta.touched && meta.error ? "#ED1C24": meta.touched && !meta.error? "#2A6BB0" :"#E5E5E5"}}>
                <select name="select" id="" onChange = {
                    (e) => {
                        let newCode = getCountries().filter((h,ind) => (getCountryCallingCode(h) === e.target.value.slice(0)))[0];
                        setCode(newCode);
                    }
                    
                }
                style={{borderRightColor: meta.touched && meta.error ? "#ED1C24": meta.touched && !meta.error? "#2A6BB0" :"#E5E5E5"}}>
                    <option value={`+${getCountryCallingCode("NG")}`}>{`+${getCountryCallingCode("NG")}`}</option>
                    {
                        Countries.filter((c) => (c !== "234"))
                            .map((ctyCode,i) => {
                                return (
                                    <option key={i} value={ctyCode}>{`+${ctyCode}`}</option>
                                )
                        })
                    }
                </select>
                <Input
                    defaultCountry={'NG'}
                    country = {code}
                    international
                    placeholder = {placeholder}
                    {...field}
                    onChange = {(value) => {helpers.setValue(value)}}    
                />
            </InputWrapper>
            <ErrorMessage name={field.name} className = "error" component="div"/>
        </Container>
    )
}

export default PhoneInput




