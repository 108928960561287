import TableRow, { Row } from "../../../../components/TableRow";
import InviteActionMenus from "./InviteActionMenus";
import { ActionMenuLabel, Box, UserTableContainer } from "../../../../styles";
import {
    ListMenuType,
    InviteTopMenuType,
    TableHeaderInfo,
    MenuType,
    InviteDataType,
    UserTopMenuType,
} from "../../../types/DataTypes";
import ActionMenus from "./InviteActionMenus";

interface InviteTableProps {
    data: InviteDataType[];
    nullMessage: string;
    showPlusIcon: boolean;
    additionMessage?: string;
    tableHeader: Array<TableHeaderInfo>;
    addTableActionBtn: boolean;
    onActionClicked: (user: InviteDataType) => void;
    actionMenus: ListMenuType[] | InviteTopMenuType[];
    selectedInvite?: InviteDataType;
    onMenuClick: (m: MenuType) => void;
    topActionMenus: UserTopMenuType[] | InviteTopMenuType[];
    onTopMenuClick: (m: MenuType) => void;
    offset: number;
}

const InviteTable = ({
    data,
    nullMessage,
    showPlusIcon,
    additionMessage,
    addTableActionBtn,
    tableHeader,
    onActionClicked,
    actionMenus,
    selectedInvite,
    onMenuClick,
    topActionMenus,
    offset,
    onTopMenuClick,
}: InviteTableProps) => {
    const fetchFromObject: (obj: InviteDataType, prop?: string) => string = (
        obj,
        prop
    ) => {
        return obj[prop as keyof InviteDataType] || "";
    };

    const fillRowData = (
        item: InviteDataType,
        header: TableHeaderInfo,
        ind: number,
        key: number
    ) => {
        let Data;
        if (header.wrapper) {
            Data = (
                <header.wrapper
                    value={fetchFromObject(item, header.value)}
                ></header.wrapper>
            );
        } else if (ind === 0) {
            Data = offset + key + 1;
        } else {
            Data = `${fetchFromObject(item, header.value)}`;
        }
        return (
            <Row key={ind} className={`row row${ind}`}>
                <p>{Data || "N/A"}</p>
            </Row>
        );
    };

    return (
        <UserTableContainer>
            <TableRow className="table-head">
                {tableHeader.map((s, ind) => {
                    return (
                        <Row key={ind} className={`row row${ind}`}>
                            <p>{s.text}</p>
                        </Row>
                    );
                })}
                {addTableActionBtn && (
                    <Row className={`row row-last}`}>
                        <p>Action</p>
                    </Row>
                )}
            </TableRow>
            {data === undefined ? (
                <></>
            ) : data.length > 0 ? (
                data.map((item: InviteDataType, key: number) => {
                    return (
                        <TableRow className="table-row" key={key}>
                            {tableHeader.map((header, ind) => {
                                return fillRowData(item, header, ind, key);
                            })}
                            {addTableActionBtn && (
                                <div onClick={() => onActionClicked(item)}>
                                    <InviteActionMenus
                                        menus={actionMenus}
                                        selectedInvite={selectedInvite}
                                        item={item}
                                        onMenuClick={onMenuClick}
                                        activator={
                                            <ActionMenuLabel>
                                                <p>Action</p>
                                                <img
                                                    src="/assets/institution/icons/dropdown.svg"
                                                    alt=""
                                                />
                                            </ActionMenuLabel>
                                        }
                                        type="tableAction"
                                    />
                                </div>
                            )}
                        </TableRow>
                    );
                })
            ) : (
                <Box style={{ backgroundColor: "none" }}>
                    <div>
                        <p>{nullMessage}</p>
                        <ActionMenus
                            activator={
                                <>
                                    {showPlusIcon && (
                                        <p className="btn">
                                            <span>+</span>
                                            <span
                                                style={{
                                                    textDecoration: "underline",
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                {additionMessage}
                                            </span>
                                        </p>
                                    )}
                                </>
                            }
                            menus={topActionMenus}
                            onMenuClick={onTopMenuClick}
                            type="pageAction"
                        />
                    </div>
                </Box>
            )}
        </UserTableContainer>
    );
};

export default InviteTable;
