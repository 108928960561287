import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    position: relative;
    width: 100%;
    gap: 4px;

    select{
        height: 50px;
        width: 100%;
        border-radius: 4px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #373737;
        padding: 13px;
        background-color: #FFFFFF;
        border: 1px solid #E5E5E5;
        outline: none;
    
    } 

    label {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #4E4E4E;
    }

    .star{
        position: absolute;
        bottom: 16px;
        left: 10px;
        pointer-events: none;
        color: grey;
        transition: .5s;
        transform: translateY(-20px);
        font-size: 12px;
    }   

`

interface SelectProps {
    title?: string;
    name: string;
    placeholder?: string;
    options: string[];
    inner?: boolean;
    required?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>, s?: string) => void;

}

const Select = (props: SelectProps) =>{
    const { title, name, placeholder, options, inner, required, onChange} = props;
    return(
        <Container className='form-group'>
            <label htmlFor={name}>{inner? "" : title}<span style={{marginLeft: "3px"}}>{required && "*"}</span></label>
            <select
                style={{backgroundColor: "#E5E5E5"}}
                id={name}
                onChange={onChange}
                className= "form-select">


                    {placeholder && <option value="" disabled selected hidden>{placeholder}</option>}
                    {options.map((value)=>{
                        return(
                            <option key={value} value={value} label={value}>{value}</option>
                        )
                    })}

            </select>
        </Container>
    )
}

export default Select;