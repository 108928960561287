import React from "react";
import { Link } from "react-router-dom";

const EmailInvite = () => {
    return(
        <div id="email-invite">
            <p>
                Hi Sola, <br />
                You have been invited by the Admin. <br />
                The platform will enable you feep track of reports made by the public and come in where needed. <br />
                Kindly use the link below to accept your invitation.
            </p>

            <div className="invitation-button">
                <Link to={"/accepted"}>Accept Invitation</Link>
            </div>

            <p>
                If you’re having trouble clicking the accept invitation button, copy and paste the URL below into your web browser.
            </p>

            <Link className="alternative-invite" to={"acc"}>http://onetoonetelemedicine.com/accept-invitation/12345678</Link>
        </div>
    );
}

export default EmailInvite;