import React, { useState } from 'react';
import styled from 'styled-components';
import { useField, ErrorMessage } from "formik";
import { P } from '../institution/styles/generalStyles';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    position: relative;
    width: 100%;
    gap: 4px;

    input{
        height: 50px;
        width: 100%;
        border-radius: 4px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        // text-decoration-line: underline;
        color: #373737;
        padding: 16px;
        background-color: #FFFFFF;
        border: 1px solid #E5E5E5;
        outline: none;
        
    }

    label {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #4E4E4E;
    }


    .star{
        position: absolute;
        bottom: 16px;
        left: 10px;
        pointer-events: none;
        color: grey;
        transition: .5s;
        transform: translateY(-20px);
        font-size: 12px;
    }

    .input-blue-border{
        background: #FFFFFF;
        border: 1px solid #2A6BB0 !important;
        border-radius: 4px;
    }
`

const TogglePassword = styled(P)`
    position: absolute;
    bottom: 10px;
    right: 10px;
    transition: .5s;
    cursor: pointer;
    // border: 1px solid red;
`

export interface InputProps {
    title?: string;
    type: string;
    name: string;
    placeholder?: string;
    max?: number;
    inner?: boolean;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    fileFormat?: string;
    tempOnChange?: React.ChangeEventHandler<HTMLInputElement>;
    tempOnKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
    value?: string;
    forceError?: boolean
}

const Input = (props: InputProps) => {
    const [field, meta, helpers] = useField(props);
    const { title, type, placeholder, max, inner, required, disabled,
        className, fileFormat, tempOnChange, value, tempOnKeyPress, forceError } = props;
    const [passType, setPassType] = useState<string>("password");

    const togglePassword = () => {
        passType === "password" ? setPassType("text") : setPassType("password");

    }
    return (
        <Container className='form-group'>
            <label htmlFor={field.name}>{inner ? "" : title}<span style={{ marginLeft: "3px" }}>{required && "*"}</span></label>
            <input
                style={{
                    borderColor: meta.touched && meta.error ? "#ED1C24" : forceError ? "#ED1C24" :
                        meta.touched && !meta.error ? "#2A6BB0" : "#E5E5E5"
                }}
                className={`form-input ${className}`}
                type={type === "password" ? passType : type}
                placeholder={placeholder}
                {...field}
                onChange={
                    tempOnChange ? tempOnChange : (e) => { helpers.setValue(e.target.value) }
                }
                onKeyPress={
                    tempOnKeyPress ? tempOnKeyPress : (e) => { helpers.setValue(e.target.value) }
                }
                value={value ? value : field.value}
                min={type === "number" ? 0 : undefined}
                max={max}
                disabled={disabled}
                accept={type === "file" ? fileFormat ? fileFormat : "image/png, image/jpeg, image/jpg" : undefined}
            />
            {
                type === "password" &&
                <TogglePassword
                    onClick={togglePassword}
                    style={{ color: passType === "text" ? "#2A6BB0" : "#000" }}>
                    Show
                </TogglePassword>
            }
            {
                inner &&
                <p className='star'>{title}</p>
            }
            <ErrorMessage name={field.name} className="error" component="div" />
        </Container>
    )
}

export default Input;