import { TableHeaderInfo } from "./DataTypes";

const StatusWrapper = (props: TableHeaderInfo) => {
    const stringFormat = (value: string) => {
        const lowerCaseValue = value.toLocaleLowerCase();
        return `${lowerCaseValue.charAt(0).toUpperCase()}${lowerCaseValue.slice(1)}`;
    }
    return (
        <span
            className={props.value.toLowerCase() === "active" || props.value.toLowerCase() === "approved"
            || props.value.toLowerCase() === "accepted" ? "active"
                : props.value.toLowerCase() === "pending" ? "pending"
                    : "inactive"}>
            {stringFormat(props.value)}
        </span>
    )
};

export default StatusWrapper;
