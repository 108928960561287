import { Routes, Route } from "react-router-dom";
import styled from "styled-components";
import LeftSideBar from "./components/LeftSideBar";
import CreateAccount from "./pages/CreateAccount";
import InstitutionSignUp from "./pages/CreateAccount/Institution";
import DoctorSignUp from "./pages/CreateAccount/Doctor";
import PatientSignUp from "./pages/CreateAccount/Patient";
import Login from "./pages/Login";
import { ForgotPassword, OTPForm, ResetPassword } from "./pages/ForgotPassword";
import GlobalStyles from "./styles/GlobalStyles";
import Home from "./pages/Home";
import { InstitutionSessionProvider } from "./context/InstitutionSessionContext";

const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 100vw;
    position: relative;
`;
function InstitutionApp() {
    return (
        <Container>
            <InstitutionSessionProvider>
                <GlobalStyles />
                <Routes>
                    <Route element={<LeftSideBar />}>
                        <Route path="/" element={<CreateAccount />} />
                        <Route
                            path="/sign-up"
                            element={<InstitutionSignUp />}
                        />
                        <Route
                            path="/doctor-sign-up"
                            element={<DoctorSignUp />}
                        />
                        <Route
                            path="/patient-sign-up"
                            element={<PatientSignUp />}
                        />
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/forgot-password"
                            element={<ForgotPassword />}
                        />
                        <Route path="/verify-otp" element={<OTPForm />} />
                        <Route
                            path="/reset-password"
                            element={<ResetPassword />}
                        />
                    </Route>
                    <Route path="/home/*" element={<Home />} />
                </Routes>
            </InstitutionSessionProvider>
        </Container>
    );
}

export default InstitutionApp;
