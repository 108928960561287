import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import connectivityService from '../service/ConnectivityService';



const InstitutionInviteRedirect = () => {

    const {referralCode} = useParams();
    const navigate = useNavigate();


    type ToastTypes = 'info' | 'success' | 'warning' | 'error' | 'default';

    const notify = (type:ToastTypes , msg: string) => (
        toast(msg, {type: type, position: "top-center", theme: "colored"})
    )


    useEffect(() => {

      const getUserInvite = async () => {

        const url = `${process.env.REACT_APP_GET_INVITE_URL}/${referralCode}`;

        const response = await connectivityService()
          .makeApiCallWithoutToken("get", url);
        
        const data = connectivityService().handleErrorsReturnData(response, notify);

        if (data) {

          if (data?.userRole === "patient") {

            navigate("/institution/patient-sign-up", {state: {
              email: data?.email,
              phoneNumber: data?.phoneNumber,
              referralCode: data?.invitationCode,
              role: data?.userRole,
              shortCode: data?.shortCode
            }})

          } else if (data?.userRole === "doctor") {

            navigate("/institution/doctor-sign-up", {state: {
              email: data?.email,
              phoneNumber: data?.phoneNumber,
              referralCode: data?.invitationCode,
              role: data?.userRole,
              shortCode: data?.shortCode
            }})
            
          } else if (data?.userRole === "admin") {

            console.log("user is admin");
            
          }

        }

        // navigate("/institution/doctor-sign-up", {state: {
        //   email: "valentineorabueze@gmail.com",
        //   phoneNumber: "+2347082983945",
        //   referralCode: "c0064a04",
        //   role: "doctor"
        // }});

        // navigate("/institution/doctor-sign-up", {state: {
        //   email: "valentineorabueze@gmail.com",
        //   phoneNumber: "+2347080937287",
        //   referralCode: "datainvitationCode",
        //   role: "datauserRole"
        // }});
        


      }

      getUserInvite();
    }, [referralCode])

  return (
    <div>
        <ToastContainer limit={1}/>
    </div>
  )
}

export default InstitutionInviteRedirect
