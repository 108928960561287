import { Routes, Route, useNavigate } from "react-router-dom";
import styled from "styled-components";
import LeftSideBarHome from '../../components/LeftSideBarHome';
import Invites from '../Invites';
import BulkInvitesList from '../Invites/templates/BulkInvitesList';
import BulkUsersList from "../ManageUsers/templates/BulkUsersList";
import Users from '../ManageUsers';
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";
import { useInstitutionSession } from "../../context/InstitutionSessionContext";

const Container = styled.div`
    display: flex;
    width: 100vw;

`


const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { sessionInstitution } = useInstitutionSession();

  const institutionName = sessionInstitution.institutionName;
  const shortCode = sessionInstitution.shortCode;

  useEffect(() => {
    if (!institutionName || !shortCode) {
      navigate('/institution/login');
    }
  }, [institutionName, navigate, shortCode]);
  return (
    <Container>
      {
        institutionName && shortCode ?
          <>
              {location.pathname !== '/institution/home/invites/list' &&
                location.pathname !== '/institution/home/users/list' && <LeftSideBarHome />}
            <div style={{ flex: 5 }}>
              <Routes>
                <Route path='/' element={<div>Hello</div>} />
                <Route path='/invites' element={<Invites />} />
                <Route path='/invites/list' element={<BulkInvitesList />} />
                <Route path='/users/list' element={<BulkUsersList />} />
                <Route path='/users' element={<Users />} />
              </Routes>
            </div>
          </> :
          <></>
      }
    </Container>
  )
}

export default Home
