import styled from 'styled-components';

const Form = styled.form`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 16px;
    gap: 16px;
    width: 509px;
    height: 49px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 8px;

    @media (max-width: 850px) {
      width: 400px !important;
    }

    @media (max-width: 480px) {
      width: 400px !important;
    }

  img {
    position: absolute;
    top: 17px;
    right: 10px;
  }
`;
const FormInput = styled.input`
  width: 100%;
  outline: none;
  border: none;
  height: 100%;

`;

interface SearchBarProps {
    className?: string,
    handleChange: (e:React.ChangeEvent<HTMLInputElement>)=>void,
    inputValue: string,
    placeHolder: string,
    handleSubmit: (e:React.FormEvent<HTMLFormElement>) => void
}


const SearchBar = ({className, handleChange, inputValue, placeHolder, handleSubmit}:SearchBarProps) => {
  

  return (
    <Form className={className} onSubmit={handleSubmit}>
      <FormInput
        onChange={handleChange}
        placeholder={placeHolder}
        value={inputValue}
      />
      <img src="/assets/institution/icons/search-icon.svg" alt="search" />
    </Form>
  );
};

export default SearchBar;
