import { useState } from 'react';
import styled from 'styled-components';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';

const Styles = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 20px 30px;

    button{
        width: 80%;
    }

    img{
        margin-bottom: 74px;
    }

    p{
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        font-style: normal;
        margin-bottom: 51px;
    }

    .buttons{
        gap: 32px;
        display: flex;
        flex-direction: column;
        width: 407px;

        @media (max-width: 1024px) {
            width: auto;
        }
    }

    .favicon{
        width: 190px;
        height: 143px;
            @media (max-width: 1024px) {
                width: 153px;
                height: 115px;
            }
    }
`

const Home = () => {
    const [patientLoading, setPatientLoading] = useState<boolean>(false);
    const [doctorLoading, setDoctorLoading] = useState<boolean>(false);
    const [institutionLoading, setInstitutionLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const handlePatientClick = () => {
        navigate('/institution/patient-sign-up');
    }
    const handleDoctorClick = () => {
        setDoctorLoading(true);
        navigate('/institution/doctor-sign-up');
    }
    const handleInstitutionClick = () => {
        setInstitutionLoading(true);
        navigate('/institution/sign-up');
    }

    return (
        <Styles>
            <img className="favicon" src="/favicon.png" alt="one-2-one icon" />
            <p>Sign up now to get access to our healthcare services</p>
            <div className="buttons">
                <Button
                    onClick={handlePatientClick}
                    loading={patientLoading}
                    children="Sign up as a Patient"
                ></Button>
                <Button
                    onClick={handleDoctorClick}
                    loading={doctorLoading}
                    children="Sign up as a Doctor"
                ></Button>
                <Button
                    onClick={handleInstitutionClick}
                    loading={institutionLoading}
                    children="Sign up/Sign in as an Institution"
                ></Button>
                <Button onClick={() => navigate("/")}>
                    Back to Home
                </Button>
            </div>
        </Styles>
    );
}

export default Home;
