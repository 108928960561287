import * as yup from 'yup';

export const SingleUserSchema = yup.object({
    email: yup
        .string()
        .trim()
        .email('This is not a valid email')
        .required('This field is required')
});

export const SingleIDPUserSchema = yup.object({
    firstName: yup
        .string()
        .trim()
        .required('This field is required'),
    lastName: yup
        .string()
        .trim()
        .required('This field is required'),
    dob: yup
        .string()
        .trim()
        .required('This field is required'),
    gender: yup
        .string()
        .trim()
        .required('This field is required')
});

export const BulkUserSchema = yup.object({
    file: yup
        .mixed()
        .required('File is required')
});