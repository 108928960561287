import TableRow, { Row } from '../../../../components/TableRow';
import PriceActionMenus from './PriceActionMenus';
import { Box, UserTableContainer } from "../../../../styles";
import {
    PriceListMenuType, TableHeaderInfo, MenuType, PriceDataType,
} from '../../../types/DataTypes';

interface PriceTableProps {
    data: PriceDataType[]
    nullMessage: string
    showPlusIcon: boolean
    additionMessage?: string
    tableHeader: Array<TableHeaderInfo>
    addTableActionBtn: boolean
    onActionClicked: (price: PriceDataType) => void
    actionMenus: PriceListMenuType[]
    selectedPrice?: PriceDataType
    onMenuClick: (m: MenuType) => void
    onAddClick: () => void
    offset: number,
    productName: string,
    productUnit: string
}

const PriceTable = ({ data, nullMessage, showPlusIcon, additionMessage, addTableActionBtn, tableHeader,
    onActionClicked, actionMenus, selectedPrice, onMenuClick, offset, onAddClick, productName, productUnit }: PriceTableProps) => {
    const fetchFromObject: (obj: PriceDataType, prop?: string) =>
        string = (obj, prop) => {
            return obj[prop as keyof PriceDataType] || ''
        }

    const fillRowData = (item: PriceDataType, header: TableHeaderInfo, ind: number, key: number) => {
        let Data
        if (header.wrapper) {
            Data = (
                <header.wrapper
                    value={fetchFromObject(item, header.value)}
                ></header.wrapper>
            )
        }
        else if (ind === 0) {
            Data = offset + key + 1
        }
        else {
            Data = header.value === "productName"? productName : header.value === "volume"
            ? `${fetchFromObject(item, header.value)} ${productUnit}` :
            `${fetchFromObject(item, header.value)}`
        }
        return (
            <Row key={ind} className={`row row${ind}`}>
                <p
                >
                    {Data || 'N/A'}
                </p>
            </Row>
        )
    }

    return (
        <UserTableContainer>
            <TableRow className='table-head'>
                {
                    tableHeader.map((s, ind) => {
                        return (
                            <Row key={ind} className={`row row${ind}`}>
                                <p>{s.text}</p>
                                {/* {
                                    ind !== 0 &&
                                    <img src="/assets/institution/icons/sort-icon.svg" alt="" />
                                } */}
                            </Row>
                        )
                    })

                }
                {
                    addTableActionBtn && (
                        <Row className={`row row-last}`}>
                            <p>Action</p>
                            {/* <img src="/assets/institution/icons/sort-icon.svg" alt="" /> */}
                        </Row>
                    )
                }
            </TableRow>
            {
                data === undefined ?
                    <></> :
                    data.length > 0 ?
                        data.map((item: PriceDataType, key: number) => {
                            return (
                                <TableRow className='table-row' key={key}>
                                    {
                                        tableHeader.map((header, ind) => {
                                            return fillRowData(item, header, ind, key)
                                        })
                                    }
                                    {addTableActionBtn &&
                                        <div onClick={() => onActionClicked(item)}>
                                            <PriceActionMenus
                                                menus={actionMenus}
                                                selectedPrice={selectedPrice}
                                                item={item}
                                                onMenuClick={onMenuClick}
                                                activator={
                                                    <>
                                                        <p>Action</p>
                                                        <img src="/assets/institution/icons/dropdown.svg" alt="" />
                                                    </>
                                                }
                                                type="tableAction"
                                            />
                                        </div>
                                    }
                                </TableRow>
                            )
                        })
                        :
                        <Box style={{ backgroundColor: "none" }}>
                            <div>
                                <p>{nullMessage}</p>
                                <div onClick={onAddClick}>
                                    {showPlusIcon &&
                                    <p className='btn'><span>+</span><span style={{ textDecoration: "underline", marginLeft: "10px" }}>{additionMessage}</span></p>
                                    }
                                </div>
                            </div>
                        </Box>
            }

        </UserTableContainer >
    )
}

export default PriceTable
