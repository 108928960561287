import { useState } from "react";
import Input from "../../../../../components/Input";
import { useCreateAccount } from "../context/PatientCreateAccountContext";
import {
    FormBox,
    FormStyle,
    Heading,
    TwoColumnInputFlex,
} from "../../../../styles/generalStyles";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { GeneralInfoSchema } from "../PatientCreateAccount.validators";
import styled from "styled-components";
import Select from "../../../../../components/Select";
import { Link } from "react-router-dom";
import Button from "../../../../../components/Button";
import { toast, ToastContainer } from "react-toastify";
import connectivityService from "../../../../../service/ConnectivityService";
import DarkModal from "../../../../../components/DarkModal";
import AccountAlreadyExists from "../../../../modals/CreateAccount/AccountAlreadyExists";
import PhoneInput from "../../../../components/PhoneInput";

const Btn = styled.button`
    height: 50px;
    border-radius: 8px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    outline: none;
    border: none;
    margin-top: 32px;
    width: 100%;
`;
const Container = styled.div`
    padding: 108px 96px 108px 111px;

    @media (max-width: 768px) {
        padding: 80px 0 48px 45px;
    }
`;

const Note = styled.div`
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #373737;
    text-align: center;
    margin-top: 8px;
`;

export type InviteDataType = {
    email: string;
    phoneNumber: string;
    referralCode: string;
    shortCode: string;
    role: string;
};

interface GeneralInfoProps {
    inviteData: null | undefined | InviteDataType;
}

const GeneralInfo = ({ inviteData }: GeneralInfoProps) => {
    const { subPages, setInviteSessionUser } = useCreateAccount();
    const [curSubPage, setCurSubPage] = subPages;
    const [loading, setLoading] = useState<boolean>(false);
    const [showAccountExistsModal, setShowAccountExistsModal] =
        useState<boolean>(false);
    const [newRole, setNewRole] = useState<boolean>(false);

    let doctorInfo = {
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        gender: "",
        dob: "",
        password: "",
        referralCode: "",
    };

    if (inviteData) {
        doctorInfo = {
            ...doctorInfo,
            emailAddress: inviteData.email,
            phoneNumber: inviteData.phoneNumber,
            referralCode: inviteData.referralCode,
        };
    }

    const handleSubmit = async (
        values: typeof doctorInfo,
        actions: FormikHelpers<typeof doctorInfo>
    ) => {
        setLoading(true);
        type ToastTypes = "info" | "success" | "warning" | "error" | "default";

        const notify = (type: ToastTypes, msg: string) =>
            toast(msg, {
                type: type,
                position: "top-center",
                theme: "colored",
            });

        const url = `${process.env.REACT_APP_USER_ROOT_URL}`;

        const payload = {
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            email: values.emailAddress,
            gender:
                values.gender === "Male"
                    ? "M"
                    : values.gender === "Female"
                    ? "F"
                    : "N",
            phoneNumber: values.phoneNumber,
            dateOfBirth: values.dob,
            role: "patient",
            referralCode: values.referralCode,
            status: "Active",
            newRole,
        };

        const response = await connectivityService().makeApiCallWithoutToken(
            "post",
            url,
            payload
        );

        const data = connectivityService().handleErrorsReturnData(
            response,
            notify
        );

        if (data) {
            if (data === "AccountExists") {
                setShowAccountExistsModal(true);
            } else {
                setInviteSessionUser({
                    id: data?.user?.userId,
                    firstName: data?.user?.firstName,
                    phoneNumber: data?.user?.phoneNumber,
                    shortCode: inviteData?.shortCode,
                });
                setCurSubPage(1);
            }
        }
        setNewRole(false);
        setLoading(false);
    };

    return (
        <Formik
            initialValues={doctorInfo}
            onSubmit={handleSubmit}
            validationSchema={GeneralInfoSchema}
        >
            {(formik: FormikProps<typeof doctorInfo>) => {
                const toggle = Object.keys(formik.errors).length === 0;
                return (
                    <div>
                        <Form style={FormStyle}>
                            <TwoColumnInputFlex>
                                <Container>
                                    <Heading>Let's get you started</Heading>
                                    <FormBox>
                                        <div className="flex">
                                            <Input
                                                title="First name"
                                                name="firstName"
                                                type="text"
                                                placeholder="First Name"
                                                required
                                            />
                                            <Input
                                                title="Last name"
                                                name="lastName"
                                                type="text"
                                                placeholder="Last Name"
                                                required
                                            />
                                        </div>
                                        <div className="flex">
                                            {Boolean(inviteData) ? (
                                                <Input
                                                    title="Phone number"
                                                    name="phoneNumber"
                                                    type="text"
                                                    placeholder="Phone Number"
                                                    disabled={Boolean(
                                                        inviteData
                                                    )}
                                                    required
                                                />
                                            ) : (
                                                <PhoneInput
                                                    title="Phone number"
                                                    name="phoneNumber"
                                                    placeholder="Phone Number"
                                                    required
                                                />
                                            )}
                                            <Input
                                                title="Email Address"
                                                name="emailAddress"
                                                type="text"
                                                placeholder="Email Address"
                                                disabled={Boolean(inviteData)}
                                                required
                                            />
                                        </div>

                                        <div className="flex">
                                            <Select
                                                title="Gender"
                                                name="gender"
                                                options={["Male", "Female"]}
                                                placeholder="Select gender"
                                                required
                                            />
                                            <Input
                                                title="Date of Birth"
                                                name="dob"
                                                type="date"
                                                placeholder="Select Date"
                                                required
                                            />
                                        </div>

                                        <Input
                                            title=" Create Password"
                                            name="password"
                                            type="password"
                                            placeholder="Enter password"
                                            required
                                        />

                                        <Input
                                            title=" Referral code(Optional)"
                                            name="referralCode"
                                            type="text"
                                            disabled={Boolean(inviteData)}
                                            placeholder="Enter referral code"
                                        />
                                    </FormBox>
                                    <Btn>
                                        <Button
                                            type="submit"
                                            disabled={!toggle}
                                            loading={loading}
                                            children="Verify"
                                        />
                                    </Btn>
                                    <Note>
                                        Are you an existing user?
                                        <Link
                                            to="/"
                                            style={{
                                                color: "#2A6BB0",
                                                marginLeft: "8px",
                                            }}
                                        >
                                            Log in to your account?
                                        </Link>
                                    </Note>
                                    <Note style={{ marginTop: "24px" }}>
                                        Download the Patient app on your mobile
                                        device to get full access
                                    </Note>
                                    <div
                                        className="flex"
                                        style={{ marginTop: "16px" }}
                                    >
                                        <img
                                            src="/assets/institution/icons/app-store-btn.svg"
                                            alt="apple-store-button"
                                        />
                                        <img
                                            src="/assets/institution/icons/google-store-btn.svg"
                                            alt="google-play-store-button"
                                        />
                                    </div>
                                </Container>
                            </TwoColumnInputFlex>
                        </Form>
                        <ToastContainer limit={1} />
                        {showAccountExistsModal && (
                            <DarkModal>
                                <AccountAlreadyExists
                                    handleDiscard={() => {
                                        setShowAccountExistsModal(false);
                                    }}
                                    handleProceed={() => {
                                        setNewRole(true);
                                        formik.submitForm();
                                        setShowAccountExistsModal(false);
                                    }}
                                />
                            </DarkModal>
                        )}
                    </div>
                );
            }}
        </Formik>
    );
};

export default GeneralInfo;
