import moment from 'moment';
import * as yup from 'yup';



export const GeneralInfoSchema = yup.object({
    firstName: yup
        .string()
        .trim()
        .min(3, "First name should be up to 3 characters")
        .required("This field is required"),
    lastName: yup
        .string()
        .trim()
        .min(3, "Last name should be up to 3 characters")
        .required("This field is required"),
    emailAddress: yup
        .string()
        .trim()
        .email('This is not a valid email')
        .required('Email field is empty'),
    phoneNumber: yup
        .string()
        .trim()
        .min(8, "Phone Number should be up to 8 characters")
        .required("This field is required"),
    gender: yup
        .string()
        .required("This field is required"),
    dob: yup
        .date()
        .required("This field is required")
        .test(
            "DOB",
            "You must be up to 16 years to proceed",
            value => {
                return moment().diff(moment(value),'years') >= 16;
            }
        ),
    password: yup
        .string()
        .trim()
        .required("This field is required")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            "Must contain 8 characters, one uppercase, one lower case, one number and one special case character."
        ),
    referralCode: yup
        .string()
        .nullable()
        .min(3, "Referral code should be more than 3 characters")
});

export const ProfessionalInfoSchema = yup.object({
    licenseNumber: yup
        .string()
        .trim()
        .min(3, "License number should be up to 3 characters")
        .required("This field is required"),
    country: yup
        .string()
        .trim()
        .min(1, "This field is required")
        .required("This field is required"),
    specialty: yup
        .string()
        .trim()
        .min(1, "This field is required")
        .required("This field is required"),
    language: yup
        .string()
        .trim()
        .min(1, "This field is required")
        .required("This field is required"),
    yearsOfExperience: yup
        .string()
        .trim()
        .required("This field is required"),
    about: yup
        .string()
        .trim()
        .required("This field is required")
});

