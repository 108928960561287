import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { FormBox, Heading } from "../../styles/generalStyles";
import Input from "../../../components/Input";
import toggleButton from "../../../institution/utils/toggleButton";
import { LoginSchema } from "./Login.validators";
import { ForgotPassword } from "./Login.style";
import { Link } from "react-router-dom";
import styled from "styled-components";
import connectivityService from "../../../service/ConnectivityService";
import { ToastContainer, toast } from "react-toastify";
import { ToastTypes } from "../../../components/DataTypes";
import Button from "../../../components/Button";
import { useLocation } from "react-router-dom";
import { useAdminSession } from "../../context/AdminSessionContext";

const FormStyle: React.CSSProperties = {
    height: "100%",
    position: "relative",
};

const Styles = styled.div`
    padding: 42px 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    @media (max-width: 1024px) {
        padding: 100px 30px 100px 30px;
    }
`;

const Body = styled.div`
    padding: 20px 20px 26px;
    width: 100%;
    @media (max-width: 1024px) {
        padding: 20px;
    }
`;

const MainSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 100vh;
    width: 100vw;
`;

type LoginData = {
    userName: string;
    password: string;
};

type LocationState = {
    email: string;
    password: string;
    notifyCreationSuccess: boolean;
    notifyPasswordReset: boolean;
};

const Login = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { setSessionAdmin } = useAdminSession();

    const notify = (type: ToastTypes, msg: string) =>
        toast(msg, { type: type, position: "top-center", theme: "colored" });

    let loginData = {
        userName: "",
        password: "",
    };

    if (location?.state) {
        loginData = {
            userName: (location?.state as LocationState).email,
            password: (location?.state as LocationState).password,
        };
    }

    useEffect(() => {
        if ((location.state as LocationState)?.notifyCreationSuccess)
            notify("success", "Registration successful. Proceed to Login");
        if ((location.state as LocationState)?.notifyPasswordReset)
            notify("success", "Password reset was successful");
    }, [location.state]);

    const handleSubmit = async (
        values: LoginData,
        actions: FormikHelpers<LoginData>
    ) => {
        setLoading(true);
        const data = {
            userName: values.userName,
            password: values.password,
            role: "super-admin",
            type: "email",
        };

        const response = await connectivityService().makeApiCallWithoutToken(
            "post",
            process.env.REACT_APP_LOGIN_URL,
            data
        );

        setLoading(false);

        if (response.code === 200 || response.code === 201) {
            if (response.data.user.length === 0) {
                notify(
                    "error",
                    "Sorry, something went wrong. Please try again."
                );
                return;
            }

            if (response.data.user.length !== 0) {
                setSessionAdmin({
                    userId: response.data.user.userId,
                    accessToken: response.data.token.access_token,
                    adminImage: response.data.user.userImgUri,
                    adminName: response.data.user.firstName,
                });
                navigate("/admin/home/users");
            }
        } else {
            await connectivityService().handleErrors(response, notify);
        }
    };

    return (
        <MainSection>
            <Formik
                initialValues={loginData}
                onSubmit={handleSubmit}
                validationSchema={LoginSchema}
            >
                {(formik: FormikProps<LoginData>) => {
                    const toggle =
                        toggleButton<LoginData>(formik.values) &&
                        Object.keys(formik.errors).length === 0;
                    return (
                        <Body>
                            <Form style={FormStyle}>
                                <Styles>
                                    <Heading>Sign into your account</Heading>
                                    <FormBox>
                                        <Input
                                            title="Email address"
                                            name="userName"
                                            type="text"
                                            placeholder="Enter email"
                                            disabled={Boolean(location?.state)}
                                            required
                                        />

                                        <Input
                                            title="Password"
                                            name="password"
                                            type="password"
                                            placeholder="Password"
                                            disabled={Boolean(location?.state)}
                                            required
                                        />

                                        <ForgotPassword
                                            style={{ marginBottom: "24px" }}
                                        >
                                            <Link
                                                to="/admin/forgot-password"
                                                style={{
                                                    color: "rgb(42, 107, 176)",
                                                    textDecoration: "underline",
                                                }}
                                            >
                                                Forgot your password?
                                            </Link>
                                        </ForgotPassword>
                                    </FormBox>
                                    <Button
                                        type="submit"
                                        disabled={!toggle}
                                        loading={loading}
                                        children="Login"
                                    />
                                    <Button onClick={() => navigate("/")}>
                                        Back to Home
                                    </Button>
                                </Styles>
                            </Form>
                        </Body>
                    );
                }}
            </Formik>
            <ToastContainer limit={1} />
        </MainSection>
    );
};

export default Login;
