import React from 'react';
import styled from 'styled-components';
import { useField, ErrorMessage } from "formik";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    position: relative;
    width: 100%;
    gap: 4px;

    select{
        height: 50px;
        width: 100%;
        border-radius: 4px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #373737;
        padding: 13px;
        background-color: #FFFFFF;
        border: 1px solid #E5E5E5;
        outline: none;
        cursor: pointer;
    } 

    label {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #4E4E4E;
    }

    .star{
        position: absolute;
        bottom: 16px;
        left: 10px;
        pointer-events: none;
        color: grey;
        transition: .5s;
        transform: translateY(-20px);
        font-size: 12px;
    }   

`

interface SelectProps {
    title?: string;
    name: string;
    placeholder?: string;
    options: string[];
    inner?: boolean;
    required?: boolean;
    disabled?: boolean;
    tempOnChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    forceError?: boolean;
}

const Select = (props: SelectProps) => {
    const [field, meta, helpers] = useField(props);
    const { title, placeholder, options, inner, required, tempOnChange, disabled, forceError} = props;
    return (
        <Container className='form-group'>
            <label htmlFor={field.name}>{inner ? "" : title}<span style={{ marginLeft: "3px" }}>{required && "*"}</span></label>
            <select
                style={{ borderColor: meta.touched && meta.error ? "#ED1C24" : forceError ? "#ED1C24" : meta.touched && !meta.error ? "#2A6BB0" :"#E5E5E5" }}
                id={field.name}
                {...field}
                onChange={
                    tempOnChange ? tempOnChange : (e) => { helpers.setValue(e.target.value) }
                }
                className="form-select" disabled={disabled}>


                {placeholder && <option value="" disabled selected hidden>{placeholder}</option>}
                {options.map((value) => {
                    return (
                        <option key={value} value={value} label={value}>{value}</option>
                    )
                })}

            </select>
            {
                inner &&
                <p className='star'>{title}</p>
            }
            <ErrorMessage name={field.name} className="error" component="div" />
        </Container>
    )
}

export default Select;