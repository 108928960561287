import styled from 'styled-components';
import { useCreateAccount } from '../context/PatientCreateAccountContext';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Flex = styled.div`
    padding: 80px 60px;
    width: 585px;
    height: 630px;
    margin-left: 150px;
    background: #FFFFFF;
    box-shadow: 8.32785px 8.32785px 24.9835px rgba(2, 2, 70, 0.15);
    border-radius: 32px;

        img{    
            margin-bottom: 32px;
        }

        .heading{
            font-weight: 700;
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 16px;
            color: #000000;
            text-transform: uppercase;
        }

        .content{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    
        @media (max-width: 1028px) {
            margin: 0 auto;
            max-width: 80%;
        }
`

const Close = styled.p`    
    float: right;
    position: relative;
    top: -31px;
    color: #212529;
`

const Note = styled.div`
    p{
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #373737;
    }
`

const SuccessCard = () => {
    const { inviteSessionUser, setInviteSessionUser } = useCreateAccount();

    const navigate = useNavigate();

    useEffect(()=> {
        setInviteSessionUser({
            ...inviteSessionUser,
            id:null,
            phoneNumber:null
        })
    },[]);

    return (
        <Flex>
            <Close><span className='close' onClick={() => navigate('/institution')}>X</span></Close>
            <div className='content'>
                <img src='/assets/institution/images/success.png' alt='success' />
                <p className='heading'>Congratulations {inviteSessionUser.firstName}</p>
                <Note>
                    <p>Registration successful.</p>
                    <p>Download the app to log in.</p>
                </Note>
            </div>
        </Flex>
    )

}

export default SuccessCard;