import styled from 'styled-components';



const ModalBox = styled.div`
  position: fixed;
  left:0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

`
const ModalScroll = styled.div`
  width: 100%;
  height: 100vh;
  background: transparent;
  overflow-y: auto;
`

interface DarkModalProps {
  children: React.ReactNode,
  className?: string

}


const DarkModal = ({children, className}:DarkModalProps) => {
  return (
    <ModalBox className={className}>
      <ModalScroll >
        {
            children
        }
      </ModalScroll>
    </ModalBox>
  )
}

export default DarkModal
