import TableRow, { Row } from '../../../../components/TableRow';
import ActionMenus from './ActionMenus';
import { Box, UserTableContainer } from '../../../../styles';
import {
     UserTopMenuType, TableHeaderInfo, MenuType, InstUserDataType
} from '../../../types/DataTypes'
import { AppDataType } from '../../../../components/DataTypes';
import styled from 'styled-components';

const DataStyle = styled.p`
    display: flex;
    flex-direction: row;
    gap: 8px;
        img{
            width: 24px;
            height: 24px;
            border-radius: 6px;
        }
`

interface UserTableProps {
    data: InstUserDataType[]
    nullMessage: string
    showPlusIcon: boolean
    additionMessage?: string
    tableHeader: Array<TableHeaderInfo>
    addTableActionBtn: boolean
    onActionClicked: (user: InstUserDataType) => void
    actionMenus: MenuType[] | UserTopMenuType[]
    selectedUser?: AppDataType
    onMenuClick: (m: MenuType) => void
    topActionMenus: UserTopMenuType[] | UserTopMenuType[],
    onTopMenuClick: (m: MenuType) => void,
    offset: number
}

const UserTable = ({ data, nullMessage, showPlusIcon, additionMessage, addTableActionBtn, tableHeader,
    onActionClicked, actionMenus, selectedUser, onMenuClick, offset, topActionMenus, onTopMenuClick }: UserTableProps) => {
    const fetchFromObject: (obj: AppDataType, prop?: string) =>
        string = (obj, prop) => {
            return obj[prop as keyof AppDataType] || ''
        }

    const fillRowData = (item: AppDataType, header: TableHeaderInfo, ind: number, key: number) => {
        let Data
        if (header.wrapper) {
            Data = (
                <header.wrapper
                    value={fetchFromObject(item, header.value)}
                ></header.wrapper>
            )
        }
        else if (ind === 0) {
            Data = offset + key + 1
        }
        else {
            Data = `${fetchFromObject(item, header.value)}`
        }
        return (
            <Row key={ind} className={`row row${ind}`}>
                <DataStyle>
                    {header.imgValue && <img src={`${fetchFromObject(item, header.imgValue)}`} alt="" />}
                    {Data || 'N/A'}
                </DataStyle>
            </Row>
        )
    }

    return (
        <UserTableContainer>
            <TableRow className='table-head'>
                {
                    tableHeader.map((s, ind) => {
                        return (
                            <Row key={ind} className={`row row${ind}`}>
                                <p>{s.text}</p>
                            </Row>
                        )
                    })

                }
                {
                    addTableActionBtn && (
                        <Row className={`row row-last}`}>
                            <p>Action</p>
                        </Row>
                    )
                }
            </TableRow>
            {
                data === undefined ?
                    <></> :
                    data.length > 0 ?
                        data.map((item: InstUserDataType, key: number) => {
                            return (
                                <TableRow className='table-row' key={key}>
                                    {
                                        tableHeader.map((header, ind) => {
                                            return fillRowData(item, header, ind, key)
                                        })
                                    }
                                    {addTableActionBtn &&
                                        <div onClick={() => onActionClicked(item)}>
                                            <ActionMenus
                                                menus={actionMenus}
                                                selectedUser={selectedUser}
                                                item={item}
                                                onMenuClick={onMenuClick}
                                                activator={
                                                    <>
                                                        <p>Action</p>
                                                        <img src="/assets/institution/icons/dropdown.svg" alt="" />
                                                    </>
                                                }
                                                type="tableAction"
                                            />
                                        </div>
                                    }
                                </TableRow>
                            )
                        })
                        :
                        <Box style={{ backgroundColor: "none" }}>
                            <div>
                                <p>{nullMessage}</p>
                                <ActionMenus
                                    activator={
                                        <>
                                            {showPlusIcon &&
                                                <p className='btn'><span>+</span><span style={{ textDecoration: "underline", marginLeft: "10px" }}>{additionMessage}</span></p>
                                            }
                                        </>}
                                    menus={topActionMenus}
                                    onMenuClick={onTopMenuClick}
                                    type="pageAction"
                                />
                            </div>
                        </Box>
            }

        </UserTableContainer>
    )
}

export default UserTable
