import React, { useEffect, useState } from "react";
import TopMenu from "../../../components/TopMenu";
import { Head, ExportBtn } from "../../styles/page-styles";
import Select from "../../../components/SingleSelect";
import StatusWrapper from "../../../components/StatusWrapper";
import Modal from "../../../components/Modal";
import addBtn from "./addBtn.svg";
import {
    InstitutionTypeDataType,
    ListMenuType,
    TableHeaderInfo,
} from "../../types/DataTypes";
import Pagination from "../../../components/Pagination";
import connectivityService from "../../../service/ConnectivityService";
import { ToastContainer, toast } from "react-toastify";
import { AppMenuType, ToastTypes } from "../../../components/DataTypes";
import AddInstitutionType from "./templates/AddInstitutionType";
import { useAdminSession } from "../../context/AdminSessionContext";
import { useNavigate } from "react-router-dom";
import DateWrapper from "../../../components/DateWrapper";
import GenericTable from "../../../components/GenericUserTable";
import {
    Loader,
    LoaderWrapper,
    SectionContainer,
    SpacedBetweenColumnBox,
} from "../../../styles";
import { sortDataByDate } from "../../../utils";

interface Props {
    pageChangeFunction: React.Dispatch<React.SetStateAction<string>>;
}

function InstitutionType({ pageChangeFunction }: Props) {
    const [selectedInstitutionType, setSelectedInstitutionType] =
        useState<InstitutionTypeDataType>();
    const [listActionMenus, setListActionMenus] = useState<ListMenuType[]>([]);
    const [modalType, setModalType] = useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [pageType, setPageType] = useState<string>("institutionType");
    const [tableData, setTableData] = useState<InstitutionTypeDataType[]>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [entityAction, setEntityAction] = useState<string>("Add");
    const [entityValue, setEntityValue] = useState<string>("");
    const [pageRefresh, setPageRefresh] = useState<boolean>(false);
    const { sessionAdmin } = useAdminSession();
    const navigate = useNavigate();

    const notify = (type: ToastTypes, msg: string) =>
        toast(msg, { type: type, position: "top-center", theme: "colored" });

    const url = process.env.REACT_APP_INSTITUTION_TYPE_URL;

    let tableHeader: TableHeaderInfo[];

    pageType === "institutionType"
        ? (tableHeader = [
              { value: "instTypeId", text: "S/N" },
              { value: "institutionType", text: "Institution Type" },
              { value: "workStatus", text: "Status", wrapper: StatusWrapper },
              {
                  value: "createdAt",
                  text: "Date Created",
                  wrapper: DateWrapper,
              },
              { value: "createdByName", text: "Created by" },
          ])
        : (tableHeader = [
              { value: "id", text: "S/N" },
              { value: "specialization", text: "Specialization" },
              { value: "status", text: "Status", wrapper: StatusWrapper },
              { value: "date", text: "Date" },
          ]);

    const itemsPerPage = 7;

    const handleDeactivate = (instTypeId: number) => {
        const deactivate = async () => {
            const payload = {
                adminId: sessionAdmin.userId,
                instTypeId: instTypeId,
                workStatus: "Inactive",
            };

            const res = await connectivityService().makeApiCall(
                "put",
                `${url}/${instTypeId}`,
                payload,
                sessionAdmin.accessToken
            );

            if (res.code === 200 || res.code === 201) {
                setPageRefresh(!pageRefresh);
                notify("success", "Status deactivated successfully");
            } else {
                await connectivityService().handleErrors(res, notify);
            }
        };

        deactivate();
    };

    const handleActivate = (instTypeId: number) => {
        const activate = async () => {
            const payload = {
                adminId: sessionAdmin.userId,
                instTypeId: instTypeId,
                workStatus: "Active",
            };

            const res = await connectivityService().makeApiCall(
                "put",
                `${url}/${instTypeId}`,
                payload,
                sessionAdmin.accessToken
            );

            if (res.code === 200 || res.code === 201) {
                setPageRefresh(!pageRefresh);
                notify("success", "Status activated successfully");
            } else {
                const error = await connectivityService().handleErrors(
                    res,
                    notify
                );
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.");
                    navigate("/admin");
                }
            }
        };

        activate();
    };

    const handleMenuClick = (menu: AppMenuType) => {
        const newselectedInvite = {
            ...(selectedInstitutionType as InstitutionTypeDataType),
            workStatus: menu === "Activate" ? "Active" : "Not Active",
        };
        setSelectedInstitutionType(newselectedInvite);
    };

    useEffect(() => {
        const fetchData = async () => {
            const res = await connectivityService().makeApiCall(
                "get",
                url,
                undefined,
                sessionAdmin.accessToken
            );

            if (res.code === 200 || res.code === 201) {
                const sortedTD = sortDataByDate(res.data, "createdAt");
                setTableData(sortedTD as InstitutionTypeDataType[]);
            } else {
                const error = await connectivityService().handleErrors(
                    res,
                    notify
                );
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.");
                    navigate("/admin");
                }
            }
        };
        fetchData();
    }, [pageRefresh]);

    const offset = itemsPerPage * (currentPage - 1);
    let displayData = tableData
        ? tableData.slice(offset, offset + itemsPerPage)
        : tableData;
    return (
        <SectionContainer>
            {showModal && (
                <Modal
                    children={
                        <AddInstitutionType
                            type={modalType}
                            entityName="institution type"
                            entityAction={entityAction}
                            entityValue={entityValue}
                            formClose={() => setShowModal(false)}
                            pageRefresh={pageRefresh}
                            setPageRefresh={setPageRefresh}
                        />
                    }
                    show={showModal}
                    onClose={() => {
                        setEntityValue("");
                        setShowModal(false);
                    }}
                    style={{ width: "704px" }}
                />
            )}

            <TopMenu
                hasNoButton
                navTag="Type & Specialization/Institution Type"
                btnAction="Add Specialization"
                topActionMenus={[]}
                onMenuClick={(menu) => {
                    handleMenuClick(menu);
                }}
                portal="admin"
                searchData={() => {}}
            />

            <SpacedBetweenColumnBox>
                <Head>
                    <div className="option" style={{ height: "100%" }}>
                        <div style={{ display: "flex", width: "100%" }}>
                            <Select
                                title="Select an option"
                                name="pageType"
                                options={["Institution Type", "Specialization"]}
                                onChange={() =>
                                    pageChangeFunction("specialization")
                                }
                            />
                        </div>
                    </div>

                    <div
                        onClick={() => {
                            setEntityAction("Add");
                            setShowModal(true);
                        }}
                        className="button"
                    >
                        <ExportBtn>
                            <img src={addBtn} alt="" />
                        </ExportBtn>
                    </div>
                </Head>

                {tableData !== undefined ? (
                    <>
                        <div className="page-section">
                            <GenericTable
                                data={displayData as InstitutionTypeDataType[]}
                                nullMessage="No Institution type has been created"
                                showPlusIcon={false}
                                tableHeader={tableHeader}
                                addTableActionBtn={true}
                                offset={offset}
                                onActionClicked={(
                                    item: InstitutionTypeDataType
                                ) => {
                                    const actionText =
                                        item.workStatus === "Active"
                                            ? "Deactivate"
                                            : "Activate";
                                    setSelectedInstitutionType(item);
                                    setListActionMenus([actionText]);
                                }}
                                selectedItem={selectedInstitutionType}
                                actionMenus={listActionMenus}
                                onMenuClick={(menu) => {
                                    if (menu === "Activate") {
                                        if (
                                            selectedInstitutionType?.instTypeId
                                        ) {
                                            handleActivate(
                                                selectedInstitutionType.instTypeId
                                            );
                                        }
                                    } else if (menu === "Deactivate") {
                                        if (
                                            selectedInstitutionType?.instTypeId
                                        ) {
                                            handleDeactivate(
                                                selectedInstitutionType.instTypeId
                                            );
                                        }
                                    }
                                }}
                            />
                        </div>

                        {tableData !== undefined &&
                            tableData.length > itemsPerPage && (
                                <Pagination
                                    data={
                                        tableData as InstitutionTypeDataType[]
                                    }
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    itemsPerPage={itemsPerPage}
                                />
                            )}
                    </>
                ) : (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                )}
            </SpacedBetweenColumnBox>

            <ToastContainer />
        </SectionContainer>
    );
}

export default InstitutionType;
