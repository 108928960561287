import styled from "styled-components";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { PriceSchema } from "../Price.validators";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import { FormBox } from "../../../styles/generalStyles";
import { TwoColumnInputFlex } from "../../../styles/generalStyles";
import connectivityService from "../../../../service/ConnectivityService";
import { ToastContainer, toast } from 'react-toastify';
import { ToastTypes } from '../../../../components/DataTypes';
import { useState } from "react";
import { PriceDataType } from "../../../types/DataTypes";
import toggleButton from "../../../../institution/utils/toggleButton";
import { useAdminSession } from "../../../context/AdminSessionContext";
import { useNavigate } from "react-router-dom";

const ModalContent = styled.div`
    margin-top : -17px;

    .file-input{
        height: 50px;
        width: 100%;
        padding: 13px;
    }

    .title{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        p{
            font-weight: 400;
            font-size: 16px;
            a{
                color: #2A6BB0;
            }
        }
    }

    .input-blue-border{
        background: #FFFFFF;
        border: 1px solid #2A6BB0;
        border-radius: 4px;
    }
    
    .input-section{
        margin-top: 16px;
        .button {
            margin-top: 34px;
        }
        label{
            font-weight: 400;
            font-size: 16px;
            color: #4E4E4E;
        }
    }

    .bulk{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 24px;
        align-items: end;
    }

    #button.img-btn:hover{
        background: #2A6BB0 !important;
    }
`

const ValidityText = styled.div`
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 16px;
    p{
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #373737;
    }
`

type PriceObjType = {
    price?: string,
    volume?: string,
    validFrom?: string,
    validTo?: string
}

interface AddPriceProps {
    addPrice: (invite: PriceDataType) => void,
    formClose: () => void,
    productId: string,
    pageRefresh: boolean,
    setPageRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

const AddPrice = ({ formClose, addPrice, productId, pageRefresh, setPageRefresh }: AddPriceProps) => {
    const [priceData, setPriceData] = useState<PriceObjType>(
        {
            price: "",
            volume: "",
            validFrom: "",
            validTo: ""
        }
    )

    const [loading, setLoading] = useState<boolean>(false);
    const { sessionAdmin } = useAdminSession();

    const navigate = useNavigate();

    const notify = (type: ToastTypes, msg: string) => (
        toast(msg, { type: type, position: "top-center", theme: "colored" })
    );

    const handleSubmit = async (values: PriceObjType, actions: FormikHelpers<PriceObjType>) => {

        if (values.validTo) {
            if (new Date(values.validTo) <= new Date(values.validFrom as string)) {
                notify("error", `price validFrom must be less than validTo`);
                return;
            }

            if (new Date(values.validTo) <= new Date()) {
                notify("error", `invalid validTo for price`);
                return;
            }
        }

        setLoading(true);
        setPriceData(values);

        const data = {
            price: values.price,
            volume: values.volume,
            validFrom: values.validFrom,
            validTo: values.validTo,
            productId,
            createdBy: sessionAdmin.userId
        };

        const url = `${process.env.REACT_APP_PRODUCT_ROOT_URL}/price`;
        const response = await connectivityService().makeApiCall(
            'post', url, data, sessionAdmin.accessToken
        );

        setLoading(false);

        if (response.code === 200 || response.code === 201) {
            notify("success", "Product price added successfuly");
            actions.resetForm({
                values: {
                    price: "",
                    validFrom: "",
                    validTo: "",
                    volume: "",
                }
            });
            setPageRefresh(!pageRefresh)
            formClose();
        }
        else {
            const error = await connectivityService().handleErrors(response, notify)
            if (error === "AccessDenied") {
                notify("error", "Login session expired. Please re-login.")
                navigate("/admin")
            }
        }
    }


    return (
        <>
            <Formik
                initialValues={priceData}
                onSubmit={handleSubmit}
                validationSchema={PriceSchema}>
                {
                    (formik: FormikProps<PriceObjType>) => {
                        const toggle = toggleButton<PriceObjType>(formik.values) &&
                            Object.keys(formik.errors).length === 0;
                        return (
                            <ModalContent>
                                <div className="title">
                                    Add Price
                                </div>
                                <div className="input-section">
                                    <Form>
                                        <TwoColumnInputFlex>
                                            <FormBox>
                                                <div className="flex">
                                                    <Input
                                                        name="price"
                                                        title="Price"
                                                        type="text"
                                                        placeholder="Enter price"
                                                        required
                                                    />
                                                    <Input
                                                        name="volume"
                                                        title="Volume"
                                                        type="text"
                                                        placeholder="Enter volume"
                                                        required
                                                    />
                                                </div>
                                                <ValidityText>
                                                    <p>Validity Period</p>
                                                </ValidityText>
                                                <div className="flex">
                                                    <Input
                                                        name="validFrom"
                                                        title="From"
                                                        type="date"
                                                        required
                                                    />
                                                    <Input
                                                        name="validTo"
                                                        title="To"
                                                        type="date"
                                                        required
                                                    />
                                                </div>
                                                <Button
                                                    className="button"
                                                    type="submit"
                                                    loading={loading}
                                                    disabled={!toggle}
                                                    children={`Add Price`}
                                                    style={{ marginTop: "24px" }}
                                                />
                                            </FormBox>
                                        </TwoColumnInputFlex>
                                    </Form>
                                </div>
                            </ModalContent>
                        )
                    }
                }
            </Formik>
            <ToastContainer limit={1} />
        </>
    )

}

export default AddPrice;