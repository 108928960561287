import React from 'react';
import styled from 'styled-components';
import {useField, ErrorMessage} from "formik";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 100%;
    gap: 4px;
    position: relative;

    textarea{
        height: 150px;
        width: 600px;
        border-radius: 5px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        border: 1px solid #E5E5E5;
        border-radius: 4px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #4E4E4E;
        outline: none;

    }


    label {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #4E4E4E;
    }
`

interface TextAreaProps {
    title?: string;
    name: string;
    placeholder?: string;
    cols?: number;
    rows?: number;
    required?: boolean;
    width?: string;
}
const TextArea = (props: TextAreaProps) =>{
    const [field, meta] = useField(props);
    const {title, cols, rows, placeholder, required, width} = props;
    return(
        <Container className='form-group'>
            <label htmlFor={field.name}>{title}<span style={{marginLeft: "3px"}}>{required && "*"}</span></label>
            <textarea
                style={{borderColor: meta.touched && meta.error ? "#ED1C24": meta.touched && !meta.error? "#2A6BB0" :"#E5E5E5", width: width}}
                className='form-textarea'
                id={field.name}
                rows={rows}
                cols={cols}
                {...field}
                placeholder={placeholder}>
            </textarea>
            <ErrorMessage name={field.name} className = "error" component="div"/>
        </Container>
    )
}

export default TextArea;