import React from 'react'
import { CreateAccountProvider, useCreateAccount } from './context/InstCreateAccountContext';
import {Body, Container} from '../CreateAccount.style'
import AddressInformation from './templates/AddressInformation';
import AdminUserInformation from './templates/AdminUserInformation';
import BasicInformation from './templates/BasicInformation';
import DocumentUpload from './templates/DocumentUpload';
import { useNavigate } from 'react-router-dom';


const Home = () => {
    const {subPages} = useCreateAccount();
    const [curSubPage, setCurSubPage] = subPages;

    const navigate = useNavigate(); 

    return (
        <Container>
            <Body>
                <p className='close' onClick={() => navigate('/institution')}>X</p>
                {
                    curSubPage === 0 ?
                    <AdminUserInformation />:
                    curSubPage === 1 ?
                    <BasicInformation />:
                    curSubPage === 2 ?
                    <AddressInformation />:
                    curSubPage === 3 ?
                    <DocumentUpload />: ""
                }
            </Body>
        </Container>
    )
}

const CreateAccount = () => {

  return (
    <CreateAccountProvider>
        <Home />
    </CreateAccountProvider>
  )
}

export default CreateAccount
