import * as yup from 'yup';


export const AdminInfoSchema = yup.object({
    firstName: yup
        .string()
        .trim()
        .min(3, "First name should be up to 3 characters")
        .required("This field is required"),
    lastName: yup
        .string()
        .trim()
        .min(3, "Last name should be up to 3 characters")
        .required("This field is required"),
    password: yup
        .string()
        .trim()
        .required("This field is required")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            "Must contain 8 characters, one uppercase, one lower case, one number and one special case character."
        )
});

export const BasicInfoSchema = yup.object({
    institutionName: yup
        .string()
        .trim()
        .min(3, "Name should be up to 3 characters")
        .required("This field is required"),
    institutionType: yup
        .string(),
    institutionEmail: yup
        .string()
        .trim()
        .email('This is not a valid email')
        .required('Email field is empty'),
    institutionPhoneNumber: yup
        .string()
        .trim()
        .min(3, "Name should be up to 3 characters")
        .required("This field is required"),
    institutionSummary: yup
        .string()
        .trim()
        .min(3, "Name should be up to 3 characters")
        .required("This field is required")
});

export const AddressSchema = yup.object({
    companyAddress: yup
        .string()
        .trim()
        .min(5, "Address should have atleast 5 characters")
        .required("Required field"),
    town: yup
        .string()
        .trim()
        .min(3, "Town name should have atleast 3 characters")
        .required("Required field"),
    state: yup
        .string()
        .trim()
        .min(3, "State name should have atleast 3 characters")
        .required("Required field"),
    country: yup
        .string()
        .trim()
        .required("Required field")
});