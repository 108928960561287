import styled from "styled-components";
import { ButtonType } from "./DataTypes";

const ButtonStyles = styled.div`
    #button{
        background: #2A6BB0;
        border-radius: 8px;
        color: white;
        width: 100%;
        padding: 16px;
        cursor: pointer;
        font-family: 'Lato';
        font-size: 16px;
    }

    #button.disabled:hover{
        border: none;
        color: white;
    }

    #button.loading{
        color: #7EA6D0
    }

    #button.loading:hover{
        color: #7EA6D0
    }

    #button:hover{
      background: #FFFFFF;
      border: 1px solid #2A6BB0;
      color: #2A6BB0;
    }

    #button.disabled, #button.loading{
        background-color: #EAF0F7;
    }
      
`

interface ButtonProps {
    onClick?: () => void,
    loading?: boolean,
    disabled?: boolean,
    children: JSX.Element | string,
    className?: string,
    type?: ButtonType,
    style?: React.CSSProperties
}

const Button = ({ onClick, loading, disabled, children, className, type, style }: ButtonProps) => {
    const handleClick = () => {
        if (onClick) onClick();
    };
    return (
        <ButtonStyles>
            <button
                type={type}
                id="button"
                className={`${disabled ? "disabled" : ""} ${loading? "loading" : ""} ${className}`}
                onClick={handleClick}
                style={style}
                disabled={disabled || loading}
            >
                <div className="children d-flex align-items-center">
                  {loading && <i className="fa fa-spinner fa-spin"></i>}
                  {children}
                </div>
            </button>
        </ButtonStyles>
    );
};

export default Button;
