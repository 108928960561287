import {createGlobalStyle} from "styled-components";


const GlobalStyles = createGlobalStyle`

    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    body {
        overflow-x: hidden;
    }

    .error {
        position: absolute;
        top: 80px;
        left:0;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */
        color: #ED1C24;
    }

    .label {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #4E4E4E;
    }

    .close {
        text-align: right;
        width: 100% !important;
        margin-bottom: 10px;
        cursor: pointer;
        // padding: 0 40px 0 55px;
    }



`

export default GlobalStyles;