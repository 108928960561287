import { TableHeaderInfo } from "./DataTypes";

const TimeWrapper = (props: TableHeaderInfo) => {
    function convertDate(inputFormat: string) {
      var d = new Date(inputFormat);
      let day = d.getDate();
      let month = d.toLocaleString('default', { month: 'short' });;
      let year = d.getFullYear();
      let hours = d.getHours().toString().padStart(2, '0');
      let minutes = d.getMinutes().toString().padStart(2, '0');
      if (isNaN(day) || !day || isNaN(year)) return "Invalid date";
      return `${day} ${month} ${year}, ${hours}:${minutes}`;
    }
  
    return <span>{convertDate(props.value)}</span>;
  };
  
  export default TimeWrapper;
  