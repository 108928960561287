import AuthCode from 'react-auth-code-input';
import styled from "styled-components";
import { useField } from "formik";


const OTPContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    div {
    display: flex;
    gap: 45px;
    padding: 0 20%;
    }

    input {
    text-align: center;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #373737;
    padding: 16px;

    width: 46px;
    height: 46px;

    border: 1px solid #2A6BB0;
    border-radius: 4px;
    }
`;

interface OTPInputProps {
    name: string
}

const OTPInput = (props:OTPInputProps) => {
    const [field, meta, helpers] = useField(props);
  return (
    <div>
        <OTPContainer>
            <AuthCode 
                {...field}
                onChange = {(value)=> {value !== "" && helpers.setValue(value)}}
                length = {4}/>

        </OTPContainer>
    </div>
  )
}

export default OTPInput
