import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ToastTypes } from "../../../../components/DataTypes";
import connectivityService from "../../../../service/ConnectivityService";
import { useAdminSession } from "../../../context/AdminSessionContext";
import { DoctorDataType } from "../../../types/DataTypes";
import { useNavigate } from "react-router-dom";

const Modal = styled.div`
    .content-inner{
        padding: 16px;
        font-family: 'Courier New';
        background-color: #ECF5FF;
        margin-top: 15px;
        margin-bottom: 30px;

        .value{
            line-height: 16px;
            color: #373737;
        }
    }

    .head-1, .head-2, .head-3, .head-4{
        font-weight: 700;
    }

    .head-1{
        font-size: 22px;
    }

    .head-2{
        font-size: 14px;
        margin-top: 30px;
    }

    .row{
        display: flex;
        flex-direction : row;
        justify-content: space-between;
    }

    .row1, .row2, .row3, .row4, .row5{
        margin-bottom: 20px;
    }
    
    .row p:(first-child){
        margin-bottom: 8px;
    }

    a {
        text-decoration: underline;
        color: #0066cc;
    }
`

type DoctorInfoType = {
    licenseCountryOfIssue: string,
    specialization: string,
    language: string,
    licenseNumber: string,
    experience: string,
    certificate: string
}

interface ViewPatientProps {
    doctorRep?: DoctorDataType
}

const ViewDoctor = ({ doctorRep }: ViewPatientProps) => {
    const [doctor, setDoctor] = useState<DoctorDataType>();
    const [doctorInfo, setDoctorInfo] = useState<DoctorInfoType>();
    const [languages, setLanguages] = useState<String>();

    const { sessionAdmin } = useAdminSession();
    const navigate = useNavigate();

    const notify = (type: ToastTypes, msg: string) => (
        toast(msg, { type: type, position: "top-center", theme: "colored" })
    );

    useEffect(() => {
        const fetchData = async () => {
            const url = `${process.env.REACT_APP_USER_ROOT_URL}/${doctorRep?.userId}`;
            const infoUrl = `${process.env.REACT_APP_DOCTOR_ROOT_URL}/${doctorRep?.userId}/qualification`;
            const languageUrl = `${process.env.REACT_APP_DOCTOR_ROOT_URL}/${doctorRep?.userId}/languages`;

            const res = await connectivityService().makeApiCall("get", url, undefined, sessionAdmin.accessToken);

            if (res.code === 200 || res.code === 201) {
                setDoctor(res.data);
                const infoRes = await connectivityService().makeApiCall("get", infoUrl, undefined, sessionAdmin.accessToken);
                if (infoRes.code === 200 || infoRes.code === 201) {
                    setDoctorInfo(infoRes.data);
                    const languageRes = await connectivityService().makeApiCall("get", languageUrl, undefined, sessionAdmin.accessToken);
                    if (languageRes.code === 200 || languageRes.code === 201) {
                        const languages: Array<String> = [];
                        languageRes.data.map((val: any, ind: any) => {
                            return languages.push(val.language);
                        })
                        setLanguages(languages.join(", "));
                    }
                    else {
                        const error = await connectivityService().handleErrors(languageRes, notify)
                        if (error === "AccessDenied") {
                            notify("error", "Login session expired. Please re-login.")
                            navigate("/admin")
                        }
                    }
                }
                else {
                    const error = await connectivityService().handleErrors(infoRes, notify)
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.")
                    navigate("/admin")
                }
                }
            }
            else {
                const error = await connectivityService().handleErrors(res, notify)
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.")
                    navigate("/admin")
                }
            }
        }
        fetchData();
    }, []);

    return (
        <>
            <Modal>
                <p className="head-1">View</p>
                <p className="head-2">Doctor's Details</p>
                <div className="content-inner">
                    <div className="row row1">
                        <div>
                            <p className="head-3">Name</p>
                            <p className="value">{doctor?.firstName ?? "loading..."} {doctor?.lastName}</p>
                        </div>
                        <div>
                            <p className="head-3">Email Address</p>
                            <p className="value">{doctor?.email ?? "loading..."}</p>
                        </div>
                    </div>
                    <div className="row row2">
                        <div>
                            <p className="head-3">Phone Number</p>
                            <p className="value">{doctor?.phoneNumber ?? "loading..."}</p>
                        </div>
                        <div>
                            <p className="head-3">Gender</p>
                            <p className="value">{doctor?.gender === "M" ? "Male" : "Female" ?? "loading..."}</p>
                        </div>
                    </div>
                    <div className="row row3">
                        <div>
                            <p className="head-3">Date of Birth</p>
                            <p className="value">{doctor?.dateOfBirth ?? "loading..."}</p>
                        </div>
                        <div>
                            <p className="head-3">Country</p>
                            <p className="value">{doctorInfo?.licenseCountryOfIssue ?? "-"}</p>
                        </div>
                    </div>
                    <div className="row row4">
                        <div>
                            <p className="head-4">License Number</p>
                            <p className="value">{doctorInfo?.licenseNumber ?? "-"}</p>
                        </div>
                        <div>
                            <p className="head-4">Language</p>
                            <p className="value">{languages ?? "-"}</p>
                        </div>
                    </div>
                    <div className="row row5">
                        <div>
                            <p className="head-4">Specialty</p>
                            <p className="value">{doctorInfo?.specialization ?? "-"}</p>
                        </div>
                        <div>
                            <p className="head-4">Years of Experience</p>
                            <p className="value">{doctorInfo?.experience ?? "-"}</p>
                        </div>
                    </div>
                    <div className="row row6">
                        <div >
                            <p className="head-4">Certificate</p>
                            <a target={"_blank"} href={doctorInfo?.certificate}>{doctorInfo?.certificate ? "certificate.pdf" : ""}</a>
                        </div>
                        <div >
                            <p className="head-4">Profile Picture</p>
                            <a target={"_blank"} href={doctor?.userImgUri}>{doctor?.userImgUri ? "picture.jpg" : ""}</a>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )

}

export default ViewDoctor