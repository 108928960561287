import React, { useState } from "react";
import InstitutionType from "../InstitutionType/InstitutionType";
import Specialization from "../Specialization/Specialization";

const TypeAndSpecialization = () => {
    const [activePage, setActivePage] = useState("institutiontype")
    
    return (
        <div style={{ width: "100%" }}>
            {
                (activePage === "institutiontype") ?
                        <InstitutionType pageChangeFunction={setActivePage} />
                    :
                        <Specialization pageChangeFunction={setActivePage} />
            }
        </div>
    )
}

export default TypeAndSpecialization;