import { useState, useEffect} from 'react';
import styled from 'styled-components';
import { UserDataType } from '../types/DataTypes';

const PaginationBox = styled.div`
    width: 100%;
    background: #DDE2E7;
    padding: 12px 20px;

    .right{
        margin-left: 20px;
    }
    .content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .left-arrow, .right-arrow{
        padding: 2px 6px;
        background: #FFFFFF;
        box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.24);
        border-radius: 6px;
        cursor: pointer;
    }

    .right-arrow, select{
        margin-left: 10px;
    }

    .left-arrow{
        margin-right: 10px;
    }

    select{
        background-color: transparent;
        cursor: pointer;
        border: none;
    }

    .disabled{
        pointer-events: none;
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
    }
    }
`

interface PaginationProps {
    data: UserDataType[];
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    itemsPerPage: number;
}

const Pagination = ({ data, currentPage, setCurrentPage, itemsPerPage }: PaginationProps) => {
    const numberOfPages = data.length > 6 ? Math.ceil(data.length / itemsPerPage) : 1;
    const offset = itemsPerPage * (currentPage - 1);

    const previousPageHandler = () => {
        setCurrentPage(currentPage - 1);

    }

    const nextPageHandler = () => {
        setCurrentPage(currentPage + 1)
    }

    return (
        <PaginationBox>
            <div className='content'>
                <p>
                    {offset + 1}-{(numberOfPages === 1 || currentPage === numberOfPages) ?
                        data.length : offset + itemsPerPage} of {data.length}
                </p>
                <p>
                    {/* <span>Rows per page:
                        <select value="" onSelect={(e: any) => {
                            setItemsPerPage(e.target.value);
                        }}>
                            <option value="7">7</option>
                            <option value="7">10</option>
                        </select>
                    </span> */}
                    <span
                        className={`right left-arrow ${currentPage === 1 ? "disabled" : ""}`}
                        onClick={previousPageHandler}>
                        <img src="/assets/institution/icons/arrow-left-icon.svg" alt=""></img>
                    </span>
                    <span>{currentPage}/{numberOfPages}</span>
                    <span
                        className={`right-arrow ${currentPage === numberOfPages ? "disabled" : ""}`}
                        onClick={nextPageHandler}>
                        <img src="/assets/institution/icons/arrow-right-icon.svg" alt=""></img>
                    </span>
                </p>
            </div>
        </PaginationBox>
    )
}

export default Pagination;