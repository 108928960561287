import { useState } from 'react';
import { useCreateAccount } from '../context/PatientCreateAccountContext'
import { FormBox, FormStyle, Heading, TwoColumnInputFlex } from '../../../../styles/generalStyles';
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import styled from 'styled-components';
import Input from '../../../../../components/Input';
import Button from '../../../../../components/Button';
import { toast, ToastContainer } from 'react-toastify';
import connectivityService from '../../../../../service/ConnectivityService';

const AllergiesList = styled.div`
    display: flex;
    gap: 16px;
    color: #2A6BB0;
    width: 100%;
    flex-wrap: wrap;
    p {
        padding: 8px;
        border: 1px solid #2A6BB0;
        border-radius: 17px;
        span{
            margin-left: 8px;
            margin-right: 2px;
            cursor: pointer;
        }
    }
`

const Btn = styled.div`
    height: 50px;
    border-radius: 8px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    outline: none;
    border: none;
    width: 100%;

`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 50px 96px 108px 50px;   
    
    @media (max-width : 768px) {
        padding: 80px 0 48px 45px;
    }
`

const Note = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #373737;
    text-align: center;
    margin-top: 8px;
`

const AllergiesInfo = () => {
    const { inviteSessionUser, subPages } = useCreateAccount();
    const [curSubPage, setCurSubPage] = subPages;
    const [loading, setLoading] = useState<boolean>(false);
    const [allergyArray, setAllergyArray] = useState<Array<string>>([]);
    const [isAllergyFirstMount, setIsAllergyFirstMount] = useState<boolean>(true);

    if (inviteSessionUser.id === null) {
        setCurSubPage(0);
    }


    let AllergiesInfo = {
        allergy: ""
    }

    const handleRemoval = (name: string) => {
        const newAllergyArray = allergyArray.filter((val) => (val !== name));
        setAllergyArray(newAllergyArray);
    }

    const handleSubmit = async (values: typeof AllergiesInfo, actions: FormikHelpers<typeof AllergiesInfo>) => {
        setLoading(true);
        type ToastTypes = 'info' | 'success' | 'warning' | 'error' | 'default';

        const notify = (type: ToastTypes, msg: string) => (
            toast(msg, { type: type, position: "top-center", theme: "colored" })
        )

        const payload = {
            allergies: allergyArray.toString()
        }

        const url = `${process.env.REACT_APP_USER_ROOT_URL}/${inviteSessionUser.id}/allergy`;

        const response = await connectivityService().makeApiCallWithoutToken("post", url, payload);

        const data = connectivityService().handleErrorsReturnData(response, notify);

        if (data) {
            setCurSubPage(3);
            
        }
        setLoading(false);

    }

    const skip = () => {
        setCurSubPage(3);
    }

    const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }

    return (
        <Formik initialValues={AllergiesInfo}
            onSubmit={handleSubmit}
        >
            {
                (formik: FormikProps<typeof AllergiesInfo>) => {
                    const toggle = allergyArray.length !== 0;
                    return (
                        <div >
                            <Form onKeyPress={onKeyPress} style={FormStyle}>
                                <TwoColumnInputFlex>
                                    <Container>
                                        <Heading style={{ marginBottom: "16px" }}>Allergies</Heading>
                                        <Note style={{ textAlign: 'left', marginTop: 0 }}>Let us know if there is anything you react to</Note>
                                        <FormBox style={{ marginTop: 24, gap: "10px", height: "280px" }}>
                                            <Input 
                                                title='Any Allergy?'
                                                name='allergy'
                                                type='text'
                                                placeholder="Enter allergies"
                                                forceError={!isAllergyFirstMount && allergyArray.length === 0}
                                                tempOnKeyPress={(e) => {
                                                    if(e.key === "Enter") {
                                                    !allergyArray.includes(e.target.value) && e.target.value !== ""
                                                     && setAllergyArray([...allergyArray, e.target.value]);
                                                        formik.setFieldValue('allergy', '');
                                                    isAllergyFirstMount && setIsAllergyFirstMount(false);
                                                    }
                                                }}
                                                required
                                            />
                                            <p style={{
                                                transform: "translateY(-8px)",
                                                fontSize: "14px",
                                                color: "#ED1C24",
                                                margin: 0,
                                                padding: 0

                                            }}
                                            >{!isAllergyFirstMount && allergyArray.length === 0 && "This field is required"}</p>
                                            <AllergiesList>
                                                {allergyArray.length > 0 && allergyArray.map((language: string, index: number) => {
                                                    return <p key={index}>{language}<span onClick={() => handleRemoval(language)}>x</span></p>
                                                })}
                                            </AllergiesList>
                                        </FormBox>
                                        <div className='flex' style={{ marginTop: "24px" }}>
                                            <Btn><Button type="submit" disabled={!toggle} loading={loading} children="Next" /></Btn>
                                            <Btn><Button type="button" onClick={skip} children="Skip" /></Btn>
                                        </div>
                                        <Note style={{ marginTop: "24px" }}>Download the Patient app on your mobile device to get full access</Note>
                                        <div className='flex' style={{ marginTop: "16px" }}>
                                            <img src="/assets/institution/icons/app-store-btn.svg" alt="apple-store-button" />
                                            <img src="/assets/institution/icons/google-store-btn.svg" alt="google-play-store-button" />
                                        </div>
                                    </Container>
                                </TwoColumnInputFlex>
                            </Form>
                            <ToastContainer limit={1} />
                        </div>
                    )

                }
            }
        </Formik>
    )
}

export default AllergiesInfo;
