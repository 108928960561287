import React, { useEffect, useState } from "react";
import Input from "../../../../../components/Input";
import Select from "../../../../../components/Select";
import { useCreateAccount } from "../context/InstCreateAccountContext";
import {
  DoubleInputWrapper,
  FormBox,
  Heading,
} from "../../CreateAccount.style";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import toggleButton from "../../../../utils/toggleButton";
import TextArea from "../../../../../components/TextArea";
import Footer from "./Footer";
import { BasicInfoSchema } from "../InstCreateAccount.validators";
import PhoneInput from "../../../../components/PhoneInput";
import connectivityService from "../../../../../service/ConnectivityService";
import { toast, ToastContainer } from "react-toastify";

export const FormStyle: React.CSSProperties = {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const BasicInformation = () => {
  const { basic, subPages } = useCreateAccount();
  const [basicInfo, setBasicInfo] = basic;
  const [curSubPage, setCurSubPage] = subPages;
  const [institutionTypes, setInstitutionTypes] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (
    values: typeof basicInfo,
    actions: FormikHelpers<typeof basicInfo>
  ) => {
    setLoading(true);
    setBasicInfo(values);
    setCurSubPage(2);
    setLoading(false);
  };

  type ToastTypes = "info" | "success" | "warning" | "error" | "default";

  const notify = (type: ToastTypes, msg: string) =>
    toast(msg, { type: type, position: "top-center", theme: "colored" });

  console.log({ hello: "hello test" });

  useEffect(() => {
    const fetchData = async () => {
      const res = await connectivityService().makeApiCallWithoutToken(
        "get",
        process.env.REACT_APP_INSTITUTION_TYPE_URL
      );

      const data = connectivityService().handleErrorsReturnData(res, notify);

      if (data) {
        const institutionTypes = data.map(
          (
            val: {
              workStatus: string;
              institutionType: any;
            },
            key: any
          ) => {
            return val.workStatus === "Active" ? val.institutionType : "";
          }
        );
        setInstitutionTypes(institutionTypes);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Formik
        initialValues={basicInfo}
        onSubmit={handleSubmit}
        validationSchema={BasicInfoSchema}
      >
        {(formik: FormikProps<typeof basicInfo>) => {
          const toggle = toggleButton<typeof basicInfo>(formik.values);
          return (
            <div style={{ height: "100%" }}>
              <Form style={FormStyle}>
                <div style={{ padding: "0 40px 0 55px" }}>
                  <Heading>Institution Information</Heading>
                  <FormBox>
                    <Input
                      title="Name of Institution"
                      name="institutionName"
                      type="text"
                      placeholder="Enter name of institution"
                      required
                    />
                    <Select
                      title="Type of Institution"
                      name="institutionType"
                      options={institutionTypes.filter((val) => val !== "")}
                      placeholder="Select an option"
                      required
                    />
                    <DoubleInputWrapper>
                      <Input
                        title="Institution’s email address"
                        name="institutionEmail"
                        type="text"
                        placeholder="Enter email address"
                        required
                      />
                      <PhoneInput
                        title="Phone number"
                        name="institutionPhoneNumber"
                        placeholder="Phone number"
                      />
                    </DoubleInputWrapper>
                    <TextArea
                      title="Institution summary"
                      name="institutionSummary"
                      placeholder="Type here"
                      required
                    />
                  </FormBox>
                </div>
                <Footer shouldDisable={!toggle} loading={loading} />
              </Form>
            </div>
          );
        }}
      </Formik>
      <ToastContainer />
    </>
  );
};

export default BasicInformation;
