import React, { useEffect, useState } from "react";
import TopMenu from "../../../components/TopMenu";
import { Head, ExportBtn } from "../../styles/page-styles";
import Select from "../../../components/SingleSelect";
import StatusWrapper from "../../../components/StatusWrapper";
import addBtn from "./addBtn.svg";
import {
    SpecializationDataType,
    ListMenuType,
    TableHeaderInfo,
} from "../../types/DataTypes";
import Pagination from "../../../components/Pagination";
import connectivityService from "../../../service/ConnectivityService";
import { ToastContainer, toast } from "react-toastify";
import { AppMenuType, ToastTypes } from "../../../components/DataTypes";
import Modal from "../../../components/Modal";
import AddSpecialization from "./templates/AddSpecialization";
import { useAdminSession } from "../../context/AdminSessionContext";
import { useNavigate } from "react-router-dom";
import DateWrapper from "../../../components/DateWrapper";
import GenericTable from "../../../components/GenericUserTable";
import {
    Loader,
    LoaderWrapper,
    SectionContainer,
    SpacedBetweenColumnBox,
} from "../../../styles";
import { sortDataByDate } from "../../../utils";

interface Props {
    pageChangeFunction: React.Dispatch<React.SetStateAction<string>>;
}

const Specialization = ({ pageChangeFunction }: Props) => {
    const [selectedSpecialization, setSelectedSpecialization] =
        useState<SpecializationDataType>();
    const [listActionMenus, setListActionMenus] = useState<ListMenuType[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [tableData, setTableData] = useState<SpecializationDataType[]>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageRefresh, setPageRefresh] = useState<boolean>(false);
    const { sessionAdmin } = useAdminSession();
    const navigate = useNavigate();
    const modalType = "";

    const url = process.env.REACT_APP_SPECIALIZATION_URL;

    const notify = (type: ToastTypes, msg: string) =>
        toast(msg, { type: type, position: "top-center", theme: "colored" });

    const tableHeader: TableHeaderInfo[] = [
        { value: "userId", text: "S/N" },
        { value: "specialization", text: "Specialization" },
        { value: "workStatus", text: "Status", wrapper: StatusWrapper },
        { value: "createdAt", text: "Date", wrapper: DateWrapper },
    ];

    const itemsPerPage = 7;

    const handleMenuClick = (menu: AppMenuType) => {
        const newselectedInvite = {
            ...(selectedSpecialization as SpecializationDataType),
            workStatus: menu === "Activate" ? "Active" : "Not Active",
        };
        setSelectedSpecialization(newselectedInvite);
    };

    const handleDeactivate = (specializationId: number) => {
        const deactivate = async () => {
            const payload = {
                adminId: sessionAdmin.userId,
                specializationId: specializationId,
                workStatus: "Inactive",
            };

            const res = await connectivityService().makeApiCall(
                "put",
                `${url}/${specializationId}`,
                payload,
                sessionAdmin.accessToken
            );

            if (res.code === 200 || res.code === 201) {
                setPageRefresh(!pageRefresh);
            } else {
                await connectivityService().handleErrors(res, notify);
            }
        };
        deactivate();
    };

    const handleActivate = (specializationId: number) => {
        const activate = async () => {
            const payload = {
                adminId: sessionAdmin.userId,
                specializationId: specializationId,
                workStatus: "Active",
            };

            const res = await connectivityService().makeApiCall(
                "put",
                `${url}/${specializationId}`,
                payload,
                sessionAdmin.accessToken
            );

            if (res.code === 200 || res.code === 201) {
                setPageRefresh(!pageRefresh);
            } else {
                const error = await connectivityService().handleErrors(
                    res,
                    notify
                );
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.");
                    navigate("/admin");
                }
            }
        };

        activate();
    };

    useEffect(() => {
        const fetchData = async () => {
            const res = await connectivityService().makeApiCall(
                "get",
                url,
                undefined,
                sessionAdmin.accessToken
            );

            if (res.code === 200 || res.code === 201) {
                const sortedTD = sortDataByDate(res.data, "createdAt");
                setTableData(sortedTD as SpecializationDataType[]);
            } else {
                const error = await connectivityService().handleErrors(
                    res,
                    notify
                );
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.");
                    navigate("/admin");
                }
            }
        };
        fetchData();
    }, [navigate, pageRefresh, sessionAdmin.accessToken, url]);

    const offset = itemsPerPage * (currentPage - 1);
    let displayData = tableData
        ? tableData.slice(offset, offset + itemsPerPage)
        : tableData;

    return (
        <SectionContainer optionalStyles="justify-content: unset;">
            {showModal && (
                <Modal
                    children={
                        <AddSpecialization
                            type={modalType}
                            entityName="Specialization"
                            formClose={() => setShowModal(false)}
                            pageRefresh={pageRefresh}
                            setPageRefresh={setPageRefresh}
                        />
                    }
                    show={showModal}
                    onClose={() => setShowModal(false)}
                    style={{ width: "704px" }}
                />
            )}

            <TopMenu
                hasNoButton
                navTag="Type & Specialization/Specialization"
                btnAction="Add Specialization"
                topActionMenus={[]}
                onMenuClick={(menu) => {
                    handleMenuClick(menu);
                }}
                portal="admin"
                searchData={() => {}}
            />

            <SpacedBetweenColumnBox>
                <Head>
                    <div className="option" style={{ height: "100%" }}>
                        <div style={{ display: "flex", width: "100%" }}>
                            <Select
                                title="Select an option"
                                name="pageType"
                                options={["Specialization", "Institution Type"]}
                                onChange={() =>
                                    pageChangeFunction("institutiontype")
                                }
                            />
                        </div>
                    </div>

                    <div onClick={() => setShowModal(true)} className="button">
                        <ExportBtn>
                            <p></p>
                            <img src={addBtn} alt="" />
                        </ExportBtn>
                    </div>
                </Head>

                {tableData !== undefined ? (
                    <>
                        <div className="page-section">
                            <GenericTable
                                data={displayData as SpecializationDataType[]}
                                nullMessage="No Specialization has been created"
                                showPlusIcon={false}
                                tableHeader={tableHeader}
                                addTableActionBtn={true}
                                offset={offset}
                                onActionClicked={(
                                    item: SpecializationDataType
                                ) => {
                                    const actionText =
                                        item.workStatus === "Active"
                                            ? "Deactivate"
                                            : "Activate";
                                    setSelectedSpecialization(item);
                                    setListActionMenus([actionText]);
                                }}
                                selectedItem={selectedSpecialization}
                                actionMenus={listActionMenus}
                                onMenuClick={(menu) => {
                                    if (menu === "Activate") {
                                        if (selectedSpecialization?.id) {
                                            handleActivate(
                                                selectedSpecialization.id
                                            );
                                        }
                                    } else if (menu === "Deactivate") {
                                        if (selectedSpecialization?.id) {
                                            handleDeactivate(
                                                selectedSpecialization.id
                                            );
                                        }
                                    }
                                }}
                            />
                        </div>

                        {tableData !== undefined &&
                            tableData.length > itemsPerPage && (
                                <Pagination
                                    data={tableData as SpecializationDataType[]}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    itemsPerPage={itemsPerPage}
                                />
                            )}
                    </>
                ) : (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                )}
            </SpacedBetweenColumnBox>
            <ToastContainer />
        </SectionContainer>
    );
};

export default Specialization;
