import React, { useEffect, useState } from "react";
import TopMenu from "../../../components/TopMenu";
import ProductTable from "./templates/ProductTable";
import StatusWrapper from "../../../components/StatusWrapper";
import Modal from "../../../components/Modal";
import AddProduct from "./templates/AddProduct";
import Pagination from "../../components/Pagination";
import {
    ProductListMenuType,
    MenuType,
    ProductDataType,
} from "../../types/DataTypes";
import DateWrapper from "../../../components/DateWrapper";
import connectivityService from "../../../service/ConnectivityService";
import { ToastContainer, toast } from "react-toastify";
import { ToastTypes } from "../../../components/DataTypes";
import ViewProduct from "./templates/ViewProduct";
import { useNavigate } from "react-router-dom";
import { useAdminSession } from "../../context/AdminSessionContext";
import { sortDataByDate } from "../../../utils";
import {
    Loader,
    LoaderWrapper,
    SectionContainer,
    SpacedBetweenColumnBox,
} from "../../../styles";

const tableHeader = [
    { value: "productId", text: "ID" },
    { value: "productCategory", text: "Category" },
    { value: "productName", text: "Name" },
    { value: "createdAt", text: "Created on", wrapper: DateWrapper },
    { value: "createdByName", text: "Created by" },
    { value: "workStatus", text: "Status", wrapper: StatusWrapper },
];

const itemsPerPage = 7;

const Products = () => {
    const [selectedProduct, setSelectedProduct] = useState<ProductDataType>();
    const [listActionMenus, setListActionMenus] = useState<
        ProductListMenuType[]
    >(["View details", "Price"]);
    const [modalType, setModalType] = useState<string>("");
    const [showModal, setModalShow] = useState<boolean>(false);
    const [tableData, setTableData] = useState<ProductDataType[]>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const navigate = useNavigate();
    const { sessionAdmin } = useAdminSession();
    const [pageRefresh, setPageRefresh] = useState<boolean>(false);

    const handleAddProduct = () => {
        setModalShow(true);
        setModalType("Add");
    };

    const handleEditProduct = () => {
        setModalType("Edit");
    };

    const handleMenuClick = async (menu: MenuType) => {
        if (menu === "View details") {
            setModalShow(true);
            setModalType("View");
        } else if (menu === "Price") {
            navigate(
                `/admin/home/product/${selectedProduct?.productName}/price`,
                { state: { product: selectedProduct } }
            );
        } else {
            let data: { workStatus: string | undefined } = {
                workStatus:
                    menu === "Activate"
                        ? "Active"
                        : menu === "Deactivate"
                        ? "Inactive"
                        : undefined,
            };

            if (data.workStatus) {
                const url = `${process.env.REACT_APP_PRODUCT_ROOT_URL}/${selectedProduct?.productId}`;
                const res = await connectivityService().makeApiCall(
                    "post",
                    url,
                    data,
                    sessionAdmin.accessToken
                );
                if (res.code === 200 || res.code === 201) {
                    setPageRefresh(!pageRefresh);
                } else {
                    const error = await connectivityService().handleErrors(
                        res,
                        notify
                    );
                    if (error === "AccessDenied") {
                        notify(
                            "error",
                            "Login session expired. Please re-login."
                        );
                        navigate("/admin");
                    }
                }
            }
        }
    };

    const notify = (type: ToastTypes, msg: string) =>
        toast(msg, { type: type, position: "top-center", theme: "colored" });

    useEffect(() => {
        const fetchData = async () => {
            const res = await connectivityService().makeApiCall(
                "get",
                `${process.env.REACT_APP_PRODUCT_ROOT_URL}`,
                undefined,
                sessionAdmin.accessToken
            );
            if (res.code === 200 || res.code === 201) {
                const sortedData = sortDataByDate(res.data, "createdAt");
                setTableData(sortedData as ProductDataType[]);
            } else {
                const error = await connectivityService().handleErrors(
                    res,
                    notify
                );
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.");
                    navigate("/admin");
                }
            }
        };
        fetchData();
    }, [pageRefresh]);

    const offset = itemsPerPage * (currentPage - 1);
    let displayData = tableData
        ? tableData.slice(offset, offset + itemsPerPage)
        : tableData;

    return (
        <SectionContainer>
            {showModal && (
                <Modal
                    children={
                        modalType === "View" ? (
                            <ViewProduct
                                product={selectedProduct}
                                edit={handleEditProduct}
                            />
                        ) : (
                            <AddProduct
                                type={modalType}
                                selectedProduct={selectedProduct}
                                pageRefresh={pageRefresh}
                                setPageRefresh={setPageRefresh}
                                formClose={() => {
                                    setModalShow(false);
                                }}
                            />
                        )
                    }
                    show={showModal}
                    onClose={() => setModalShow(false)}
                    style={{ width: "704px" }}
                />
            )}

            <TopMenu
                navTag="Products"
                btnAction="Add Product"
                onBtnClick={handleAddProduct}
                portal="admin"
                hasSubMenus={false}
                btnStyle={{ width: "142px" }}
                searchData={() => {}}
            />

            <SpacedBetweenColumnBox>
                {tableData !== undefined ? (
                    <>
                        <div className="page-section">
                            <ProductTable
                                data={displayData as ProductDataType[]}
                                offset={offset}
                                nullMessage="No Product has been created"
                                showPlusIcon={true}
                                additionMessage="New Product"
                                tableHeader={tableHeader}
                                addTableActionBtn={true}
                                onActionClicked={(item: ProductDataType) => {
                                    const actionText =
                                        item.workStatus === "Active"
                                            ? "Deactivate"
                                            : "Activate";
                                    setSelectedProduct(item);
                                    setListActionMenus([
                                        "View details",
                                        "Price",
                                        actionText,
                                    ]);
                                }}
                                actionMenus={listActionMenus}
                                selectedProduct={selectedProduct}
                                onMenuClick={(menu) => {
                                    handleMenuClick(menu);
                                }}
                                onAddClick={handleAddProduct}
                            />
                        </div>

                        {tableData !== undefined &&
                            tableData.length > itemsPerPage && (
                                <Pagination
                                    data={tableData as ProductDataType[]}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    itemsPerPage={itemsPerPage}
                                />
                            )}
                    </>
                ) : (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                )}
            </SpacedBetweenColumnBox>

            <ToastContainer limit={1} />
        </SectionContainer>
    );
};

export default Products;
