import { useEffect, useState } from "react";
import TopMenu from "../../../components/TopMenu";
import TransactionTable from "./templates/TransactionTable";
import Modal from "../../../components/Modal";
import Pagination from "../../components/Pagination";
import {
    AccountDataType,
    MenuType,
    TransactionDataType,
} from "../../types/DataTypes";
import TimeWrapper from "../../../components/TimeWrapper";
import connectivityService from "../../../service/ConnectivityService";
import ViewTransaction from "./templates/ViewTransaction";
import { ToastContainer, toast } from "react-toastify";
import { ToastTypes } from "../../../components/DataTypes";
import { useAdminSession } from "../../context/AdminSessionContext";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { sortDataByDate } from "../../../utils";
import { Loader, LoaderWrapper, SectionContainer } from "../../../styles";

const tableHeader = [
    { value: "id", text: "ID" },
    { value: "accountId", text: "Account ID" },
    { value: "customerId", text: "Customer ID" },
    { value: "transactionType", text: "Transaction Type" },
    {
        value: "transactionTime",
        text: "Transaction Time",
        wrapper: TimeWrapper,
    },
];

const itemsPerPage = 8;

interface StateType {
    account: AccountDataType;
}

const Transaction = () => {
    const [selectedTransaction, setSelectedTransaction] =
        useState<TransactionDataType>();
    const [listActionMenus, setListActionMenus] = useState<MenuType[]>();
    const [showModal, setModalShow] = useState<boolean>(false);
    const [tableData, setTableData] = useState<TransactionDataType[]>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const { sessionAdmin } = useAdminSession();

    const location = useLocation();
    const navigate = useNavigate();

    const handleMenuClick = async (menu: MenuType) => {
        setModalShow(true);
    };

    const notify = (type: ToastTypes, msg: string) =>
        toast(msg, { type: type, position: "top-center", theme: "colored" });

    const downloadData = () => {
        let json = tableData as TransactionDataType[];
        let fields = Object.keys(json[0]);
        let csv = json.map((row) => {
            const valuesArray = Object.values(row);
            return valuesArray.toString();
        });
        csv.unshift(fields.join(","));
        let csv_ = csv.join("\r\n");

        let downloadLink = document.createElement("a");
        downloadLink.href = "data:text/csv;charset=utf-8," + encodeURI(csv_);
        downloadLink.target = "_blank";
        downloadLink.download = "transactions.csv";
        downloadLink.click();
    };

    if (!(location?.state as StateType).account) {
        navigate(-1);
    }

    const account = (location?.state as StateType).account;

    useEffect(() => {
        const fetchData = async () => {
            const res = await connectivityService().makeApiCall(
                "get",
                `${process.env.REACT_APP_TRANSACTION_ROOT_URL}/${account.customerId}/${account.accountId}`,
                undefined,
                sessionAdmin.accessToken
            );

            if (res.code === 200 || res.code === 201) {
                const sortedData = sortDataByDate(res.data, "transactionTime");
                setTableData(sortedData as TransactionDataType[]);
            } else {
                const error = await connectivityService().handleErrors(
                    res,
                    notify
                );
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.");
                    navigate("/admin");
                }
            }
        };
        fetchData();
    }, []);

    const offset = itemsPerPage * (currentPage - 1);
    let displayData = tableData
        ? tableData.slice(offset, offset + itemsPerPage)
        : tableData;

    return (
        <SectionContainer>
            {showModal && (
                <Modal
                    children={
                        <ViewTransaction
                            transaction={
                                selectedTransaction as TransactionDataType
                            }
                        />
                    }
                    show={showModal}
                    onClose={() => setModalShow(false)}
                    style={{ width: "800px" }}
                />
            )}

            <TopMenu
                navTag="Account"
                subTag="Transactions"
                hasSubMenus={false}
                addExportButton={true}
                onBtnClick={downloadData}
                portal="admin"
                searchData={() => {}}
            />

            {tableData !== undefined ? (
                <>
                    <div className="page-section">
                        <TransactionTable
                            data={displayData as TransactionDataType[]}
                            offset={offset}
                            nullMessage="No Transaction has been carried out on this account"
                            showPlusIcon={false}
                            tableHeader={tableHeader}
                            addTableActionBtn={true}
                            onActionClicked={(item: TransactionDataType) => {
                                setSelectedTransaction(item);
                                setListActionMenus(["View details"]);
                            }}
                            actionMenus={listActionMenus as MenuType[]}
                            selectedTransaction={selectedTransaction}
                            onMenuClick={(menu) => {
                                handleMenuClick(menu);
                            }}
                        />
                    </div>

                    {tableData !== undefined &&
                        tableData.length > itemsPerPage && (
                            <Pagination
                                data={tableData as TransactionDataType[]}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                itemsPerPage={itemsPerPage}
                            />
                        )}
                </>
            ) : (
                <LoaderWrapper>
                    <Loader />
                </LoaderWrapper>
            )}

            <ToastContainer limit={1} />
        </SectionContainer>
    );
};

export default Transaction;
