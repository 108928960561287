import styled from "styled-components";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { SpecializationSchema } from "../Specialization.validators";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import { useState } from "react";
import connectivityService from "../../../../service/ConnectivityService";
import { useAdminSession } from '../../../context/AdminSessionContext'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ToastTypes } from '../../../../components/DataTypes';

const ModalContent = styled.div`
    margin-top : -17px;

    .title{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        p{
            font-weight: 400;
            font-size: 16px;
            a{
                color: #2A6BB0;
            }
        }
    }

    .input-blue-border{
        background: #FFFFFF;
        border: 1px solid #2A6BB0;
        border-radius: 4px;
    }
    
    .input-section{
        margin-top: 32px;
        .button {
            margin-top: 34px;
        }
        label{
            font-weight: 400;
            font-size: 16px;
            color: #4E4E4E;
        }
    }

    .bulk{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 24px;
        align-items: end;
    }

    #button:hover{
        background: #FFFFFF !important;
    }
`

interface AddEntityProps {
    type: string,
    entityName: string,
    formClose: () => void,
    pageRefresh: boolean,
    setPageRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

const AddSpecialization = ({ type, entityName, formClose, pageRefresh, setPageRefresh }: AddEntityProps) => {
    const [specialization, setSpecialization] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { sessionAdmin } = useAdminSession();
    const navigate = useNavigate();

    const specializationFields = {
        specialization: ""
    }

    const notify = (type: ToastTypes, msg: string) => (
        toast(msg, { type: type, position: "top-center", theme: "colored" })
    );

    const handleSubmit = async (values: typeof specializationFields, actions: FormikHelpers<typeof specializationFields>) => {
        setLoading(true);
        const url = process.env.REACT_APP_SPECIALIZATION_URL;

        let specializations = values.specialization.split(",");

        let payload = {
            "specializations": specializations,
            "createdBy": sessionAdmin.userId
        }

        const res = await connectivityService().makeApiCall("post", url, payload, sessionAdmin.accessToken);
        setLoading(false);

        if (res.code === 200 || res.code === 201) {
            setSpecialization(values.specialization);
            actions.resetForm({ values: { specialization: "" } });
            formClose();
            setPageRefresh(!pageRefresh)
        }
        else {
            const error = await connectivityService().handleErrors(res, notify)
            if (error === "AccessDenied") {
                notify("error", "Login session expired. Please re-login.")
                navigate("/admin")
            }
        }

    }

    return (
        <>
        <Formik
            initialValues={specializationFields}
            onSubmit={handleSubmit}
            validationSchema={SpecializationSchema}>
            {
                (formik: FormikProps<typeof specializationFields>) => {
                    return (
                        <ModalContent>
                            <div className="title">
                                {type} Specialization
                            </div>
                            <div className="input-section">
                                <Form>
                                    <div style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
                                        <Input
                                            name="specialization"
                                            type="text"
                                            title="Specialization "
                                            required
                                        />
                                    </div>
                                    <Button
                                        className="button"
                                        type="submit"
                                        loading={loading}
                                        children={`Add ${entityName}`}
                                        style={{ marginTop: "24px" }}
                                    />
                                </Form>
                            </div>
                        </ModalContent>
                    )
                }
            }
        </Formik>
        <ToastContainer limit={1}/>
        </>
    )

}

export default AddSpecialization