import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Outlet } from 'react-router-dom';

const Container = styled.div`
  width: 40%;
  flex-basis: 40%;
  height: auto;
  background-size: cover;
  background-position: center;
  transition: .5s;

  @media (max-width: 1024px) {
    display: none;
  }

`

const Overlay = styled.div`
    height: 100%;
    width: 100%;
    background: linear-gradient(173.29deg, rgba(0, 0, 0, 0.74) 5.26%, rgba(0, 0, 0, 0.23) 94.96%);
    transform: rotate(-180deg);
    padding: 48px 41px 48px 40px;


    &>div {
      transform: rotate(180deg);
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items:center;
      position: relative;


      &>img{
        align-self: start;
        // border: 1px solid red;
      }
    }

`

const FootNote = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 165px;

  p{
    font-family: 'Lato';
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.8);
    transition: .5s;
  }

  img {
    width: 64px;
    height: 36px;
    transition: .5s;
  }

`

const RadioDiv = styled.div`
  position: absolute;
  bottom: 0;
  right: 48px;
  // border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  span {
      background-color: white;
      height: 6px;
      width: 6px;
      border-radius: 50%;
  }

`

type carousel = {
  coverImage: string;
  logoImage: string;
  text: string;
}

const carouselCollection: carousel[] = [
  {
    coverImage: "dummy1",
    logoImage: "branch-logo",
    text: '"This is unbelievable. After using Testiminial Generator my buisness skyrocketed!" Rick E.'
  },
  {
    coverImage: "dummy2",
    logoImage: "branch-logo",
    text: '"lorem Behoold the beholder, and the entire body at rest" Rick E.'
  },
  {
    coverImage: "dummy3",
    logoImage: "branch-logo",
    text: '"Wonders of the planet, The magic outpour of rain and sun at similar times" Rick E.'
  },
]

const LeftSideBar = () => {
  const [curCarousel, setCurCarousel] = useState(0);
  const curSet = carouselCollection[curCarousel];

  useEffect(() => {
      const interval = setInterval(() => {
          setCurCarousel(
              (prevCarousel) => (prevCarousel + 1) % carouselCollection.length
          );
      }, 30000);

      return () => {
          clearInterval(interval);
      };
  }, []);

  return (
    <>
      <Container style={{ backgroundImage: `url(/assets/images/${curSet.coverImage}.jpg)` }}>
        <Overlay>
          <div>
            <img src="/assets/icons/one2one-logo.svg" alt="" />
            <FootNote>
              <p>{curSet.text}</p>
              <img src={`/assets/icons/${curSet.logoImage}.svg`} alt="" />
            </FootNote>
            <RadioDiv>
              {
                [0, 1, 2].map((num, i) => (
                  <span key={i}
                    style={{
                      opacity: num === curCarousel ? 1 : 0.2,
                    }}
                    onClick={() => setCurCarousel(i as typeof curCarousel)}>
                  </span>
                ))
              }

            </RadioDiv>
          </div>
        </Overlay>

      </Container>
      <Outlet />
    </>
  )
}

export default LeftSideBar
