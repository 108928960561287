import React, { useEffect, useState } from "react";
import TopMenu from "../../../components/TopMenu";
import InstitutionTable from "./templates/InstitutionTable";
import StatusWrapper from "../../../components/StatusWrapper";
import Modal from "../../../components/Modal";
import Pagination from "../../../components/Pagination";
import { InstitutionDataType } from "../../types/DataTypes";
import DateWrapper from "../../../components/DateWrapper";
import connectivityService from "../../../service/ConnectivityService";
import { ToastContainer, toast } from "react-toastify";
import { ToastTypes } from "../../../components/DataTypes";
import ActivateInstitution from "./templates/ActivateInstitution";
import DeactivateInstitution from "./templates/DeactivateInstitution";
import ViewInstitution from "./templates/ViewInstitution";
import { useNavigate } from "react-router-dom";
import { useAdminSession } from "../../context/AdminSessionContext";
import {
    Loader,
    LoaderWrapper,
    SectionContainer,
    SpacedBetweenColumnBox,
} from "../../../styles";
import GenericTable from "../../../components/GenericUserTable";
import { AppMenuType } from "../../../components/DataTypes";
import { sortDataByDate } from "../../../utils";

const tableHeader = [
    { value: "institutionId", text: "S/N" },
    { value: "name", text: "Institution name" },
    { value: "institutionType", text: "Type" },
    { value: "users", text: "Users" },
    { value: "workStatus", text: "Status", wrapper: StatusWrapper },
    { value: "createdAt", text: "Date", wrapper: DateWrapper },
];

const itemsPerPage = 8;

const Institution = () => {
    const [selectedInstitution, setSelectedInstitution] =
        useState<InstitutionDataType>();
    const [listActionMenus, setListActionMenus] = useState<AppMenuType[]>();
    const [modalType, setModalType] = useState<string>("");
    const [showModal, setModalShow] = useState<boolean>(false);
    const [initialData, setInitialData] = useState<InstitutionDataType[]>();
    const [tableData, setTableData] = useState<InstitutionDataType[]>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { sessionAdmin } = useAdminSession();
    const [pageRefresh, setPageRefresh] = useState(false);
    const [noDataMessage, setNoDataMessage] = useState<string>();

    const showAddModal = () => {
        setModalShow(true);
        setModalType("Add");
    };

    const handleMenuClick = (menu: AppMenuType) => {
        if (menu === "Review") {
            setModalShow(true);
            setModalType("View");
        } else if (menu === "Discount") {
            navigate(
                `/admin/home/institution/${selectedInstitution?.name}/discount`,
                { state: { institution: selectedInstitution } }
            );
        } else if (menu === "Deactivate") {
            setModalShow(true);
            setModalType("Deactivate");
        } else {
            setModalShow(true);
            setModalType("Activate");
        }

        const newSelectedInstitution = {
            ...(selectedInstitution as InstitutionDataType),
            status: menu === "Activate" ? "Active" : "Not Active",
        };

        setSelectedInstitution(newSelectedInstitution);
    };

    const updateInstStatus = async (action: AppMenuType) => {
        setLoading(true);
        const code = (selectedInstitution as InstitutionDataType).shortCode;
        const url = `${process.env.REACT_APP_INST_URL}/${code}/workstatus`;
        const data = {
            adminId: sessionAdmin.userId,
            action: action === "Approve" ? "APPROVE" : "SUSPEND",
        };
        const res = await connectivityService().makeApiCall(
            "put",
            url,
            data,
            sessionAdmin.accessToken
        );
        setLoading(false);

        const actionName = action === "Approve" ? "Approved" : "Suspended";

        if (res.code === 200 || res.code === 201) {
            notify(
                "success",
                `Institution has been ${actionName.toLowerCase()} successfully`
            );
            setModalShow(false);
            setPageRefresh(!pageRefresh);
        } else {
            const error = await connectivityService().handleErrors(res, notify);
            if (error === "AccessDenied") {
                notify("error", "Login session expired. Please re-login.");
                navigate("/admin");
            }
        }
    };

    const notify = (type: ToastTypes, msg: string) =>
        toast(msg, { type: type, position: "top-center", theme: "colored" });

    useEffect(() => {
        const fetchData = async () => {
            const url = process.env.REACT_APP_INST_URL;
            const res = await connectivityService().makeApiCall(
                "get",
                url,
                undefined,
                sessionAdmin.accessToken
            );
            if (res.code === 200 || res.code === 201) {
                const sortedData = sortDataByDate(res.data, "createdAt");
                setTableData(sortedData);
                setInitialData(sortedData);
            } else {
                const error = await connectivityService().handleErrors(
                    res,
                    notify
                );
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.");
                    navigate("/admin");
                }
            }
        };
        fetchData();
    }, [pageRefresh]);

    const searchData = (data: string) => {
        if (data === "") {
            setNoDataMessage("No institution has been added");
            setTableData(initialData);
        } else {
            setNoDataMessage("Institution not found");
            const result = initialData?.filter((val) => {
                return (
                    val.name.toLowerCase().includes(data.toLowerCase()) ||
                    val.institutionType
                        ?.toLowerCase()
                        .includes(data.toLowerCase())
                );
            });
            setTableData(result);
        }
    };

    const offset = itemsPerPage * (currentPage - 1);
    let displayData = tableData
        ? tableData.slice(offset, offset + itemsPerPage)
        : tableData;

    return (
        <>
            <SectionContainer optionalStyles="justify-content: unset;">
                {showModal && (
                    <Modal
                        children={
                            modalType === "View" ? (
                                <ViewInstitution
                                    institutionRep={selectedInstitution}
                                    verify={() => {
                                        updateInstStatus("Approve");
                                    }}
                                    loading={loading}
                                />
                            ) : modalType === "Activate" ? (
                                <ActivateInstitution
                                    activate={() => {}}
                                    formClose={() => {
                                        setModalShow(false);
                                    }}
                                    loading={loading}
                                />
                            ) : (
                                <DeactivateInstitution
                                    deactivate={() => {
                                        updateInstStatus("Suspend");
                                    }}
                                    formClose={() => {
                                        setModalShow(false);
                                    }}
                                    loading={loading}
                                />
                            )
                        }
                        show={showModal}
                        onClose={() => setModalShow(false)}
                        style={{ width: "580px" }}
                    />
                )}

                <TopMenu
                    navTag="Institution"
                    hasNoButton
                    hasSubMenus
                    hasSearch
                    portal="admin"
                    searchBy="institution name or type"
                    searchData={searchData}
                />

                {tableData !== undefined ? (
                    <>
                        <SpacedBetweenColumnBox>
                            <GenericTable
                                data={displayData as InstitutionDataType[]}
                                offset={offset}
                                nullMessage={
                                    noDataMessage ||
                                    "No Institution has been added"
                                }
                                showPlusIcon={false}
                                tableHeader={tableHeader}
                                addTableActionBtn={true}
                                onActionClicked={(
                                    item: InstitutionDataType
                                ) => {
                                    const actionArray: AppMenuType[] =
                                        item.workStatus === "APPROVED"
                                            ? [
                                                  "Deactivate",
                                                  "Discount",
                                                  "Review",
                                              ]
                                            : ["Discount", "Review"];
                                    setSelectedInstitution(item);
                                    setListActionMenus(actionArray);
                                }}
                                actionMenus={listActionMenus as AppMenuType[]}
                                selectedItem={selectedInstitution}
                                onMenuClick={(menu) => {
                                    handleMenuClick(menu);
                                }}
                                onAddClick={showAddModal}
                            />

                            {tableData !== undefined &&
                                tableData.length > itemsPerPage && (
                                    <Pagination
                                        data={
                                            tableData as InstitutionDataType[]
                                        }
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        itemsPerPage={itemsPerPage}
                                    />
                                )}
                        </SpacedBetweenColumnBox>
                    </>
                ) : (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                )}
            </SectionContainer>
            <ToastContainer limit={1} />
        </>
    );
};

export default Institution;
