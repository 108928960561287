import * as yup from 'yup';


export const LoginSchema = yup.object({
    userName: yup
        .string()
        .trim()
        .email('This is not a valid email')
        .required('Email field is empty'),
    password: yup
        .string()
        .trim()
        .min(8, "Password should be up to 8 characters")
        .required("This field is required")
});

