import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 32px;
    width: 355px;
    height: 320px;
    margin: auto;
    margin-top: 50px;
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 8px;

`

const Text = styled.p`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    width: 325px;
    color: #373737;


`
const ButtonContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

`

const Button = styled.button`
    padding: 10px 8px;
    width: 150px;
    height: 50px;
    border-radius: 8px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

`

const ProceedBtn = styled(Button)`
    background-color: #2A6BB0;
    color: #FFFFFF;

`


const DecorImage = styled.img`


`

interface AccountAlreadyExistsProps {
    handleProceed: () => void;
    email?: string;
    cta: string;
    msg?: string;

}

const EmailSuccessModal = ({ handleProceed, email, cta, msg}:AccountAlreadyExistsProps) => {


    return (
        <Container>
            <DecorImage src="/assets/icons/featured-icon.svg"/>

            {
                email ?
                <Text>
                    A confirmation email has been sent to {email}. The email contains instructions on how to reset your password.
                </Text>:
                <Text>
                    {msg}
                </Text>
            }
            <ButtonContainer>
                {/* <DiscardBtn type="button" onClick={handleDiscard}>Discard</DiscardBtn> */}
                <ProceedBtn type="button" onClick={handleProceed}>{cta}</ProceedBtn>
            </ButtonContainer>
        </Container>
    )
}



export default EmailSuccessModal;