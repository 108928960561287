import styled from "styled-components";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { SingleUserSchema, SingleIDPUserSchema, BulkUserSchema } from "../ManageUsers.validators";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import { TwoColumnInputFlex, FormBox } from "../../../styles/generalStyles";
import { ToastContainer, toast } from 'react-toastify';
import { ToastTypes } from '../../../../components/DataTypes';
import toggleButton from "../../../utils/toggleButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import connectivityService from "../../../../service/ConnectivityService";
import { useInstitutionSession } from "../../../context/InstitutionSessionContext";

const ModalContent = styled.div`
    margin-top : -17px;

    .file-input{
        height: 50px;
        width: 100%;
        padding: 13px;
    }

    .title{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        p{
            font-weight: 400;
            font-size: 16px;
            a{
                color: #2A6BB0;
            }
        }
    }

    .input-blue-border{
        background: #FFFFFF;
        border: 1px solid #2A6BB0;
        border-radius: 4px;
    }
    
    .input-section{
        margin-top: 16px;
        .button {
            margin-top: 34px;
        }
        label{
            font-weight: 400;
            font-size: 16px;
            color: #4E4E4E;
        }
    }

    .bulk{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 24px;
        align-items: end;
    }

    #button.img-btn:hover{
        background: #2A6BB0 !important;
    }
`

type UserObj = {
    email: string,
    phoneNumber: string,
    role: string
}

interface AddEntityProps {
    type: string,
    isIDP: boolean,
    pageRefresh: () => void,
    formClose: () => void
}

const AddUser = ({ type, isIDP, pageRefresh, formClose }: AddEntityProps) => {
    const [file, setFile] = useState<File[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const {sessionInstitution} = useInstitutionSession();

    const notify = (type: ToastTypes, msg: string) => (
        toast(msg, { type: type, position: "top-center", theme: "colored" })
    )

    const singleFields = {
        email: ""
    }

    const singleIDPFields = {
        firstName: "",
        lastName: "",
        dob: "",
        gender: ""
    }

    const bulkFields = {
        file: []
    }

    const institutionCode = sessionInstitution.shortCode;
    const url = `${process.env.REACT_APP_INST_URL}/${institutionCode}/addUser`;
    const idpUrl = `${process.env.REACT_APP_INST_URL}/${institutionCode}/idpUser`;

    const handleSingleSubmit = async (values: typeof singleFields, actions: FormikHelpers<typeof singleFields>) => {
        const dataObj = {
            email: values.email
        }

        const data = {
            users: [dataObj],
            host: "institution"
        }

        const response = await connectivityService().makeApiCall(
            'post', url, data, sessionInstitution.accessToken
        )

        setLoading(false);

        if (response.code === 200 || response.code === 201) {
            notify("success", "User added successfuly");
            actions.resetForm({ values: { email: "" } });
            formClose();
            pageRefresh();
        }
        else {
            const error = await connectivityService().handleErrors(response, notify)
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.")
                    navigate("/admin")
                }
        }
    }

    const handleSingleIDPSubmit = async (values: typeof singleIDPFields, actions: FormikHelpers<typeof singleIDPFields>) => {
        setLoading(true);

        const dataObj = {
            firstName: values.firstName,
            lastName: values.lastName,
            dateOfBirth: values.dob,
            gender: values.gender === "Female" ? "F" : "M"
        }

        const data = {
            users: [dataObj]
        }

        const response = await connectivityService().makeApiCall(
            'post', idpUrl, data, sessionInstitution.accessToken
        )

        setLoading(false);

        if (response.code === 200 || response.code === 201) {
            notify("success", "User added successfuly");
            actions.resetForm({ values: { firstName: "", lastName: "", dob: "", gender: "" } });
            formClose();
            pageRefresh();
        }
        else {
            const error = await connectivityService().handleErrors(response, notify)
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.")
                    navigate("/admin")
                }
        }
    }

    const handleBulkSubmit = (values: typeof bulkFields, actions: FormikHelpers<typeof bulkFields>) => {
        setFile(values.file);
        convertToObject(values.file);
    }

    const notifyError = () => {
        notify("error", "File format is incorrect");
        return;
    }

    const validateHeaders = (headersText: Array<string>, isIDP: boolean) => {
        if (!isIDP && headersText.length === 3 && headersText.includes("email address") &&
            headersText.includes("phone number") && headersText.includes("role")) {
            return true
        }
        if (isIDP && headersText.length === 4 && headersText.includes("first name") &&
            headersText.includes("last name") && headersText.includes("dob") &&
            headersText.includes("gender")) {
            return true
        }
        else {
            return false
        }
    }

    const validateCsv = (header: string, data: string, isIDP: boolean) => {
        return isIDP ? validateIDPCsv(header, data) : validateGeneralCsv(header, data)
    }

    const validateGeneralCsv = (header: string, data: string) => {
        const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
        if (data === "") {
            return false;
        }
        else if (header === "email") {
            if (!data.match(emailRegex)) {
                return false;
            }
        }
        else if (header === "phoneNumber") {
            if (!data.match(phoneRegex)) {
                return false;
            }
        }
        else {
            if (data !== "Doctor" && data !== "Patient") {
                return false;
            }
        }
        return true;
    }

    const validateIDPCsv = (header: string, data: string) => {

        if (data === "") {
            return false;
        }

        if (header === "gender") {
            if (data.toLowerCase() !== "female" && data.toLowerCase() !== "male") {
                return false;
            }
        }

        return true;
    }

    const convertToObject = (data: File[]) => {
        const fileReader = new FileReader();
        fileReader.readAsText(data[0]);
        fileReader.onload = async r => {
            const arr = (fileReader.result as string).split('\n');
            const jsonObj = [];
            const headers_ = arr[0].split(',');

            const headers: Array<string> = []

            const headersText = headers_.map((val, key) => {
                return val.trim().toLowerCase()
            });

            if (!validateHeaders(headersText, isIDP)) {
                notifyError();
            }
            else {
                !isIDP && headers_.map((val, key) => {
                    if (val.trim().toLowerCase() === "email address") {
                        headers.push("email");
                    }
                    else if (val.trim().toLowerCase() === "phone number") {
                        headers.push("phoneNumber");
                    }
                    else {
                        headers.push("role");
                    }
                });
                isIDP && headers_.map((val, key) => {
                    if (val.trim().toLowerCase() === "first name") {
                        headers.push("firstName");
                    }
                    else if (val.trim().toLowerCase() === "last name") {
                        headers.push("lastName");
                    }
                    else if (val.trim().toLowerCase() === "dob") {
                        headers.push("dob");
                    }
                    else {
                        headers.push("gender");
                    }
                });
                for (let i = 1; i < arr.length; i++) {
                    let data: Array<string> = arr[i].split(',');
                    let obj: UserObj = { email: "", phoneNumber: "", role: "" };
                    for (var j = 0; j < data.length; j++) {
                        if (!validateCsv(headers[j], data[j].trim(), isIDP)) {
                            notifyError();
                            return;
                        }
                        obj[headers[j] as keyof UserObj] = data[j].trim();
                    }
                    jsonObj.push(obj);
                }
                navigate('/institution/home/users/list', { state: { users: jsonObj, isIDP: isIDP } });
            }
        }
    }


    return (
        <>
            {
                type === "Single" && isIDP ?
                    <Formik
                        initialValues={singleIDPFields}
                        onSubmit={handleSingleIDPSubmit}
                        validationSchema={SingleIDPUserSchema}>
                        {
                            (formik: FormikProps<typeof singleIDPFields>) => {
                                const toggle = toggleButton<typeof singleIDPFields>(formik.values) &&
                                    Object.keys(formik.errors).length === 0;
                                return (
                                    <ModalContent>
                                        <div className="title">
                                            {type} User
                                        </div>
                                        <div className="input-section">
                                            <TwoColumnInputFlex>
                                                <Form>
                                                    <FormBox>
                                                        <div className="flex">
                                                            <Input
                                                                name="firstName"
                                                                type="text"
                                                                title="First Name"
                                                                required
                                                            />
                                                            <Input
                                                                name="lastName"
                                                                type="text"
                                                                title="Last Name"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="flex">
                                                            <Input
                                                                name="dob"
                                                                type="date"
                                                                title="DOB"
                                                                required
                                                            />
                                                            <Select
                                                                name="gender"
                                                                title="Gender"
                                                                options={['Male', 'Female']}
                                                                placeholder='Select gender'
                                                                required
                                                            />
                                                        </div>
                                                        <Button
                                                            className="button"
                                                            type="submit"
                                                            loading={loading}
                                                            disabled={!toggle}
                                                            children={'Add User'}
                                                        />
                                                    </FormBox>
                                                </Form>
                                            </TwoColumnInputFlex>
                                        </div>
                                    </ModalContent>
                                )
                            }
                        }
                    </Formik>
                    :
                    type === "Single" && !isIDP ?
                        <Formik
                            initialValues={singleFields}
                            onSubmit={handleSingleSubmit}
                            validationSchema={SingleUserSchema}>
                            {
                                (formik: FormikProps<typeof singleFields>) => {
                                    const toggle = toggleButton<typeof singleFields>(formik.values) &&
                                        Object.keys(formik.errors).length === 0;
                                    return (
                                        <ModalContent>
                                            <div className="title">
                                                Add User
                                            </div>
                                            <div className="input-section">
                                                <Form>
                                                    <Input
                                                        name="email"
                                                        type="text"
                                                        title="Email Address"
                                                        required
                                                    />
                                                    <Button
                                                        className="button"
                                                        type="submit"
                                                        loading={loading}
                                                        disabled={!toggle}
                                                        children={`Add User`}
                                                    />
                                                </Form>
                                            </div>
                                        </ModalContent>
                                    )
                                }
                            }
                        </Formik>
                        :
                        <Formik
                            initialValues={bulkFields}
                            onSubmit={handleBulkSubmit}
                            validationSchema={BulkUserSchema}>
                            {
                                (formik: FormikProps<typeof bulkFields>) => {
                                    return (
                                        <ModalContent>
                                            <div className="title">
                                                Bulk User
                                                {isIDP ?
                                                    <p>Your csv file should follow this <a href="https://docs.google.com/spreadsheets/d/1jk7Y1c3x1XHXvG58E5EOtks3368Q6JAJ-Ymp1fcmT38/edit#gid=0" target="_blank" rel="noreferrer">format</a></p>
                                                    :
                                                    <p>Your csv file should follow this <a href="https://docs.google.com/spreadsheets/d/1qFpBBKyzg4ug5J8QTFQPfxL4_rZf6n8wzwLAdMxATuQ/edit#gid=0" target="_blank" rel="noreferrer">format</a></p>
                                                }
                                            </div>
                                            <div className="input-section">
                                                <Form>
                                                    <div className="bulk">
                                                        <input
                                                            className="input-blue-border file-input"
                                                            name="file"
                                                            title="Choose file"
                                                            type="file"
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                formik.setFieldValue("file", (e.currentTarget.files));
                                                            }}
                                                            accept=".csv, text/csv" />
                                                        <Button
                                                            type="submit"
                                                            className="button img-btn"
                                                            children={<img src="/assets/institution/icons/upload-icon.svg" alt="" />}
                                                        />
                                                    </div>
                                                </Form>
                                            </div>
                                        </ModalContent>
                                    )
                                }
                            }
                        </Formik>
            }
            <ToastContainer limit={1} />
        </>
    )

}

export default AddUser