import React, { useEffect, useState } from "react";
import TopMenu from "../../../components/TopMenu";
import DiscountTable from "./templates/DiscountTable";
import StatusWrapper from "../../../components/StatusWrapper";
import Modal from "../../../components/Modal";
import AddDiscount from "./templates/AddDiscount";
import Pagination from "../../../components/Pagination";
import {
    MenuType,
    DiscountDataType,
    InstitutionDataType,
} from "../../types/DataTypes";
import DateWrapper from "../../../components/DateWrapper";
import connectivityService from "../../../service/ConnectivityService";
import { ToastContainer, toast } from "react-toastify";
import { ToastTypes } from "../../../components/DataTypes";
import ActivateDiscount from "./templates/ActivateDiscount";
import DeactivateDiscount from "./templates/DeactivateDiscount";
import { useLocation, useNavigate } from "react-router-dom";
import { useAdminSession } from "../../context/AdminSessionContext";
import { sortDataByDate } from "../../../utils";
import { Loader, LoaderWrapper, SectionContainer, SpacedBetweenColumnBox } from "../../../styles";

const tableHeader = [
    { value: "productId", text: "ID" },
    { value: "productName", text: "Product name" },
    { value: "institutionName", text: "Institution name" },
    { value: "discount", text: "Discount" },
    { value: "validFrom", text: "Valid From", wrapper: DateWrapper },
    { value: "validTo", text: "Valid To", wrapper: DateWrapper },
    { value: "isActive", text: "Status", wrapper: StatusWrapper },
];

const itemsPerPage = 8;

interface StateType {
    institution: InstitutionDataType;
}

const Discount = () => {
    const [selectedDiscount, setSelectedDiscount] =
        useState<DiscountDataType>();
    const [listActionMenus, setListActionMenus] = useState<MenuType[]>();
    const [modalType, setModalType] = useState<string>("");
    const [showModal, setModalShow] = useState<boolean>(false);
    const [tableData, setTableData] = useState<DiscountDataType[]>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { sessionAdmin } = useAdminSession();
    const location = useLocation();
    const [pageRefresh, setPageRefresh] = useState(false);

    const showAddModal = () => {
        setModalShow(true);
        setModalType("Add");
    };

    const handleMenuClick = (menu: MenuType) => {
        if (menu === "Deactivate") {
            setModalShow(true);
            setModalType("Deactivate");
        } else if (menu === "Activate") {
            setModalShow(true);
            setModalType("Activate");
        }

        const newselectedDiscount = {
            ...(selectedDiscount as DiscountDataType),
            status: menu === "Activate" ? "Active" : "Not Active",
        };
        setSelectedDiscount(newselectedDiscount);
    };

    const handleAddDiscount = () => {};

    const handleActivateDiscount = () => {};

    const handleDeactivateDiscount = async () => {
        setLoading(true);

        const url = `${process.env.REACT_APP_PRODUCT_ROOT_URL}/discount/${
            (location.state as StateType).institution.shortCode
        }`;

        const discountObj = {
            action: "deactivate",
            discountId: selectedDiscount?.id,
        };

        const response = await connectivityService().makeApiCall(
            "put",
            url,
            discountObj,
            sessionAdmin.accessToken
        );

        setLoading(false);

        if (response.code === 200 || response.code === 201) {
            setPageRefresh(!pageRefresh);
            setModalShow(false);
        } else {
            const error = await connectivityService().handleErrors(
                response,
                notify
            );
            if (error === "AccessDenied") {
                notify("error", "Login session expired. Please re-login.");
                navigate("/admin");
            }
        }
    };

    const notify = (type: ToastTypes, msg: string) =>
        toast(msg, { type: type, position: "top-center", theme: "colored" });

    useEffect(() => {
        const fetchData = async () => {
            const url = `${process.env.REACT_APP_PRODUCT_ROOT_URL}/discount/${
                (location.state as StateType).institution.shortCode
            }`;
            const res = await connectivityService().makeApiCall(
                "get",
                url,
                undefined,
                sessionAdmin.accessToken
            );
            if (res.code === 200 || res.code === 201) {
                const sortedData = sortDataByDate(res.data, "validFrom");
                setTableData(sortedData as DiscountDataType[]);
            } else {
                const error = await connectivityService().handleErrors(
                    res,
                    notify
                );
                if (error === "AccessDenied") {
                    notify("error", "Login session expired. Please re-login.");
                    navigate("/admin");
                }
            }
        };
        fetchData();
    }, [pageRefresh]);

    if (!(location.state as StateType).institution) {
        navigate(-1);
    }

    const offset = itemsPerPage * (currentPage - 1);
    let displayData = tableData
        ? tableData.slice(offset, offset + itemsPerPage)
        : tableData;

    return (
        <SectionContainer>
            {showModal && (
                <Modal
                    children={
                        modalType === "Add" ? (
                            <AddDiscount
                                formClose={() => {
                                    setModalShow(false);
                                }}
                                addDiscount={handleAddDiscount}
                                selectedDiscount={selectedDiscount}
                                selectedInstitution={
                                    (location?.state as StateType).institution
                                }
                                pageRefresh={pageRefresh}
                                setPageRefresh={setPageRefresh}
                            />
                        ) : modalType === "Activate" ? (
                            <ActivateDiscount
                                activate={handleActivateDiscount}
                                formClose={() => {
                                    setModalShow(false);
                                }}
                                loading={loading}
                            />
                        ) : (
                            <DeactivateDiscount
                                deactivate={handleDeactivateDiscount}
                                formClose={() => {
                                    setModalShow(false);
                                }}
                                loading={loading}
                            />
                        )
                    }
                    show={showModal}
                    onClose={() => setModalShow(false)}
                    style={{ width: "704px" }}
                />
            )}

            <TopMenu
                navTag="Institution"
                subTag="Discount"
                btnAction="Add Discount"
                portal="admin"
                onBtnClick={showAddModal}
                hasSubMenus={false}
                btnStyle={{ width: "142px" }}
                searchData={() => {}}
            />

            <SpacedBetweenColumnBox>
                {tableData !== undefined ? (
                    <>
                        <div className="page-section">
                            <DiscountTable
                                data={displayData as DiscountDataType[]}
                                offset={offset}
                                nullMessage="No Discount added for Institution"
                                showPlusIcon={false}
                                tableHeader={tableHeader}
                                addTableActionBtn={true}
                                onActionClicked={(item: DiscountDataType) => {
                                    const actionArray: MenuType[] =
                                        item.isActive === "Active"
                                            ? ["Deactivate"]
                                            : [];
                                    setSelectedDiscount(item);
                                    setListActionMenus(actionArray);
                                }}
                                actionMenus={listActionMenus as MenuType[]}
                                selectedDiscount={selectedDiscount}
                                onMenuClick={(menu) => {
                                    handleMenuClick(menu);
                                }}
                                onAddClick={showAddModal}
                            />
                        </div>
                        {tableData !== undefined &&
                            tableData.length > itemsPerPage && (
                                <Pagination
                                    data={tableData as DiscountDataType[]}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    itemsPerPage={itemsPerPage}
                                />
                            )}
                    </>
                ) : (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                )}
            </SpacedBetweenColumnBox>
            <ToastContainer limit={1} />
        </SectionContainer>
    );
};

export default Discount;
